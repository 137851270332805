import { BasicCustomerData, Basket } from "@/services/model";
import { TransformedProduct } from "@/utils/tariffUtils";

const windowPushEvent = (event: any) => {
  console.log("windowPushEvent", event);
};

if (import.meta.env.MODE === "staging" || import.meta.env.DEV) {
  window.dataLayer = {
    push: windowPushEvent,
  } as any;
}

export const pushSimTypeSelection = (isEsim: boolean) => {
  const BUY_AND_ACTIVATE_ESIM = "buy and activate esim";
  const ACTIVATE_SIM_CARD = "activate sim card";

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - sim type",
    event_parameter: {
      action: "select sim type",
      page_location: window.location.href,
      link_url:
        window.location.origin +
        window.location.pathname +
        "#/portal/add-device/2/device-type",
      selected_option: isEsim ? BUY_AND_ACTIVATE_ESIM : ACTIVATE_SIM_CARD,
    },
  });
};

export const pushSimActivation = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - sim type",
    event_parameter: {
      action: "activate sim card",
      page_location: window.location.href,
      link_url:
        window.location.origin +
        window.location.pathname +
        "#/portal/add-device/2/device-type",
    },
  });
};

export const pushDeviceTypeSelection = (deviceType: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - device type",
    event_parameter: {
      action: "select device type",
      page_location: window.location.href,
      link_url:
        window.location.origin +
        window.location.pathname +
        "#/portal/add-device/3/imei",
      selected_option: deviceType,
    },
  });
};

export const pushProductImpression = (
  products: TransformedProduct[],
  itemListName: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      item_list_id: "connect_tariff_product_list",
      item_list_name: `connect tariff product list|/${itemListName}/`,
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.shortName,
        affiliation: "connect.digitalrepublic.ch",
      })),
    },
  });
};

export const pushProductClick = (product: TransformedProduct) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  console.log("pushProductClick", product);
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      item_list_id: "connect_tariff_product_list",
      item_list_name: "connect tariff product list|/rate-selection/",
      items: [
        {
          item_id: product.id,
          item_name: product.shortName,
          affiliation: "connect.digitalrepublic.ch",
          item_brand: "digital republic",
          item_category2: "mobile subscriptions",
          item_variant: product.shortName,
          item_list_id: "connect_tariff_product_list",
          item_list_name: "connect tariff product list|/rate-selection/",
          quantity: 1,
          price: product.chargeFlat,
          bandwidth: product.bandwidthLabel,
          duration: product.duration,
          base_display_value: product.baseDisplayValue,
        },
      ],
    },
  });
};

export const enableNumberPorting = (
  previousProvider: string,
  previousContractType: "subscription" | "prepaid",
  cancellation: "after end of contract" | "early cancellation",
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - tariff selection",
    event_parameter: {
      action: "enable number porting",
      page_location: window.location.href,
      link_url:
        window.location.origin +
        window.location.pathname +
        "#/onboarding/4/personal-details",
      previous_provider: previousProvider,
      previous_contracty_type: previousContractType,
      cancellation: cancellation,
    },
  });
};

export const pushTariffSelection = (
  selectedOption: string,
  numberPorting: boolean,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - tariff selection",
    event_parameter: {
      action: "select tariff",
      page_location: window.location.href,
      link_url:
        window.location.origin +
        window.location.pathname +
        "#/onboarding/3/rate-selection",
      selected_option: selectedOption,
      number_porting: numberPorting.toString(),
    },
  });
};

export const pushPersonalData = (
  customerType: "b2c" | "b2b",
  target: string,
  loginStatus: "existing account" | "new account",
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event_parameter: null,
  });
  window.dataLayer.push({
    event: "custom_event",
    event_name: "connect - personal data",
    event_parameter: {
      action: "successfully submitted",
      page_location: window.location.href,
      link_url: target,
      customer_type: customerType,
      login_status: loginStatus,
    },
  });
};

export const pushPaymentInfo = (
  product: TransformedProduct,
  basket: Basket,
  promotionId: string,
  promotionName: string,
  autorenewalOn: boolean,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      currency: basket.currency?.description,
      value: basket.total?.oneTime?.amount,
      coupon: basket.promotionalCode?.promotionalCode,
      discount: basket.total?.oneTime?.amountDiscounts,
      items: [
        {
          item_id: product.id,
          item_name: product.shortName,
          affiliation: "connect.digitalrepublic.ch",
          coupon: basket.promotionalCode?.promotionalCode,
          discount: basket.total?.oneTime?.amountDiscounts,
          item_brand: "digital republic",
          item_category: product.isVoiceProduct ? "voice" : "data",
          item_category2: "mobile subscriptions",
          item_variant: product.shortName,
          item_list_id: "mobile_product_list",
          item_list_name: "product list|/mobile/",
          index: 0,
          promotion_id: promotionId,
          promotion_name: promotionName,
          quantity: 1,
          price: basket.total?.oneTime?.amount,
          shop_language: "de",
          auto_renewal_preference: autorenewalOn
            ? "auto renewal"
            : "one time payment",
          bandwidth: product.bandwidthLabel,
          duration: product.duration,
          base_display_value: product.baseDisplayValue,
        },
      ],
    },
  });
};

export const pushPurchase = (
  product: TransformedProduct,
  customerData: BasicCustomerData,
  basket: Basket,
  promotionId: string,
  promotionName: string,
  autorenewalOn: boolean,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      value: basket.total?.oneTime,
      currency: basket.currency?.description,
      coupon: basket.promotionalCode?.promotionalCode,
      discount: basket.total?.oneTime?.amountDiscounts,
      items: [
        {
          item_id: product.id,
          item_name: product.shortName,
          affiliation: "connect.digitalrepublic.ch",
          coupon: basket.promotionalCode?.promotionalCode,
          discount: basket.total?.oneTime?.amountDiscounts,
          item_brand: "digital republic",
          item_category: product.isVoiceProduct ? "voice" : "data",
          item_category2: "mobile subscriptions",
          item_variant: product.shortName,
          item_list_id: "mobile_product_list",
          item_list_name: "product list|/mobile/",
          index: 0,
          promotion_id: promotionId,
          promotion_name: promotionName,
          quantity: 1,
          price: basket.total?.oneTime?.amount,
          shop_language: "de",
          auto_renewal_preference: autorenewalOn
            ? "auto renewal"
            : "one time payment",
          bandwidth: product.bandwidthLabel,
          duration: product.duration,
          base_display_value: product.baseDisplayValue,
        },
      ],
    },
    user_data: {
      first_name: customerData.firstName,
      last_name: customerData.lastName,
      email: customerData.email,
      phone: customerData.phone?.phone,
      city: customerData.mainAddress?.city,
      zip_code: customerData.mainAddress?.zip,
      country: customerData.mainAddress?.country,
    },
  });
};
