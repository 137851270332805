export const TOTAL_DAYS_IN_YEAR = 365;

export const MOBILE_ABO_VALUES = {
  MOBILE_TARIFFS: "MOBILE_TARIFFS",
  ALL_RATES: "ALL_RATES",
} as const;

// TODO: remove strings, use numbers
export const mobileAbosMonthly = ["131601", "131602", "131603"];
// TODO: remove strings, use numbers
export const mobileAbosYearly = ["131611", "131612", "131613"];
export const mobileAbos = [...mobileAbosMonthly, ...mobileAbosYearly];

export const starterKit = "Starterpaket";
