import { Basket, Device } from "@/services/model";
import { mobileAbos } from "@/utils/constants";
import {
  Duration,
  TransformedProduct,
  TransformedProductsById,
} from "@/utils/tariffUtils";

export const isSmartphoneInBasket = (basket?: Basket): boolean =>
  isDeviceSmartphone(basket?.entries[0]?.device);

export const isDeviceSmartphone = (device?: Device): boolean =>
  !!device && device.id === 1;

export const getMobileAbos = (
  processedProductsById: TransformedProductsById | undefined,
) =>
  processedProductsById
    ? Object.values(processedProductsById || {}).filter((product) =>
        mobileAbos.includes(`${product.id}`),
      )
    : [];

export const getSortedMobileAbosMonthly = (
  mobileAbos: TransformedProduct[],
) => {
  // Define the desired order by productId (Flat Mobile Swiss, Flat Mobile, Flat Mobile Plus)
  const desiredOrder = [131602, 131601, 131603];

  return mobileAbos
    .filter((abo) => abo.duration === Duration.month)
    .sort((a, b) => desiredOrder.indexOf(a.id!) - desiredOrder.indexOf(b.id!));
};

export const getSortedMobileAbosYearly = (mobileAbos: TransformedProduct[]) => {
  // Define the desired order by productId (Flat Mobile Swiss, Flat Mobile, Flat Mobile Plus)
  const desiredOrder = [131612, 131611, 131613];

  return mobileAbos
    .filter((abo) => abo.duration === Duration.year)
    .sort((a, b) => desiredOrder.indexOf(a.id!) - desiredOrder.indexOf(b.id!));
};
