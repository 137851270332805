import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Info } from "@/assets/icons/icons";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";

export const RoamingCallExplanation = ({ title }: { title?: string }) => {
  const { t } = useTranslation("portal");

  return (
    <SectionButtonHeader className={clsx(title && "mb-4")} header={title}>
      <Tooltip
        position="bottom"
        className="mt-4 !px-6 !py-4 right text-black font-normal text-left text-base mr-5 sm:mr-6 md:mr-0 min-w-[250px] md:min-w-[327px]"
        content={
          <>
            <h3 className="text-lg font-semibold text-primary-100 pb-2 block">
              {t("cockpit.managementTile.roaming.roamingCallHint.title")}
            </h3>
            <p className="pt-2">
              {t("cockpit.managementTile.roaming.roamingCallHint.text")}
            </p>
          </>
        }
      >
        <Info className="w-[21px] h-[21px] text-primary-100" />
      </Tooltip>
    </SectionButtonHeader>
  );
};
