import { Namespace, t, TFunction } from "i18next";

import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { listAvailableOptions, useListAvailableOptions } from "@/services/api";
import {
  Basket,
  Charge,
  DiscountDetail,
  Option,
  Product,
  ProductAvailability,
} from "@/services/model";

import { mobileAbos, TOTAL_DAYS_IN_YEAR } from "./constants";
import { TariffTypes } from "./deviceUtils";
import { getChargeString } from "./translationHelpers";

export interface TransformedOptions {
  no_voice_month: TransformedProduct;
  no_voice_year: TransformedProduct;
  voice_month: TransformedProduct;
  voice_year: TransformedProduct;
}
export enum Duration {
  year = "year",
  month = "month",
  unknwon = "unknown",
}
export interface TransformedProduct {
  id?: number;
  bandwidthLabel?: string;
  chargeVoice?: string;
  chargeFlat?: string;
  minContractDur?: number;
  duration?: Duration;
  baseDisplayValue?: string;
  shortName?: string;
  recommended?: boolean;
  availability?: ProductAvailability;
  precharged?: boolean;
  // Linked to the voice product of a data-only product
  voice?: number;
  // Linked to the data product of a voice option
  data?: number;
  isVoiceProduct: boolean;
}
export type TransformedProductsById = { [key: string]: TransformedProduct };
export interface TransformedProducts {
  processedProductsById: TransformedProductsById;
  preSelectedProcessedProduct: TransformedProduct | undefined;
  prechargeVoice: boolean;
}

const isVoiceProduct = (product: Product) => product.productGroup?.id === 1303;

const freeCharge: Charge = { amount: 0, currency: { id: 1 } };

/**
 * Get charge price for data rate from Product
 *
 * chargeType with id 4 is the charge for the flat
 **/
const getDataChargeFromProduct = (
  product: Product,
  precharged: boolean | undefined,
) =>
  getChargeString(
    precharged
      ? freeCharge
      : product.charges?.filter(({ chargeType }) => chargeType?.id === 4)?.[0],
  );

/**
 * Get charge price for data rate from Product
 *
 * chargeType with id 4 is the charge for the flat
 **/
const getVoiceChargeFromProduct = (
  product: Product,
  precharged: boolean | undefined,
) =>
  getChargeString(
    precharged
      ? freeCharge
      : product.charges?.filter(
          ({ chargeType }) => chargeType?.id === 400,
        )?.[0],
  );

export const transformProducts: (
  optionsFromApi: Option[] | undefined,
) => TransformedProducts = (optionsFromApi) => {
  if (!optionsFromApi) {
    return {
      processedProductsById: {},
      preSelectedProcessedProduct: undefined,
      prechargeVoice: false,
    };
  }

  const processedProductsById: TransformedProductsById = {};
  let preSelectedProcessedProduct: TransformedProduct | undefined;
  let prechargedOptionSet = false;
  let prechargeVoice = false;

  const filteredOptions = optionsFromApi.filter(
    ({ product }) =>
      product &&
      product.baseDisplayValue &&
      product.id &&
      product?.productSegment?.id === 4,
  );

  const recommendedSet = new Set(
    optionsFromApi
      .filter((option) => option.recommended)
      .map((option) => option.product?.baseDisplayValue),
  );

  filteredOptions.forEach(({ product, recommended, precharged }) => {
    if (!product?.id) return;
    const isVoice = isVoiceProduct(product);
    const baseDisplayValue = product.baseDisplayValue;
    const isRecommended = recommended || recommendedSet.has(baseDisplayValue);

    const transformedProduct: TransformedProduct = {
      id: product.id,
      bandwidthLabel: product?.bandwidthLabel,
      minContractDur: product?.minContractDur,
      baseDisplayValue,
      shortName: product?.shortName,
      ...getVoiceAndData(product, optionsFromApi),
      duration:
        product?.minContractDur === TOTAL_DAYS_IN_YEAR
          ? Duration.year
          : product?.minContractDur === 30
            ? Duration.month
            : undefined,
      recommended: isRecommended,
      chargeFlat: getDataChargeFromProduct(product, precharged),
      ...(isVoice
        ? { chargeVoice: getVoiceChargeFromProduct(product, precharged) }
        : undefined),
      availability: product.availability,
      precharged,
      isVoiceProduct: isVoice,
    };

    if (isVoice) {
      if (precharged) prechargeVoice = true;
    }

    processedProductsById[product.id] = transformedProduct;

    if (
      (!prechargedOptionSet &&
        isRecommended &&
        transformedProduct.chargeFlat) ||
      precharged
    ) {
      if (precharged) {
        if (!preSelectedProcessedProduct || !prechargedOptionSet || isVoice) {
          prechargedOptionSet = true;
          preSelectedProcessedProduct = transformedProduct;
        }
      } else if (
        !preSelectedProcessedProduct ||
        preSelectedProcessedProduct.duration === Duration.year
      ) {
        preSelectedProcessedProduct = transformedProduct;
      }
    }
  });

  return {
    processedProductsById,
    preSelectedProcessedProduct,
    prechargeVoice,
  };
};

export const combineProductsByBaselabel = (products: TransformedProductsById) =>
  Object.values(products)
    .filter((product) => product.id === product.data)
    .reduce(
      (
        prev: { [key: string]: TransformedProduct[] },
        curr: TransformedProduct,
      ): { [key: string]: TransformedProduct[] } =>
        curr.baseDisplayValue
          ? {
              ...prev,
              [curr.baseDisplayValue]: [
                ...(prev[curr.baseDisplayValue] ?? []),
                curr,
              ],
            }
          : prev,
      {},
    );

export const getNextPaymentDate = (days?: number) => {
  const date = new Date();
  date.setDate(date.getDate() + (days ?? 0));

  return date;
};

export const getPrechargedProductFromOptions = (options: Option[]) =>
  options.find((option) => option.precharged)?.product;

export const useGetPrechargedOptionForBasket = (basket: Basket | undefined) => {
  const { data: availableOptions } = useListAvailableOptions(
    basket?.id || -1,
    0,
    undefined,
    { query: { enabled: !!basket } },
  );
  if (!availableOptions) return undefined;

  return getPrechargedProductFromOptions(availableOptions);
};

export const useHasPrechargedMobileAbo = (basket: Basket | undefined) => {
  const prechargedOption = useGetPrechargedOptionForBasket(basket);

  return (
    !!prechargedOption?.id &&
    mobileAbos.includes(prechargedOption.id.toString())
  );
};

export const getPreselectedOption: (
  basket: Basket | undefined,
) => Promise<RateFormProps> = async (basket) => {
  if (!basket) return { rate: "", voice: false, numberPorting: "no" };

  const entry = basket?.entries["0"];
  const optionInBasketId = entry?.options?.[0]?.product?.id;

  const options = await listAvailableOptions(
    basket?.id || -1,
    0,
    undefined,
  ).then((res) => transformProducts(res));

  const { preSelectedProcessedProduct, prechargeVoice, processedProductsById } =
    options || {};

  // Following part covers the logic necessary to set an option from the basket or a pre-selected flat option
  const preselectionLocal: Partial<RateFormProps> = {
    rate: undefined,
    voice: false,
    numberPorting: "no",
  };
  // Wenn wir noch keine Rate gesetzt, und transformierte Produkte haben...
  if (processedProductsById && Object.keys(processedProductsById).length > 0) {
    // Setzt den Formstate basierend auf einer gegebenen Produkt-ID
    const setVoiceAndRate = (productID: number) => {
      const product = processedProductsById[productID];

      if (product?.data) {
        preselectionLocal["rate"] = product.data.toString();
      }
      // Wenn das Produkt eine Voice RoamingOptionRow ist, dann aktiviere die Voice RoamingOptionRow Checkbox.
      if (product?.voice === productID) {
        preselectionLocal["voice"] = true;
      }
    };
    // Does set the form state based on the provided product id - but ignores the voice option
    const setRateOnly = (productID: number) => {
      const product = processedProductsById[productID];

      if (product?.data) {
        preselectionLocal["rate"] = product.data.toString();
      }
    };

    // Priorisierung der Vorauswahl:
    // Prio A: Wenn wir ein vorausgewähltes und voraufgeladenes Produkt haben, dann nimm dieses, da kein anderes Produkt gewählt werden kann.
    // Prio B: Wenn der Kunde schon ein Produkt gewählt hat und diese im Basket liegt, dann nimm dieses.
    // Prio C: Ansonsten: Wenn eine Produktempfehlung, also ein vorausgewähltes, nicht-voraufgeladenes Produkt existiert, dann wähle dieses.
    if (preSelectedProcessedProduct) {
      if (
        preSelectedProcessedProduct.precharged &&
        preSelectedProcessedProduct.id !== undefined
      ) {
        // Prio A
        setVoiceAndRate(preSelectedProcessedProduct.id);

        if (prechargeVoice) {
          preselectionLocal["voice"] = true;
        }
        // special case: Prio A tritt ein, allerdings hat der Nutzer die Voice RoamingOptionRow im Basket. Wir müssen das erkennen und voice vorauswählen
        else if (
          optionInBasketId &&
          processedProductsById[optionInBasketId]?.voice === optionInBasketId
        ) {
          preselectionLocal["voice"] = true;
        }
      } else {
        if (optionInBasketId) {
          // Prio B
          setVoiceAndRate(optionInBasketId);

          const voiceOptionInBasket =
            processedProductsById[optionInBasketId]?.voice;
          if (voiceOptionInBasket === optionInBasketId) {
            preselectionLocal["voice"] = true;
          }
        } else {
          // Prio C
          const { id } = preSelectedProcessedProduct;
          if (id) {
            // For a recommended product only the rate should be set, not the voice option.
            setRateOnly(id);
          }
        }
      }
    }
    // Wenn wir ein Produkt im Warenkorb haben, wollen wir auch bei einem vorausgewählten Produkt die Nummernportierung wiederherstellen.
    if (optionInBasketId) {
      preselectionLocal["numberPorting"] = entry.onp ? "yes" : "no";
    }
  }

  return {
    numberPorting: "no",
    voice: false,
    rate: "",
    ...preselectionLocal,
  };
};

const getVoiceAndData = (product: Product, optionsFromApi: Option[]) => {
  const isMobileAbo = mobileAbos.includes(`${product.id}`);
  if (isMobileAbo) {
    return {
      voice: product.id,
      data: product.id,
    };
  }
  if (isVoiceProduct(product)) {
    return {
      voice: product.id,
      data: optionsFromApi.filter(
        (value) =>
          value.product?.baseDisplayValue === product.baseDisplayValue &&
          value.product?.minContractDur === product?.minContractDur &&
          product.id !== value.product?.id,
      )[0]?.product?.id,
    };
  } else {
    return {
      voice: optionsFromApi.filter(
        (value) =>
          value.product?.baseDisplayValue === product.baseDisplayValue &&
          value.product?.minContractDur === product?.minContractDur &&
          product.id !== value.product?.id,
      )[0]?.product?.id,
      data: product.id,
    };
  }
};

// As Backend sends discount splitted by charges it might be we get more than
// one discount for the same id. So we need to group them accordingly.
export const calculateDiscounts = (discounts?: DiscountDetail[]) => {
  if (!discounts || discounts.length === 0) {
    return []; // Return an empty array if no discounts are provided
  }

  const groupedDiscounts = discounts?.reduce<Record<number, DiscountDetail>>(
    (acc, discount) => {
      // Skip discounts without an id
      if (!discount.id) return acc;

      if (acc[discount.id]) {
        acc[discount.id].amount =
          (acc[discount.id].amount || 0) + (discount.amount || 0);
        acc[discount.id].amountNet =
          (acc[discount.id].amountNet || 0) + (discount.amountNet || 0);
      } else {
        // If this id is new, initialize it in the accumulator
        acc[discount.id] = { ...discount };
      }

      return acc;
    },
    {},
  );

  // Convert the grouped discounts back into an array
  return Object.values(groupedDiscounts);
};

export const getIncludedFeaturesRateSelection = (
  t: TFunction<Namespace, undefined, Namespace>,
  tariffType: TariffTypes,
  minContractDuration: number,
) => {
  // default
  const includedFeatures = [
    {
      name: t("portal:mobileAbo.features.unlimitedData"),
    },
    {
      name: t("portal:mobileAbo.features.unlimitedCallsAndSms"),
    },
  ];

  // flat mobile swiss
  if (tariffType === TariffTypes.flatMobileSwiss) {
    includedFeatures.push({
      name: t("portal:mobileAbo.features.limitedSpeed"),
    });
  } else {
    includedFeatures.push({ name: t("portal:mobileAbo.features.fullSpeed") });
  }

  // flat mobile
  if (tariffType === TariffTypes.flatMobile) {
    includedFeatures.push(
      {
        name: t("portal:mobileAbo.features.roamingEuUsa", {
          volume: minContractDuration === 30 ? "2" : "24",
        }),
      },
      {
        name: t("portal:mobileAbo.features.limitedCallEuUsa", {
          volume: minContractDuration === 30 ? "100" : "1200",
        }),
      },
    );
  }
  // flat mobile plus
  if (tariffType === TariffTypes.flatMobilePlus) {
    includedFeatures.push(
      {
        name: t("portal:mobileAbo.features.roamingEuUsa", {
          volume: minContractDuration === 30 ? "12" : "144",
        }),
      },
      {
        name: t("portal:mobileAbo.features.unlimitedCallEuUsa"),
      },
    );
  }

  return includedFeatures;
};

export const getIncludedFeaturesCostDetail = (
  t: TFunction<Namespace, undefined, Namespace>,
  tariffType: TariffTypes,
  minContractDuration: number,
) => {
  // flat mobile swiss / default
  const includedFeatures = [
    {
      name: t("portal:mobileAbo.features.unlimitedDataCH"),
      price: t("portal:mobileAbo.incl"),
    },
    {
      name: t("portal:mobileAbo.features.unlimitedCallsAndSmsCH"),
      price: t("portal:mobileAbo.incl"),
    },
  ];

  // flat mobile
  if (tariffType === TariffTypes.flatMobile) {
    includedFeatures.push(
      {
        name: t("portal:mobileAbo.features.roamingEuUsa", {
          volume: minContractDuration === 30 ? "2" : "24",
        }),
        price: t("portal:mobileAbo.incl"),
      },
      {
        name: t("portal:mobileAbo.features.limitedCallEuUsa", {
          volume: minContractDuration === 30 ? "100" : "1200",
        }),
        price: t("portal:mobileAbo.incl"),
      },
    );
  }

  // flat mobile plus
  if (tariffType === TariffTypes.flatMobilePlus) {
    includedFeatures.push(
      {
        name: t("portal:mobileAbo.features.roamingEuUsa", {
          volume: minContractDuration === 30 ? "12" : "144",
        }),
        price: t("portal:mobileAbo.incl"),
      },
      {
        name: t("portal:mobileAbo.features.unlimitedCallEuUsa"),
        price: t("portal:mobileAbo.incl"),
      },
    );
  }

  return includedFeatures;
};

// Simple check if tariff name (or other value) includes the 'Flat Mobile' string - if the naming changes in future we only have one place to adapt.
export const isFlatMobileTariff = (value: string) =>
  value.includes("Flat Mobile");

export const getCallsVolume = ({
  t,
  tariffType,
  minContractDuration,
}: {
  t: TFunction<"portal", undefined, "portal">;
  tariffType: TariffTypes;
  minContractDuration?: number;
}) => {
  switch (tariffType) {
    case TariffTypes.flatMobile:
      // Return data volume depending on if it's yearly or monthly contract
      return minContractDuration === TOTAL_DAYS_IN_YEAR
        ? "1200 Min."
        : "100 Min.";
    case TariffTypes.flatMobilePlus:
      return t("cockpit.managementTile.roaming.showOption.active.unlimited");
    default:
      return undefined;
  }
};
