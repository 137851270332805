import { hasMobileAbo } from "@/hooks/useDataContextHooks";
import { DataContextInterface } from "@/provider/DataContextProvider";

import { mobileAbos } from "./constants";

export const getEntry = (dataContext: DataContextInterface) =>
  dataContext.basket?.entries["0"];

export const hasOpenBasketEntry = (dataContext: DataContextInterface) =>
  !!(dataContext && getEntry(dataContext));

export const hasBasketEntryWithNamedDevice = (
  dataContext: DataContextInterface,
) =>
  // @ts-expect-error
  !!dataContext.basket?.entries[0]?.customData?.SimAlias.length;

export const hasBasketEntryWithSelectedRateOption = (
  dataContext: DataContextInterface,
) => !!dataContext.basket?.entries[0]?.options?.length;

export const hasBasketEntryWithVoiceOption = (
  dataContext: DataContextInterface,
) => {
  const basketEntry = getEntry(dataContext);

  return (
    basketEntry?.options?.[0]?.oneTimeCharges?.[0]?.chargeType?.id === 400 ||
    (mobileAbos.includes(
      basketEntry?.options?.[0]?.product?.id.toString() || "",
    ) &&
      !hasMobileAbo(dataContext))
  );
};
