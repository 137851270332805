import "@/components/Layout/DueNow/DueNow.scss";

import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

import { DueRecurringCard } from "@/components/Layout/DueRecurringCard/DueRecurringCard";
import {
  useBillingPeriod,
  useGetFlatPricesAndName,
} from "@/hooks/useDataContextHooks";
import { DataContext } from "@/provider/DataContextProvider";
import { hasBasketEntryWithVoiceOption } from "@/utils/dataContextHelpers";

export const DueRecurringWrappedCard: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "Onboarding.sections.checkout.summary",
  });

  const { dataContext } = useContext(DataContext);
  const {
    flatName,
    voicePriceRecurring,
    flatPriceRecurring,
    recurringTotal,
    recurringSubtotal,
    discountAmount,
    discountEndDate,
  } = useGetFlatPricesAndName();

  const billingPeriod = useBillingPeriod();
  const hasVoiceOption = hasBasketEntryWithVoiceOption(dataContext);

  return (
    <DueRecurringCard
      totalReoccurring={recurringTotal}
      subtotalRecurring={recurringSubtotal}
      discountRecurring={discountAmount}
      discountEndDate={discountEndDate}
      currency={"CHF"}
      flatName={flatName}
      billingPeriod={billingPeriod}
      flatPrice={flatPriceRecurring}
      {...(hasVoiceOption
        ? {
            flatOption: t("voiceOption"),
            flatOptionPrice: voicePriceRecurring,
          }
        : {})}
    />
  );
};
