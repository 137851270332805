import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { QuestionMark } from "@/assets/icons/icons";

export const SupportLinkItem = () => {
  const { t } = useTranslation();

  return (
    <a
      tabIndex={0}
      aria-label={t("Common.support")}
      href={t("Footer.support.url")}
      target="_blank"
      rel="noreferrer"
      className={clsx(
        "flex xl:py-0 py-2 text-lg text-white focus:outline-none gap-2 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 group hover:underline hover:text-secondary-100 hover:font-semibold",
      )}
    >
      <div className="items-center xl:border-2 border-transparent group-hover:border-white group-hover:bg-transparent justify-center h-10 flex xl:h-10 xl:w-10 xl:bg-white xl:rounded-full">
        <QuestionMark className="w-6 [&>path]:stroke-white [&>path]:fill-white xl:[&>path]:fill-primary-100 group-hover:[&>path]:fill-white" />
      </div>
      <span className={clsx("pb-px my-auto whitespace-nowrap xl:hidden")}>
        {t("Common.support")}
      </span>
    </a>
  );
};
