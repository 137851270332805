import { RoamingInfo } from "@/services/model";
import { getTariffType, TariffTypes } from "@/utils/deviceUtils";

export const useShouldDisplayRoamingHints = ({
  roamingInfo,
  serviceId,
}: {
  roamingInfo: RoamingInfo;
  serviceId?: number;
}) => {
  const tariffType = getTariffType(serviceId);

  // If the initial volume is less than x MB, we started the mobile abo somewhere in the middle of the month. In this case, we show a hint to the user explaining the odd volume.
  const maxVolume = tariffType === TariffTypes.flatMobilePlus ? 12288 : 2048;

  const shouldDisplayPartialHint =
    roamingInfo.initialVolume && roamingInfo.initialVolume < maxVolume;

  return {
    shouldDisplayPartialHint,
    shouldDisplayRenewalHint: !shouldDisplayPartialHint,
  };
};
