import "./VoiceOption.scss";

import clsx from "clsx";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { VoiceOptionCall } from "@/assets/icons/icons";
import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";
import { FreeEsimTrialTooltip } from "@/components/Onboarding/Steps/RateSelection/FreeEsimTrialTooltip/FreeEsimTrialTooltip";
import { OptionContainer } from "@/components/Onboarding/Steps/RateSelection/OptionContainer/OptionContainer";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { useIsMobile } from "@/hooks/useIsMobile";
import { FCC } from "@/types";
import { TransformedProduct } from "@/utils/tariffUtils";

interface Props {
  currentVoice?: TransformedProduct;
  hasFreeTrial: boolean;
  prechargeVoice: boolean;
}

export const VoiceOption: FC<Props> = ({
  currentVoice,
  hasFreeTrial,
  prechargeVoice,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<RateFormProps>();

  const isVoiceSelected = watch("voice") !== undefined && watch("voice");

  const hasNecessaryData =
    currentVoice?.minContractDur && currentVoice?.chargeVoice;

  const Container: FCC<{ isSelected?: boolean }> = ({
    children,
    isSelected,
  }) =>
    isMobile ? (
      <div
        className={clsx(
          "flex flex-col relative mt-6 shadow-primary border-1",
          isSelected && "border-solid border-highlight-100 bg-subtle-selected",
          "voice",
          prechargeVoice && "precharged",
        )}
      >
        {children}
      </div>
    ) : (
      <OptionContainer
        selected={isVoiceSelected}
        highlighted
        precharged={prechargeVoice}
        voice
      >
        {children}
      </OptionContainer>
    );

  return (
    <div className="mt-4" data-testid="voice-option">
      <p className="mb-12">
        {t("translation:Onboarding.sections.rate-selection.wouldYouLikeTo")}
        <br />
        <span>
          <Trans
            t={t}
            components={[
              <a
                key="voiceOptionLink"
                href={t(
                  "translation:Onboarding.sections.rate-selection.voiceOptionLink",
                )}
                className="text-primary-100 underline"
                target="_blank"
                rel="noreferrer"
              />,
            ]}
            i18nKey="translation:Onboarding.sections.rate-selection.discoverVoiceOption"
          />
        </span>
      </p>
      <Container isSelected={isVoiceSelected}>
        <div className={clsx(isMobile || "voice-container")}>
          <div
            className={clsx(
              "descriptions",
              isMobile && "p-6 border-b-1 border-primary-50",
            )}
          >
            <div
              className={clsx(
                "title",
                isMobile && "flex font-semibold text-primary-100",
              )}
            >
              <VoiceOptionCall className={clsx("phone", isMobile && "mr-4")} />
              <span>
                {t("translation:Onboarding.sections.rate-selection.callAndSMS")}
              </span>
            </div>
          </div>
          <div className={clsx(isMobile ? "px-6 py-4" : "durations")}>
            {hasNecessaryData ? (
              <>
                {isMobile || (
                  <div className="days">
                    {currentVoice?.minContractDur}{" "}
                    {t("translation:Onboarding.sections.rate-selection.days")}
                  </div>
                )}
                <div id="price">
                  <div className="checkbox">
                    <Checkbox
                      id="voice"
                      className={clsx(isMobile && "text-base")}
                      labelClassName={clsx(isMobile && "mb-1")}
                      name="voice"
                      label={`${isMobile ? "" : "+"} ${currentVoice?.chargeVoice}`}
                      errors={errors}
                      register={register}
                    />
                  </div>

                  <div
                    className={clsx(
                      "days-label",
                      isMobile && "ml-8 text-sm text-primary-100",
                    )}
                  >
                    {currentVoice?.minContractDur}{" "}
                    {t("translation:Onboarding.sections.rate-selection.days")}
                  </div>
                </div>
                {hasFreeTrial && (
                  <p
                    className={clsx(
                      "flex col-span-2 font-x-small whitespace-break-spaces",
                      isMobile ? "mt-2 text-primary-100" : "mt-4",
                    )}
                  >
                    <span className="mr-2">
                      {t(
                        "translation:Onboarding.sections.rate-selection.esim-trial-hint",
                      )}
                    </span>
                    <FreeEsimTrialTooltip
                      iconClassName="!w-4 !h-4 my-1"
                      leftOnMobile={true}
                    />
                  </p>
                )}
              </>
            ) : (
              <p className="font-normal break-words whitespace-normal">
                {t(
                  "translation:Onboarding.sections.rate-selection.voiceOptionRequirement",
                )}
              </p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
