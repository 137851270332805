import "@/components/Interface/FormFields/ListBox/ListBox.scss";

import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ArrowDown, Check } from "@/assets/icons/icons";

export const ListBoxMultiple = <OptionType,>({
  className,
  selected,
  setSelected,
  displayValue,
  availableValues,
  placeholder,
  itemLabel,
  renderOption = (option: OptionType, selected: boolean) => (
    <span className={`text ${selected ? "selected" : ""}`}>
      {displayValue(option)}
    </span>
  ),
}: {
  className?: string;
  selected: OptionType[] | undefined;
  setSelected: (value: OptionType[]) => void;
  displayValue: (value: OptionType) => string;
  availableValues: OptionType[];
  placeholder: string;
  itemLabel: string;
  renderOption?: (
    option: OptionType,
    selected: boolean,
    active: boolean,
  ) => JSX.Element;
}) => {
  const { t } = useTranslation();
  const translateMultipleValues: (value: OptionType[] | undefined) => any = (
    value = [],
  ) => {
    if (value.length > 3) {
      return (
        <>
          {`${itemLabel} `}
          <span className="text-primary-100">{`(${value.length} ${t(
            "Common.selected",
          )})`}</span>
        </>
      );
    }

    return value.length > 0 ? value.map(displayValue).join(", ") : placeholder;
  };

  return (
    <Listbox value={selected ? selected : []} onChange={setSelected} multiple>
      {({ open }) => (
        <div className={clsx("listbox-container", className)}>
          <div className="relative">
            <Listbox.Button className="listbox-button">
              <span className="block truncate text-black">
                {/* i think we need to change this - we want to show the amount of devices selected and not render them in words */}
                {translateMultipleValues(selected)}
              </span>
            </Listbox.Button>
            <div className="icon-wrapper">
              <ArrowDown className="icon" aria-hidden="true" />
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="listbox-options">
              {availableValues?.map((column, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) => `option ${active ? "active" : ""}`}
                  value={column}
                >
                  {({ selected, active }) => (
                    <>
                      {renderOption(column, selected, active)}

                      {selected && (
                        <Check
                          aria-hidden="true"
                          className={`icon selected 
                            ${active ? "active" : ""}
                          `}
                        />
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
