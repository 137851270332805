import "./StatusChips.scss";

import React from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FilterDialogForm } from "@/components/Portal/Cockpit/Filter/Filter";

import { StatusChip } from "./StatusChip/StatusChip";

export const StatusChips = ({
  watch,
  setValue,
}: {
  register: UseFormRegister<FilterDialogForm>;
  setValue: UseFormSetValue<FilterDialogForm>;
  watch: UseFormWatch<FilterDialogForm>;
}) => {
  const { t } = useTranslation("portal");
  const simStatusWatch = watch("simStatus");
  const colorMapping = [
    {
      color: "GREEN",
      translation: "cockpit.filter.status-labels.active",
    },
    {
      color: "YELLOW",
      translation: "cockpit.filter.status-labels.activeLimited",
    },
    { color: "RED", translation: "cockpit.filter.status-labels.suspended" },
    { color: "GREY", translation: "cockpit.filter.status-labels.inactive" },
  ] as const;

  return (
    <div className="status-chips-wrapper">
      {colorMapping.map((status, index) => (
        <StatusChip
          key={index}
          statusColor={status.color?.toLowerCase()}
          enabled={simStatusWatch.includes(status.color)}
          onChange={(value: boolean) =>
            value
              ? setValue("simStatus", [...simStatusWatch, status.color])
              : setValue(
                  "simStatus",
                  simStatusWatch.filter((value) => value !== status.color),
                )
          }
          statusLabel={t(status.translation)}
        />
      ))}
    </div>
  );
};
