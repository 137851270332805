import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { SimCardDetailNetworkResetNotAllowedReasonsItem } from "@/services/model";

interface Props {
  reasons?: SimCardDetailNetworkResetNotAllowedReasonsItem[];
  leftOnMobile?: boolean;
  dataTestId?: string;
}

export const NetworkResetDisabledTooltip: FCC<Props> = ({
  reasons = [],
  leftOnMobile,
  children,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix:
      "cockpit.managementTile.details.sections.details.networkReset.reasons",
  });

  // Reasons from api
  const {
    SIM_LOCKED,
    SIM_CANCELLED,
    SIM_TERMINATED,
    SIM_INACTIVE,
    OPEN_ONP,
    OPEN_ORDER,
    OPEN_SPEED_AND_LOCK,
  } = SimCardDetailNetworkResetNotAllowedReasonsItem;

  const translatedReasons = reasons.reduce<string[]>((acc, reason) => {
    switch (reason) {
      case SIM_LOCKED:
        acc.push(t("simLocked"));
        break;
      case SIM_CANCELLED:
        acc.push(t("simCancelled"));
        break;
      case SIM_TERMINATED:
        acc.push(t("simTerminated"));
        break;
      case SIM_INACTIVE:
        acc.push(t("simInactive"));
        break;
      case OPEN_ONP:
        acc.push(t("openONP"));
        break;
      case OPEN_ORDER:
        acc.push(t("openOrder"));
        break;
      case OPEN_SPEED_AND_LOCK:
        acc.push(t("openSpeedAndLock"));
        break;
    }

    return acc;
  }, []);

  return (
    <Tooltip
      className="w-72"
      dataTestId="network-reset-disabled-tooltip"
      content={
        <>
          <h5 className="text-base font-semibold text-primary-100">
            {t("portal:common.tooltip.actionNotPossible.title")}
          </h5>
          <p className="text-black font-x-small">
            {t("portal:common.tooltip.actionNotPossible.text", {
              count: translatedReasons.length,
            })}
          </p>
          <ul className="font-x-small text-black list-disc list-outside pl-4">
            {translatedReasons?.map((reason: string) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </>
      }
      position="top"
      leftOnMobile={leftOnMobile}
    >
      {children}
    </Tooltip>
  );
};
