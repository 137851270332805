import { useTranslation } from "react-i18next";

import { DueNow } from "@/components/Layout/DueNow/DueNow";
import { DueNowLightInterface } from "@/components/Layout/DueNowLight/DueNowLightInterface";
import { availableCurrencies } from "@/utils/translationHelpers";

export const TariffDueNow = ({
  icon,
  totalOnceAmount,
  subtotalOnceAmount,
  discountOnceAmount,
  discountEndDate,
  credit,
  creditTimeRemaining,
  currency,
  dataRateName,
  dataRatePrice = 0,
  voiceOptionPrice,
  billingPeriod,
  watchSimName,
  watchSimPrice,
  isDowngrade,
}: {
  icon: JSX.Element;
  totalOnceAmount: number;
  subtotalOnceAmount: number;
  discountOnceAmount?: number;
  discountEndDate?: Date;
  credit?: number;
  creditTimeRemaining?: number;
  currency: availableCurrencies;
  dataRateName?: string;
  dataRatePrice?: number;
  voiceOptionPrice?: number;
  billingPeriod: string;
  watchSimName?: string;
  watchSimPrice?: number;
  isDowngrade?: boolean;
}) => {
  const { t } = useTranslation();

  return isDowngrade && totalOnceAmount <= 0 ? (
    <DueNowLightInterface
      totalOnce={totalOnceAmount <= 0 ? 0 : totalOnceAmount}
      currency={currency}
      icon={icon}
    />
  ) : (
    <DueNow
      totalOnce={totalOnceAmount}
      subtotalOnce={subtotalOnceAmount}
      discountAmount={discountOnceAmount}
      discountEndDate={discountEndDate}
      currency={currency}
      icon={icon}
      dataRateName={dataRateName}
      dataRatePrice={dataRatePrice}
      voiceOptionName={t("common.device.attributes.voiceOption")}
      voiceOptionPrice={voiceOptionPrice}
      billingPeriod={billingPeriod}
      credit={credit}
      creditTimeRemaining={creditTimeRemaining}
      watchSimName={watchSimName}
      watchSimPrice={watchSimPrice}
    />
  );
};
