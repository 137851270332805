import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Checkbox } from "@/components/Interface/FormFields/Checkbox/Checkbox";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  name: Path<T>;
}

export const TermsAgreement = <T extends FieldValues>({
  register,
  errors,
  name,
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <Checkbox<T>
      id="termsAgreement"
      name={name}
      value="yes"
      labelComponent={
        <div className="w-60 xsPlus:w-full">
          <Trans
            components={[
              <a
                target="_blank"
                aria-label="Link to terms and condition"
                key="termsAndConditionsLink"
                href={t("Footer.termsConditions.url")}
                className="text-primary-100 underline"
                rel="noreferrer"
              />,
            ]}
            i18nKey="Onboarding.sections.checkout.acceptTerms"
          />
        </div>
      }
      errors={errors}
      register={register}
    />
  );
};
