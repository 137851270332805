import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { StyledLink, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { NumberForm } from "@/hooks/useNumberForm";

import { AuthenticatorPhoneNumberField } from "./AuthenticatorPhoneNumberField";

export const AuthenticatorSmsSetupForm = ({
  formMethods,
  onSubmit,
}: {
  formMethods: UseFormReturn<NumberForm, any, undefined>;
  onSubmit: (data: NumberForm) => void;
}) => {
  const { t } = useTranslation();

  return (
    <form
      className="flex flex-col gap-10"
      onSubmit={formMethods.handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-6">
        <h1 className="text-secondary-100 text-xl font-semibold">
          {t("portal:authenticator.sms.enterNumber.title")}
        </h1>
        <p className="text-black">
          {t("portal:authenticator.sms.enterNumber.description")}
        </p>
        <AuthenticatorPhoneNumberField
          register={formMethods.register}
          errors={formMethods.formState.errors}
        />
      </div>
      <ConfirmationButtonFlexBox justify="between" forceButtonWidth>
        <StyledLink className="inverted accent" to={-1 as any}>
          {t("portal:common.buttons.back")}
        </StyledLink>
        <SubmitButton
          label={t("portal:authenticator.sms.enterNumber.sendCode")}
          className="accent"
        />
      </ConfirmationButtonFlexBox>
    </form>
  );
};
