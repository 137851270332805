import { useTranslation } from "react-i18next";
import { FCC } from "types";

import {
  MenuBills,
  MenuCockpit,
  MenuDevicePlus,
  MenuPartner,
  MenuUser,
} from "@/assets/icons/icons";
import { MenuItem } from "@/components/Layout/MenuItem/MenuItem";
import { Sidemenu } from "@/components/Layout/Sidemenu/Sidemenu";

export const menuItems = [
  {
    icon: <MenuCockpit />,
    text: "layout.menu.items.cockpit",
    target: "/portal/cockpit",
  },
  {
    icon: <MenuDevicePlus />,
    target: "/portal/add-device",
    text: "layout.menu.items.devicePlus",
  },
  {
    icon: <MenuBills />,
    target: "/portal/bills",
    text: "layout.menu.items.bills",
  },
  {
    icon: <MenuPartner />,
    target: "/portal/referral",
    text: "layout.menu.items.referral",
  },
  {
    icon: <MenuUser />,
    target: "/portal/user",
    text: "layout.menu.items.account",
  },
] as const;

export const PortalSidemenu: FCC = () => {
  const { t } = useTranslation();

  return (
    <div id="portal-desktop-sidemenu" className="hidden xl:block">
      <Sidemenu>
        <ul className="flex flex-col gap-8 text-white z-[2]">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={item.icon}
              text={t(item.text)}
              target={item.target}
            />
          ))}
        </ul>
      </Sidemenu>
    </div>
  );
};
