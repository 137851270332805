/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type SimActionsSimActionsItem =
  (typeof SimActionsSimActionsItem)[keyof typeof SimActionsSimActionsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimActionsSimActionsItem = {
  canChangeTripleToESim: "canChangeTripleToESim",
  canChangeESim: "canChangeESim",
  canResetESim: "canResetESim",
  noMoreActions: "noMoreActions",
  actionsNotAllowed: "actionsNotAllowed",
  canOrderRoaming: "canOrderRoaming",
} as const;
