import "./NotFound.scss";

import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { StyledLink } from "@/components/Interface/Button/Button";
import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { LandingBlob } from "@/components/Welcome/LandingBlob/LandingBlob";
import { WelcomeHeader } from "@/components/Welcome/WelcomeHeader/WelcomeHeader";

export const NotFound: FCC = () => {
  const { t } = useTranslation();

  return (
    <Background>
      <WelcomeHeader />
      <LandingBlob>
        <div id="not-found-smileys" />
        <div id="text-and-buttons">
          <h1 className="mb-6 max-w-min whitespace-nowrap md:max-w-full md:hero text-secondary-100">
            {t("Error.404.title")}
          </h1>
          <div className="mb-10 max-w-[600px]">
            <span>{t("Error.404.text")}</span>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-6 w-fit md:w-auto ">
            <StyledLink to="/portal" className="min-w-full md:min-w-fit">
              {t("Error.404.backToThePortal")}
            </StyledLink>
          </div>
        </div>
      </LandingBlob>
      <Footer className="welcome-screen" />
    </Background>
  );
};
