import { captureMessage, withScope } from "@sentry/react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AutomotiveCar from "@/assets/icons/devices/AutomotiveCar.svg?react";
import Cameras from "@/assets/icons/devices/Cameras.svg?react";
import Hotspot from "@/assets/icons/devices/Hotspot.svg?react";
import IoT from "@/assets/icons/devices/IoT.svg?react";
import Laptop from "@/assets/icons/devices/Laptop.svg?react";
import Location from "@/assets/icons/devices/Location.svg?react";
import Phone from "@/assets/icons/devices/Phone.svg?react";
import RouterHome from "@/assets/icons/devices/RouterHome.svg?react";
import Tablet from "@/assets/icons/devices/Tablet.svg?react";
import VRHeadset from "@/assets/icons/devices/VRHeadset.svg?react";
import Wearable from "@/assets/icons/devices/Wearable.svg?react";
import { Button } from "@/components/Interface/Button/Button";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { IconTile } from "@/components/Onboarding/IconTile/IconTile";
import { Step } from "@/components/Onboarding/Steps/Step";
import { WatchSimOrWearable } from "@/components/Portal/WatchSimOrWearable/WatchSimOrWearable";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { useIsInsidePortal } from "@/hooks/useIsInsidePortal";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import {
  useGetDevices,
  useSetDeviceByUuid,
  useSetEntryCustomData,
} from "@/services/api";
import { Basket, Device } from "@/services/model";
import { pushDeviceTypeSelection } from "@/utils/analytics/onboardingAnalytics";
import { getEntry } from "@/utils/dataContextHelpers";
import {
  allowedDeviceNameRegex,
  DeviceName,
  deviceNameToValueMap,
  getIconForDevice,
} from "@/utils/deviceUtils";
import { log } from "@/utils/log";
import { useHasPrechargedMobileAbo } from "@/utils/tariffUtils";

type DeviceFormProps = {
  deviceName: string;
  deviceType?: Device;
};

// TODO: Used in multiple places, move out/up (applies to many places. Check for other `export`s `like these)
export const deviceToIconMap: {
  [description in DeviceName]: JSX.Element;
} = {
  Smartphone: <Phone />,
  Tablet: <Tablet />,
  Laptop: <Laptop />,
  "WLAN Home Router": <RouterHome />,
  "Mobile Hotspot": <Hotspot />,
  "GPS Tracker": <Location />,
  Wearable: <Wearable />,
  Kamera: <Cameras />,
  "IoT Gerät": <IoT />,
  Fahrzeug: <AutomotiveCar />,
  Andere: <VRHeadset />,
};

/**
 * DeviceType component handles the selection of a device type during the onboarding process.
 * It manages the state and interactions related to device selection, including form validation,
 * navigation, and updating the context with the selected device information.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <DeviceType />
 *
 * @hook
 * @function
 * @name DeviceType
 */
export const DeviceType: FC = () => {
  const [isAnonymousUser] = useIsAnonymousUser();
  const isAddDevice = useIsInsidePortal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dataContext, setDataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const entry = getEntry(dataContext);
  const customData = entry?.customData;
  const simAliasKey = "SimAlias" as keyof typeof customData;
  const presetDeviceId = entry?.device?.id;
  const presetDeviceName = customData?.[simAliasKey] as string | undefined;
  const focusRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    if (focusRef.current) {
      focusRef.current.scrollIntoView();
    }
  };

  // Get the device id from the device query parameter
  const [deviceQueryParam, setDeviceQueryParam] = useQueryParam("device");
  const hasPrechargedMobileAbo = useHasPrechargedMobileAbo(dataContext.basket);

  const {
    register,
    watch,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<DeviceFormProps>({
    mode: "onChange",
    defaultValues: async () => {
      if (deviceQueryParam) {
        return {
          deviceType: { id: Number(deviceQueryParam) },
          deviceName: presetDeviceName as string,
        };
      }

      return { deviceName: presetDeviceName as string };
    },
  });
  useEffect(() => {
    if (hasPrechargedMobileAbo) {
      setDeviceQueryParam("1");
      setValue("deviceType", { id: 1 });
    }
  }, [hasPrechargedMobileAbo, setDeviceQueryParam, setValue]);

  const selectedDevice = watch("deviceType");
  const deviceName = watch("deviceName", "");

  // Scrolls to the device name input. This is used after selecting a device type
  const scrollToDeviceNameTextField = () => {
    setFocus("deviceName");
  };

  const { data: devices, isFetching: isLoadingDevices } = useGetDevices({
    query: {
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (devices) => {
        if (presetDeviceId && !selectedDevice) {
          const device = devices.find(({ id }) => id === presetDeviceId);

          if (device) {
            setValue("deviceType", device);
          }
        }
      },
    },
  });

  const { mutate: setDevice, isLoading: isSettingDevice } = useSetDeviceByUuid({
    mutation: {
      onSuccess: (data) => {
        setDataContext((prev) => ({
          ...prev,
          basket: data,
        }));
      },
    },
  });

  const { mutateAsync: setEntryCustomData, isLoading: isSettingEntryData } =
    useSetEntryCustomData();

  // Add device to basket on selection
  useEffect(() => {
    if (dataContext.basket?.id && selectedDevice?.id && entry?.uuid) {
      setDevice({
        basketId: dataContext.basket.id,
        uuid: entry?.uuid,
        deviceId: selectedDevice.id,
      });
    }
  }, [dataContext.basket?.id, entry?.uuid, selectedDevice?.id, setDevice]);

  const handleDeviceSettingSuccess = (data: Basket) => {
    setDataContext((prev) => ({
      ...prev,
      basket: data,
      allowedMaxOnboardingStep: 3,
    }));
    pushDeviceTypeSelection(
      getEntry(dataContext)?.device?.description ?? "Undefined",
    );
    navigate(`${basePath}/3/rate-selection`);
  };

  const handleError = () => {
    log(`
      ${deviceName.length >= 3 ? "" : "deviceName.length >= 3"}
      ${dataContext.basket?.id ? "" : "dataContext.basket?.id"}
      ${dataContext.basket?.entries ? "" : "dataContext.basket?.entries"}
      ${entry ? "" : "dataContext.basket?.entries[0]"}
      ${entry?.uuid ? "" : "dataContext.basket?.entries[0].uuid"}`);

    withScope(function (scope) {
      scope.setTag("section", "onboarding");
      scope.setTag("onboardingStep", "devicetype");
      scope.setContext("deviceType", {
        basketId: dataContext.basket?.id,
        deviceNameLength: deviceName.length,
        basketEntries: dataContext.basket?.entries,
        basketEntry: dataContext.basket?.entries?.[0],
      });

      captureMessage(
        "Navigation in DeviceType was triggered while it should be disabled.",
      );
    });
  };

  const navigateToNext = () => {
    if (deviceName.length >= 3 && dataContext.basket?.id && !!entry?.uuid) {
      setEntryCustomData({
        basketId: dataContext.basket.id,
        uuid: entry.uuid,
        data: {
          SimAlias: deviceName,
        },
      }).then(handleDeviceSettingSuccess);
    } else {
      handleError();
    }
  };

  useEffect(() => {
    if (deviceQueryParam) {
      setFocus("deviceName");
      executeScroll();
    }
  });

  const hasValidationErrors = !!Object.keys(errors).length;
  const isLoading = isLoadingDevices || isSettingDevice || isSettingEntryData;
  const continueIsDisabled =
    selectedDevice === undefined || hasValidationErrors;

  return (
    <Step
      id="onboarding-device-type"
      headline={t("Onboarding.sections.device-type.whichDevice")}
      subheadline={t("Onboarding.sections.device-type.subtitle")}
      navigation={
        <div className="nav-button-container">
          <Button
            onClick={() =>
              navigate(
                `${basePath}/1/sim-${
                  entry?.esim ? "selection" : "verification"
                }`,
              )
            }
            className="accent inverted"
            disabled={isAnonymousUser}
          >
            {t("Common.label.back")}
          </Button>

          <Button
            className="accent"
            disabled={continueIsDisabled}
            onClick={() => navigateToNext()}
          >
            {t("Common.label.forward")}
          </Button>
        </div>
      }
    >
      {isLoading && <LoadingSpinner />}

      <div className="flex-col pl-2 mt-12 lg:mt-10">
        <span className="font-semibold text-primary-100">
          {t("Onboarding.sections.device-type.deviceType")}
        </span>

        <div className="flex flex-wrap mt-4 gap-5 md:gap-6">
          {isLoadingDevices
            ? t("loading")
            : devices
                // sort based on id
                ?.sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                // map to icon tiles
                .map((d) => (
                  <IconTile
                    key={d.id}
                    disabled={
                      (!!deviceQueryParam || hasPrechargedMobileAbo) &&
                      d.id !== Number(deviceQueryParam)
                    }
                    icon={getIconForDevice(d.description)}
                    label={t(
                      `Common.devices.${
                        deviceNameToValueMap[d.description as DeviceName]
                      }`,
                    )}
                    selected={d.id === selectedDevice?.id}
                    onClick={() => {
                      if (isAddDevice && d.description === "Wearable") {
                        setIsModalOpen(true);
                      } else {
                        scrollToDeviceNameTextField();
                      }
                      // Set this value in any case, cause the ordinary flow might be followed.
                      setValue("deviceType", d);
                    }}
                  />
                ))}
        </div>

        {isModalOpen && (
          <WatchSimOrWearable
            closeModal={() => {
              setIsModalOpen(false);
              executeScroll();
            }}
          />
        )}

        <span ref={focusRef} />

        <FormField<DeviceFormProps>
          id="device-name-input"
          label={t("Onboarding.sections.device-type.deviceName")}
          name="deviceName"
          className="mt-8 md:w-[320px]"
          errors={errors}
          register={register}
          type="text"
          placeholder={t(
            "Onboarding.sections.device-type.deviceNamePlaceholder",
          )}
          options={{
            required: t("label.validation.required"),
            minLength: {
              value: 3,
              message: t("label.validation.minLength", { min: 3 }),
            },
            pattern: {
              message: t("label.validation.deviceNameInvalidChars"),
              value: allowedDeviceNameRegex,
            },

            onChange: (e: KeyboardEvent) => {
              const input = e.target as HTMLInputElement;
              let value = input.value;
              const start = input.selectionStart;
              const end = input.selectionEnd;

              if (value.length > 24) {
                value = value.substring(0, 24);
              }

              setValue("deviceName", value);
              input.selectionStart = start;
              input.selectionEnd = end;
            },
          }}
        />
      </div>
    </Step>
  );
};
