import "@/components/Portal/Cockpit/DeviceManagement/Details/Details.scss";

import { useAtom } from "jotai";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { DetailsEsimDisplay } from "@/components/Portal/Cockpit/DeviceManagement/Details/DetailsEsimDisplay/DetailsEsimDisplay";
import { DeviceNameRow } from "@/components/Portal/Cockpit/DeviceManagement/Details/DeviceNameRow/DeviceNameRow";
import { DeviceTypeRow } from "@/components/Portal/Cockpit/DeviceManagement/Details/DeviceTypeRow/DeviceTypeRow";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { getSim } from "@/services/api";
import { Device, SimCardDetail } from "@/services/model";
import { isEditingSimDetailsAtom } from "@/utils/atoms";
import { iccidWithSpaces, toPhoneNumber } from "@/utils/deviceUtils";

import { NetworkReset } from "./NetworkReset/NetworkReset";
import { useSimMutation } from "./useSimMutation";

export type DeviceDetailsForm = { device: Device; alias: string };

export const DetailsDisplay: FC = () => {
  const { t } = useTranslation("portal");

  const [, setIsEditing] = useAtom(isEditingSimDetailsAtom);
  const [simId] = useSimIdFromQueryParam();
  const [simDetail, setSimDetail] = useState<SimCardDetail | undefined>(
    undefined,
  );

  const formMethods = useForm<DeviceDetailsForm>({
    defaultValues: async () => {
      const simData = await getSim({ simId: Number(simId) });
      setSimDetail(simData);

      return {
        device: simData.device ?? {},
        alias: simData.alias ?? "",
      };
    },
    mode: "onChange",
  });

  const { mutate } = useSimMutation();
  const updateDeviceDetails = (data: DeviceDetailsForm) => {
    if (!data) return;
    if (simDetail?.simId) {
      mutate({
        params: { simId: simDetail.simId },
        data: { sim_alias: data.alias, device_type: data.device.id },
      });
    }
    setIsEditing(false);
  };

  return (
    <div className="management-grid-layout">
      <section
        className="management-section device-settings"
        data-testid="device-settings"
      >
        <h3 className="text-secondary-100 font-semibold mb-6">
          {t("cockpit.managementTile.details.sections.details.deviceDetails")}
        </h3>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(updateDeviceDetails)}>
            <div id="type-row">
              <DeviceTypeRow />
            </div>
            <div className="mb-16" data-testid="device-name-row">
              <DeviceNameRow />
            </div>
          </form>
        </FormProvider>
        <div>
          <DetailsEsimDisplay />
        </div>
        <NetworkReset
          disabled={!simDetail?.networkResetAllowed}
          reasons={simDetail?.networkResetNotAllowedReasons}
        />
      </section>
      <section
        className="management-section device-details"
        data-testid="device-details"
      >
        <h3 className="text-secondary-100 font-semibold mb-6">
          {t("cockpit.managementTile.details.sections.details.simDetails")}
        </h3>
        <SectionButtonHeader header={t("common.device.attributes.simType")} />
        <span className="block mb-6 font-semibold text-primary-100">
          {simDetail?.simType === "Triple SIM"
            ? t("cockpit.filter.dropdowns.simType.TRIPLESIM")
            : t("cockpit.filter.dropdowns.simType.ESIM")}
        </span>
        <SectionButtonHeader header={t("common.device.attributes.simNumber")} />
        <span className="block mb-6 font-semibold text-primary-100">
          {iccidWithSpaces(simDetail?.iccid)}
        </span>
        <SectionButtonHeader
          header={t("common.device.attributes.telephoneNumber")}
        />
        <span className="block mb-6 font-semibold text-primary-100">
          {toPhoneNumber(simDetail?.msisdn || "")}
        </span>
        <SectionButtonHeader header={t("common.device.attributes.pin")} />
        <span className="block mb-6 font-semibold text-primary-100">
          {simDetail?.pin || "-"}
        </span>
        <SectionButtonHeader header={t("common.device.attributes.puk")} />
        <span className="block mb-6 font-semibold text-primary-100">
          {simDetail?.puk || "-"}
        </span>
      </section>
    </div>
  );
};
