/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type DrPaymentMeanReasonsItem =
  (typeof DrPaymentMeanReasonsItem)[keyof typeof DrPaymentMeanReasonsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrPaymentMeanReasonsItem = {
  OUTSTANDING_RATED_SERVICES_CHARGES: "OUTSTANDING_RATED_SERVICES_CHARGES",
  OPEN_BILL: "OPEN_BILL",
  RATED_SERVICES_SETTINGS_ON: "RATED_SERVICES_SETTINGS_ON",
  PAY_AS_YOU_GO_ON: "PAY_AS_YOU_GO_ON",
} as const;
