import { useGetPaymentMean } from "@/services/api";
import {
  DrCreditCardCardType,
  DrPaymentMean,
  DrPaymentMeanPaymentMode,
} from "@/services/model";

export const transformPaymentMean = (paymentMean?: DrPaymentMean) => {
  const paymentMode = paymentMean?.paymentMode;
  const cardType = paymentMean?.creditCard?.cardType;
  const maskedCardNumber = paymentMean?.creditCard?.maskedCardNumber;
  const validityMonth = paymentMean?.creditCard?.validityMonth;
  const validityYear = paymentMean?.creditCard?.validityYear;
  const maskedCardNumberString = maskedCardNumber
    ? `${maskedCardNumber}   |   ${validityMonth}/${validityYear}`
    : "";
  const isInvoice = paymentMode === DrPaymentMeanPaymentMode.MANUAL_PAYMENT;
  const isCreditCard =
    paymentMode === DrPaymentMeanPaymentMode.CREDITCARD &&
    cardType &&
    cardType in DrCreditCardCardType;
  const noPaymentMethod = !isInvoice && !isCreditCard;
  const canDeleteCreditCard = paymentMean?.canDeleteCreditCard;

  return {
    paymentMode,
    cardType,
    maskedCardNumber,
    validityMonth,
    validityYear,
    maskedCardNumberString,
    isInvoice,
    isCreditCard,
    noPaymentMethod,
    canDeleteCreditCard,
  };
};

export const usePaymentMode = () => {
  const { data: paymentMean, refetch, isLoading } = useGetPaymentMean();

  const {
    paymentMode,
    cardType,
    maskedCardNumber,
    validityMonth,
    validityYear,
    maskedCardNumberString,
    isInvoice,
    isCreditCard,
    noPaymentMethod,
    canDeleteCreditCard,
  } = transformPaymentMean(paymentMean);

  return {
    isLoading,
    paymentMode,
    cardType,
    maskedCardNumber,
    validityMonth,
    validityYear,
    maskedCardNumberString,
    isInvoice,
    isCreditCard,
    noPaymentMethod,
    canDeleteCreditCard,
    refetch,
  };
};
