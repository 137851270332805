import { useTranslation } from "react-i18next";

import { ArrowRight } from "@/assets/icons/icons";
import { DeviceTile } from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";

export const TravelSimTile = () => {
  const { t } = useTranslation("portal");

  return (
    <a
      href={t("layout.tiles.travel-sim.link")}
      target="_blank"
      rel="noreferrer"
    >
      <DeviceTile dataTestid="travel-sim-tile">
        <div className="m-auto">
          <p className="text-base text-secondary-100 leading-normal text-left">
            {t("layout.tiles.travel-sim.heading")}
          </p>
          <h2 className="text-primary-100 mt-6">
            {t("layout.tiles.travel-sim.text")}
          </h2>
        </div>
        <div className="button-wrapper">
          <button className="button">
            <span className="manage">
              {t("layout.tiles.travel-sim.activateNow")}
            </span>
            <ArrowRight />
          </button>
        </div>
      </DeviceTile>
    </a>
  );
};
