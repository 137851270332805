import "./TariffCheckout.scss";

import { captureException, captureMessage, withScope } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FCC } from "types";

import { AppearTransition } from "@/components/Interface/AppearTransition/AppearTransition";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { DueRecurringCard } from "@/components/Layout/DueRecurringCard/DueRecurringCard";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { PaymentMeanSection } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/PaymentMeanSection/PaymentMeanSection";
import { getHeader } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/roamingUtils";
import { TariffDueNow } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffCheckout/TariffDueNow/TariffDueNow";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { LightboxWrapper } from "@/components/Portal/UserAccount/PaymentMethods/LightboxWrapper/LightboxWrapper";
import { useGetCampaignEndDate } from "@/hooks/useGetCampaignEndDate";
import { useHandleCheckoutError } from "@/hooks/useHandleCheckoutError";
import { useHandleError } from "@/hooks/useHandleError";
import { transformPaymentMean } from "@/hooks/usePaymentMode";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { FullScreenLoadingContext } from "@/provider/FullScreenLoadingProvider/FullScreenLoadingProvider";
import {
  useAddOptionToContract,
  useCreateTransaction,
  useGetProduct,
  useGetSim,
  useOrderRoamingPackage,
  usePayTransaction,
} from "@/services/api";
import {
  Basket,
  BasketSubscriberOrderType,
  CreateTransactionDatatransOrderType,
  DatatransPaymentResponseStatus,
} from "@/services/model";
import {
  portalTariffProductIDAtom,
  tariffCheckoutPaymentPersistenceAtom,
} from "@/utils/atoms";
import {
  getFlatNameFromLocalFlatSpeed,
  getIconForDevice,
  getTariffType,
  hasDifferentSuccessor,
  TariffTypes,
} from "@/utils/deviceUtils";
import { env } from "@/utils/environmentHelpers";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

type FormProps = {
  paymentProvider: string;
};

export const getVoiceOptionEntryFromRateBasket = (
  rateBasket: Basket | undefined | null,
) => rateBasket?.entries?.[0]?.options?.[0];

export const TariffCheckout: FCC = () => {
  const { watch, control } = useForm<FormProps>();
  const [isSubmittingCheckout, setIsSubmittingCheckout] = useState(false);
  const [paymentStatus] = useQueryParam("payment");
  const [simId] = useSimIdFromQueryParam();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleError = useHandleError();
  const { setFullScreenLoading } = useContext(FullScreenLoadingContext);
  const [selectedRateId, setPortalTariffProductID] = useAtom(
    portalTariffProductIDAtom,
  );
  const [tariffPaymentPersistence, setTariffPaymentPersistence] = useAtom(
    tariffCheckoutPaymentPersistenceAtom,
  );

  const { data: simDetail, isSuccess: hasFetchedSim } = useGetSim(
    {
      simId: parseInt(simId),
    },
    {
      query: {
        enabled: !isNaN(parseInt(simId)),
        placeholderData: tariffPaymentPersistence?.simDetail,
        onSuccess: (simDetail) =>
          setTariffPaymentPersistence((prev) => ({ ...prev, simDetail })),
      },
    },
  );

  // /sim/subscription/{simId}/orderPackages
  const { data: subscriberOrderResponse, isSuccess: hasFetchedOrder } =
    useOrderRoamingPackage(
      Number(simId),
      {
        // @ts-ignore - this is covered by checking the condition in the "enabled" entry below.
        optionId: selectedRateId,
      },
      {
        query: {
          refetchOnWindowFocus: false,
          enabled: selectedRateId !== undefined,
          onSuccess: (subscriberOrderResponse) =>
            setTariffPaymentPersistence((prev) => ({
              ...prev,
              subscriberOrderResponse,
            })),
          onError: (error) => {
            handleError(error);
            navigate(-1);
          },
          placeholderData: tariffPaymentPersistence?.subscriberOrderResponse,
        },
      },
    );
  const rateBasket = subscriberOrderResponse?.basket;

  const subscriberOrderType = rateBasket?.subscriberOrderType;
  const discountAmount = rateBasket?.total?.oneTimeCampaign?.amount;
  const credit = Math.abs(rateBasket?.balance || 0);
  const totalOnceAmount =
    (rateBasket?.total?.oneTime?.amount || 0) - (discountAmount || 0) - credit;
  const subtotalOnceAmount =
    rateBasket?.total?.oneTime?.undiscountedAmount || 0;
  const totalRecurringAmount = rateBasket?.total?.recurring?.amount || 0;
  const creditTimeRemaining = rateBasket?.total?.oneTime?.amountDiscounts;

  const sumIsZero = totalOnceAmount === 0;
  const { isInvoice, isCreditCard, maskedCardNumberString, cardType } =
    transformPaymentMean(subscriberOrderResponse?.paymentMean);

  // If the user is downgrading, we should hide the payment selection as
  // there is no need to pay for the downgrade. The user will pay for the
  // downgrade with the next renewal. A price difference will be reimbursed.
  const shouldHidePaymentSelection =
    subscriberOrderType === BasketSubscriberOrderType.DOWNGRADE &&
    (isInvoice || isCreditCard);

  // The basket contains one Product, which might include an Option.
  // This Option is the additional voice option added to the Product.
  const { data: dataProduct, isSuccess: hasFetchedProduct } = useGetProduct(
    // @ts-ignore - This query gets only enabled if selectedRateId is a number
    selectedRateId,
    {
      expanded: true,
      client: Number(env.clientNumericId ?? 1),
    },
    {
      query: {
        enabled:
          selectedRateId !== undefined && !isNaN(Number(env.clientNumericId)),
        onSuccess: (product) =>
          setTariffPaymentPersistence((prev) => ({ ...prev, product })),
        placeholderData: tariffPaymentPersistence?.product,
      },
    },
  );

  const tariffType = getTariffType(dataProduct?.id);

  const handleSuccess = () => {
    setFullScreenLoading(false);
    setPortalTariffProductID();
    setTariffPaymentPersistence(undefined);

    // Refetch queries that become stale by this modification
    Promise.all([
      queryClient.invalidateQueries(["/sim/sim"]),
      queryClient.invalidateQueries(["/sim/sims"]),
    ]).then(() => {
      setIsSubmittingCheckout(false);
      navigate(`../display?simId=${simDetail?.simId}`, { replace: true });
    });
  };
  const isSuccess = paymentStatus === "success";
  useEffect(() => {
    if (isSuccess && rateBasket?.id && !isSubmittingCheckout) {
      setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
      handleSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, handleSuccess]);

  // This creates the transaction for saving payment details
  const {
    mutate: createTransaction,
    isLoading: isCreatingTransaction,
    data: datatransRequestDocument,
  } = useCreateTransaction();

  // Both usePayTransaction and useAddOptionToContract share the same behavior onSuccess and onError
  const handleTransactionError = (err: Error, errMessage?: string) => {
    setFullScreenLoading(false);
    setIsSubmittingCheckout(false);
    handleError(err, errMessage);
    // If it fails we report the error in sentry
    withScope(function (scope) {
      scope.setFingerprint(["/contract/serviceId/options"]);
      scope.setTag("section", "portal");
      scope.setTag("domain", "TariffCheckout");
      scope.setTag("endpoint", "addOptionToContract");
      scope.setExtra("simId", simId);
      scope.setExtra("basket", rateBasket);
      scope.setContext("paymentContext", {
        isInvoice,
        isCreditCard,
        maskedCardNumberString,
        cardType,
      });
      captureException(err);
    });
  };

  // This is used to pay transactions with a saved credit card
  const { mutate: payTransaction } = usePayTransaction({
    mutation: {
      onSuccess: (data) => {
        if (data.status === DatatransPaymentResponseStatus.OK) {
          handleSuccess();
        } else {
          handleTransactionError(
            new Error("Payment failure"),
            `${t("Error.payment.fail")} ${t("Error.payment.pleaseCheckData")}`,
          );
        }
      },
      onError: (err) => handleTransactionError(err),
    },
  });

  // This is used to add the option to the contract after the payment
  // with a saved credit card or with invoice
  const {
    mutate: addOptionToContract,
    isLoading: isAddingOptionLoading,
    isSuccess: isAddingOptionDone,
  } = useAddOptionToContract({
    mutation: {
      onSuccess: () => handleSuccess(),
      onError: (err) => handleTransactionError(err),
    },
  });

  const billingPeriod = t(
    "Onboarding.sections.checkout.summary.billingPeriod",
    {
      days: subscriberOrderResponse?.minContractDuration || 30,
    },
  );
  const isVoiceOption = dataProduct?.displayValue?.includes("Voice Option");
  const hasWatchSim = !!simDetail?.watchSim;

  // In case of an upgrade, the text should be different and the number of days should be based on contract duration.
  const isUpgrade = subscriberOrderType === BasketSubscriberOrderType.UPGRADE;

  const remainingDays = isUpgrade
    ? dataProduct?.minContractDur
    : simDetail?.remainingDays;

  const watchedPaymentProvider = watch("paymentProvider");

  useHandleCheckoutError(sumIsZero);

  const handleSubmit = () => {
    const basketId = rateBasket?.id;
    if (basketId === undefined) {
      captureMessage("Wanted to submit undefined basket id!");
      handleError("Wanted to submit undefined basket id!");

      return;
    }
    if (totalOnceAmount === undefined) {
      captureMessage("Wanted to charge undefined oneTime amount");
      handleError("Wanted to charge undefined oneTime amount");

      return;
    }
    if (!isSubmittingCheckout) {
      setFullScreenLoading(true, t("Onboarding.sections.checkout.loading"));
      if (isInvoice || isCreditCard || (sumIsZero && !watchedPaymentProvider)) {
        setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
        // If we have a saved payment mean and want to pay something directly
        if (isCreditCard && totalOnceAmount > 0) {
          // Trigger transaction for this basket
          payTransaction({
            params: {
              simId: parseInt(simId),
              basketId,
              datatransOrderType:
                CreateTransactionDatatransOrderType.AddOptionToContract,
            },
          });
        } else {
          addOptionToContract({
            serviceId: Number(simId),
            data: {
              header: getHeader(),
              basket: rateBasket,
              basketId,
            },
          });
        }
      } else {
        // Else we create a transaction, which will in turn open the Lightbox, allow the user to enter payment information
        // and call addOptionToContract backend-side if the payment was successful.
        createTransaction({
          params: {
            simId: parseInt(simId),
            basketId,
            datatransOrderType:
              CreateTransactionDatatransOrderType.AddOptionToContract,
          },
        });
      }
    }
  };

  // The voice option in the basket
  const voiceOptionEntry = getVoiceOptionEntryFromRateBasket(rateBasket);

  // The one time charge, might be reduced due to credit / runtime accounting
  const voiceOptionOneTimeCharge = useMemo(
    () =>
      voiceOptionEntry?.oneTimeCharges?.filter(
        (value) => value.chargeType?.id === 400,
      ),
    [voiceOptionEntry?.oneTimeCharges],
  );
  // The one time data product charge, might be discounted aswell
  const dataProductOneTimeCharge = useMemo(
    () =>
      voiceOptionEntry?.oneTimeCharges?.filter(
        (value) => value.chargeType?.id === 4,
      ),
    [voiceOptionEntry?.oneTimeCharges],
  );

  // The normal charge for the voice option, basket and discount independent
  const voiceOptionCharge = useMemo(
    () =>
      dataProduct?.charges?.filter(
        (charge) => charge.id === voiceOptionOneTimeCharge?.[0]?.charge?.id,
      ),
    [dataProduct, voiceOptionOneTimeCharge],
  );

  // The normal data product charge, might be discounted aswell
  const dataProductCharge = useMemo(
    () =>
      dataProduct?.charges?.filter(
        (charge) => charge.id === dataProductOneTimeCharge?.[0]?.charge?.id,
      ),
    [dataProduct?.charges, dataProductOneTimeCharge],
  );

  const { campaignEndDate } = useGetCampaignEndDate(rateBasket);
  const icon = getIconForDevice(simDetail?.device?.description);
  const currency = getCurrencyFromLookupValue(
    rateBasket?.currency || { id: 1 },
  );

  const watchSimPrice = useMemo(
    () =>
      voiceOptionEntry?.options?.[0]?.oneTimeCharges?.find(
        (charge) => charge.chargeType?.id === 4,
      )?.amount,
    [voiceOptionEntry?.options],
  );

  const subtotalRecurringAmount =
    (dataProductCharge?.[0]?.amount ?? 0) +
    (voiceOptionCharge?.[0]?.amount || 0) +
    (watchSimPrice || 0);
  const discountAmountRecurring =
    subtotalRecurringAmount - (rateBasket?.total?.recurring?.amount || 0);

  const disableSuccessButton =
    isCreatingTransaction ||
    isAddingOptionLoading ||
    isAddingOptionDone ||
    (selectedRateId === undefined &&
      tariffPaymentPersistence?.product?.id === undefined) ||
    (!sumIsZero && !watchedPaymentProvider && !(isInvoice || isCreditCard));

  const hasFetchedEverything =
    hasFetchedSim && hasFetchedOrder && hasFetchedProduct;

  return (
    <div id="checkout-tariff" data-testid={subscriberOrderType}>
      <TariffHeader
        title={t("cockpit.managementTile.tariff.checkout.changeTariff")}
        className="mb-6"
      />
      {!hasFetchedEverything ||
      isCreatingTransaction ||
      isAddingOptionLoading ? (
        <>
          <LoadingSpinner />
          <p>{t("portal:loading")}</p>
        </>
      ) : (
        <AppearTransition>
          <div id="checkout-layout">
            {!selectedRateId && !tariffPaymentPersistence?.product?.id ? (
              <p data-testid="no-product-id">
                {t("cockpit.managementTile.tariff.checkout.noProductId")}
              </p>
            ) : (
              <>
                <div id="summary">
                  <div className="mb-16">
                    <p className="mb-6">
                      <Trans
                        t={t}
                        values={{ count: simDetail?.remainingDays ?? 0 }}
                      >
                        {subscriberOrderType ===
                        BasketSubscriberOrderType.DOWNGRADE
                          ? "cockpit.managementTile.tariff.checkout.tariffActivation.asSuccessor"
                          : "cockpit.managementTile.tariff.checkout.tariffActivation.now"}
                      </Trans>
                    </p>
                    <p className="font-semibold text-primary-100 mb-6">
                      {dataProduct?.baseDisplayValue}
                      {isVoiceOption &&
                        " | " +
                          t(
                            "cockpit.managementTile.details.sections.autoRenewal.summary.voiceOption",
                          )}
                      {hasWatchSim &&
                        " | " +
                          t(
                            "portal:cockpit.managementTile.details.sections.autoRenewal.summary.watchSimOption",
                          )}
                    </p>
                    {!isVoiceOption &&
                      hasWatchSim &&
                      tariffType === TariffTypes.dataTariff && (
                        <HighlightPanel
                          headline={t(
                            "cockpit.managementTile.tariff.checkout.watchSimWarning",
                          )}
                          className="mt-10"
                        >
                          <Trans
                            components={[
                              <span key="0" className="font-semibold" />,
                            ]}
                          >
                            cockpit.managementTile.tariff.checkout.watchSimDeactivationHint
                          </Trans>
                        </HighlightPanel>
                      )}
                  </div>
                  <h3 className="text-xl font-gellix leading-normal font-semibold text-black mb-4">
                    {t("cockpit.managementTile.tariff.autoRenew")}
                  </h3>
                  <p data-testid="autorenew-hint">
                    {t(
                      simDetail?.autoRenew
                        ? "cockpit.managementTile.tariff.checkout.automaticRenewalHint.RemainsActivated"
                        : "cockpit.managementTile.tariff.checkout.automaticRenewalHint.GetsActivated",
                    )}
                  </p>
                </div>
                {/* If the successor is not the same as the current active rate, we display a warning here */}
                {hasDifferentSuccessor(simDetail) && (
                  <p data-testid="eliminiating-followup-info" className="mb-8">
                    <Trans
                      i18nKey={
                        "cockpit.managementTile.tariff.checkout.eliminatingFollowup"
                      }
                      values={{
                        description: getFlatNameFromLocalFlatSpeed(
                          simDetail?.successorForRenew?.description,
                        ),
                      }}
                    />
                  </p>
                )}

                <div id="due-cards">
                  <div id="due-now">
                    {rateBasket?.currency !== undefined &&
                      totalOnceAmount !== undefined && (
                        <TariffDueNow
                          icon={icon}
                          totalOnceAmount={totalOnceAmount}
                          subtotalOnceAmount={subtotalOnceAmount}
                          discountOnceAmount={discountAmount}
                          discountEndDate={campaignEndDate}
                          credit={credit}
                          creditTimeRemaining={creditTimeRemaining}
                          currency={currency}
                          billingPeriod={billingPeriod}
                          dataRateName={dataProduct?.baseDisplayValue}
                          dataRatePrice={dataProductOneTimeCharge?.[0]?.amount}
                          voiceOptionPrice={voiceOptionCharge?.[0]?.amount}
                          watchSimName={t("common.device.attributes.watchSim")}
                          watchSimPrice={watchSimPrice}
                          isDowngrade={
                            subscriberOrderType ===
                            BasketSubscriberOrderType.DOWNGRADE
                          }
                        />
                      )}
                  </div>
                  <div id="due-reoccuring">
                    {simDetail &&
                      rateBasket &&
                      rateBasket.currency !== undefined &&
                      rateBasket?.total?.recurring && (
                        <DueRecurringCard
                          currency={currency}
                          isUpgrade={isUpgrade}
                          remainingDays={remainingDays}
                          totalReoccurring={totalRecurringAmount}
                          subtotalRecurring={subtotalRecurringAmount}
                          discountRecurring={discountAmountRecurring}
                          discountEndDate={campaignEndDate}
                          flatName={dataProduct?.baseDisplayValue}
                          flatPrice={dataProductCharge?.[0]?.amount ?? 0}
                          flatOption={t("common.device.attributes.voiceOption")}
                          flatOptionPrice={voiceOptionCharge?.[0]?.amount}
                          billingPeriod={dataProduct?.minContractDur ?? 30}
                          watchSimName={t("common.device.attributes.watchSim")}
                          watchSimPrice={watchSimPrice}
                        />
                      )}
                  </div>
                </div>

                {!shouldHidePaymentSelection && rateBasket !== undefined && (
                  <section id="payment-section">
                    <PaymentMeanSection
                      isInvoice={isInvoice}
                      isCreditCard={isCreditCard}
                      cardType={cardType}
                      maskedCardNumberString={maskedCardNumberString}
                      control={control}
                      enableShowCurrent={true}
                      sumIsZero={sumIsZero}
                      recommended={
                        subscriberOrderType ===
                        BasketSubscriberOrderType.DOWNGRADE
                      }
                    />
                    <LightboxWrapper
                      paymentProvider={watchedPaymentProvider}
                      transactionId={datatransRequestDocument?.refNo}
                      sign={datatransRequestDocument?.sign}
                      enabled={datatransRequestDocument !== undefined}
                    />
                    {(isInvoice || isCreditCard) && (
                      <Trans t={t} components={[<span key={1} />]}>
                        cockpit.managementTile.roaming.checkout.changePaymentNote
                      </Trans>
                    )}
                  </section>
                )}
              </>
            )}
          </div>
        </AppearTransition>
      )}
      <ConfirmationButtonGroup
        successText={t("common.buttons.submit")}
        successAction={() => handleSubmit()}
        cancelText={t("common.buttons.back")}
        cancelAction={() => {
          setTariffPaymentPersistence(undefined);
          navigate(`../change?simId=${simDetail?.simId}`, { replace: true });
        }}
        disableSuccessButton={disableSuccessButton}
      />
    </div>
  );
};
