import axios, { AxiosError } from "axios";
import { useSetAtom } from "jotai";
import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AuthAndPersonalDetailsFormFields,
  CustomerTypeAndSegment,
} from "@/components/Onboarding/Steps/PersonalDetails/personalDetails.types.ts";
import { handleUpdateCustomerFunction } from "@/components/Onboarding/Steps/PersonalDetails/useHandleUpdateCustomer.ts";
import { DataContext } from "@/provider/DataContextProvider.tsx";
import { createCustomer, getCustomer } from "@/services/api.ts";
import { BasicCustomerData } from "@/services/model";
import { API_ROOT, USER_AUTHORIZATION } from "@/utils/apiUrls.ts";
import {
  accessTokenAtom,
  esimJourneyBasketIdAtom,
  refreshTokenAtom,
} from "@/utils/atoms.ts";
import {
  hasValidPersonalDetails,
  transformPersonalDetailsFormToBasicCustomerData,
} from "@/utils/customerUtils.ts";
import { log } from "@/utils/log";

/**
 * Handles the submit of the personal details form for an anonymous user.
 * We either have to login or create an account, depending on entered form values.
 *
 * @param formValues Email, password and so on. See typings for details.
 * @param isExistingAccount Whether the user already has an account or not.
 * @param formMethods Form methods from react-hook-form.
 * @param persistCustomerAndGoToCheckout Function to persist the customer and redirect to the checkout.
 * @param handleUpdateCustomer Function to update the customer.
 */
export const useHandleAnonymousUserSubmit = (
  isExistingAccount: boolean | null,
  formMethods: UseFormReturn<AuthAndPersonalDetailsFormFields>,
  formValues: AuthAndPersonalDetailsFormFields,
  persistCustomerAndGoToCheckout: (
    customerData: BasicCustomerData,
    target: string,
  ) => void,
  handleUpdateCustomer: handleUpdateCustomerFunction,
) => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setEsimJourneyBasketId = useSetAtom(esimJourneyBasketIdAtom);
  const { dataContext } = useContext(DataContext);
  const { t } = useTranslation();

  async function loginAndPersistBasket() {
    const fetchPostBody = new URLSearchParams({
      grant_type: "password",
      username: `${formValues?.email}`,
      password: `${formValues?.password}`,
    });

    return axios
      .post<{
        access_token: string;
        refresh_token: string;
      }>(`${API_ROOT}/api-auth-server/oauth/token`, fetchPostBody, {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Accept-Language": "en,de;q=0.5",
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: USER_AUTHORIZATION,
          "Sec-Fetch-Dest": "empty",
          "Sec-Fetch-Mode": "cors",
          "Sec-Fetch-Site": "same-origin",
          "Sec-GPC": "1",
        },
      })
      .then((response) => {
        if (!response.data.access_token || !response.data.refresh_token) {
          throw new Error("No tokens received after onboarding login");
        }
        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        if (!dataContext.basket?.id) {
          throw new Error("No basket id received after onboarding login");
        }
        setEsimJourneyBasketId(dataContext.basket.id);
      })
      .catch((err) => {
        log("Error when logging in an anonymous user", err);
        throw err;
      });
  }

  async function handleAnonymousUser(
    customerTypeAndCustomerSegment: CustomerTypeAndSegment,
  ) {
    // If we don't have an existing account and no form errors, we should create an account
    const shouldCreateAccount =
      !isExistingAccount &&
      Object.keys(formMethods.formState.errors).length === 0;

    if (shouldCreateAccount) {
      const postData = {
        ...transformPersonalDetailsFormToBasicCustomerData(formValues, {}),
        ...customerTypeAndCustomerSegment,
      };
      // Removing the phone data cause the backend can't add it on the initial create user
      delete postData.phone;
      await createCustomer({
        header: { source: 1, client: 1 },
        basicCustomerData: postData,
        authentification: {
          login: formValues?.email,
          password: formValues?.password,
        },
      });
      // Make the call to sign in
      await loginAndPersistBasket();
      // Here it is necessary to update the customer to add the phone number (cannot be done in create call...)
      // It is necessary to be signed in before the update of the customer.
      const freshCustomer = await getCustomer();
      // Update the customer to persist the phone number too.
      handleUpdateCustomer(freshCustomer, customerTypeAndCustomerSegment);
    } else {
      loginAndPersistBasket()
        .then(() => {
          getCustomer().then((customerData) => {
            if (hasValidPersonalDetails(customerData)) {
              // Upon login, a customer who has completed the onboarding process should be redirected
              // to the 'add device' flow within the portal. A customer without a device (unfinished onboarding)
              // should be sent to the checkout of the onboarding process.
              const redirectTarget = customerData.onboardingFinished
                ? "/portal/add-device/4/checkout"
                : "/onboarding/5";
              persistCustomerAndGoToCheckout(customerData, redirectTarget);
            }
          });
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 403) {
            // Too many login attempts, notify the user
            // that their account is now locked for a while
            formMethods.setError("password", {
              type: "backendError",
              message: t(
                "portal:user-account.personalDetails.loginData.accountLocked",
              ),
            });
          } else {
            // Set formstate to error
            formMethods.setError("password", {
              type: "backendError",
              message: t(
                "portal:user-account.personalDetails.loginData.passwordError",
              ),
            });
          }
        });
    }
  }

  return { handleAnonymousUser };
};
