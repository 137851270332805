import { getI18n } from "react-i18next";

import { deviceToIconMap } from "@/components/Onboarding/Steps/DeviceType/DeviceType";
import {
  ExtendedSimCardDetail,
  ExtendedSimCardDetailWithEsimInfo,
} from "@/hooks/useExtendedSims";
import {
  ESimInfo,
  ESimInfoStatus,
  GetSimListDevicesItem,
  RoamingInfo,
  SimActionsSimActionsItem,
  SimCardDetail,
  SimCardDetailSimStatus,
  SimCardDetailSimStatusReason,
  WatchSimWatchStatus,
} from "@/services/model";

import { mobileAbos } from "./constants";
import { Status } from "./enums";

export const allowedDeviceNameRegex = /^[^,;]*$/;

export const isActive = (device?: SimCardDetail) =>
  device?.simStatus !== SimCardDetailSimStatus.grey;

export const isInactive = (device?: SimCardDetail) =>
  device?.simStatus === SimCardDetailSimStatus.grey;

export const isNumberPortingTile = (device: SimCardDetail) =>
  "numberPorting" in device;

export const canBookWatchSim = (device?: SimCardDetail) =>
  !hasPendingService(device as ExtendedSimCardDetail) &&
  !hasWatchSim(device) &&
  device?.voiceOption;

export const hasSimAutoRenewal = (simCard: SimCardDetail) =>
  simCard.simStatus === SimCardDetailSimStatus.green;

export const hasWatchSim = (device?: SimCardDetail) =>
  device && !!device.watchSim;

export const hasWatchSimAutoRenewal = (simCard: SimCardDetail) =>
  !!simCard.successorForRenew?.description?.includes("Watch");

// Watch SIM status helpers
export const hasWatchSimPending = (simCard: SimCardDetail) =>
  simCard.watchSim?.watchStatus === WatchSimWatchStatus.PENDING;
export const hasWatchSimOrdered = (simCard: SimCardDetail) =>
  simCard.watchSim?.watchStatus === WatchSimWatchStatus.ORDERED;
export const hasWatchSimActive = (simCard: SimCardDetail) =>
  simCard.watchSim?.watchStatus === WatchSimWatchStatus.ACTIVE;

export const hasSimId = (device: ExtendedSimCardDetail) =>
  device.simId !== undefined;

export const hasEsimInfo = (
  device: ExtendedSimCardDetail,
): device is ExtendedSimCardDetailWithEsimInfo => device.esimInfo !== undefined;

export const hasFlatSpeedPackage = (device: ExtendedSimCardDetail) => {
  return !!device.localFlatSpeed?.length;
};

export const canOrderRoaming = (
  device: ExtendedSimCardDetail | SimCardDetail,
) => {
  return !!device?.simActions?.simActions?.includes(
    SimActionsSimActionsItem.canOrderRoaming,
  );
};

// Device has a simId but not remainingDays yet? -> Not ready to use.
export const isWithoutRemainingDays = (device: ExtendedSimCardDetail) =>
  device.remainingDays === undefined;

export const hasPendingService = (device: SimCardDetail) =>
  !!device.servicePending;

/**
 * This helper returns true if a customer's identity has been verified, and
 * false otherwise.
 * KYC stands for 'know your customer'.
 */
export const isKYCPendingTile = (
  device: ExtendedSimCardDetail,
  needsIdCheck: boolean | undefined,
) => needsIdCheck && !isNumberPortingTile(device);

// Checks for physical SIMs

export const isPhysicalSIM = (simDetail: SimCardDetail) =>
  simDetail.simType === "Triple SIM";

export const isPendingPhysicalSim = (device: ExtendedSimCardDetail) =>
  hasSimId(device) && isPhysicalSIM(device) && hasPendingService(device);

// Checks for eSIMs

export const isEsim = (sim: Omit<SimCardDetail, "roaming">) =>
  sim.simType === "eSIM";

// Checks if an esim should show the QR code, which is the case for some status
export const isUnscannedEsimInfo = (esimInfo: ESimInfo | undefined) =>
  esimInfo !== undefined &&
  (esimInfo?.status === ESimInfoStatus.RELEASED ||
    esimInfo?.status === ESimInfoStatus.INSTALLED ||
    esimInfo?.status === ESimInfoStatus.ERROR_INSTALL_FAILED);

export const isEsimchangeInProgress = (
  esimInfo: ESimInfo[] | undefined,
): boolean => !!esimInfo?.[0]?.esimchangeInProgress;

export const isEsimresetInProgress = (
  esimInfo: ESimInfo[] | undefined,
): boolean => !!esimInfo?.[0]?.esimresetInProgress;

export const isPendingEsim = (device: ExtendedSimCardDetail) =>
  isEsim(device) && hasPendingService(device);

export const needsQRCodeTile = (device: ExtendedSimCardDetail) =>
  hasSimId(device) && isEsim(device) && isUnscannedEsimInfo(device.esimInfo);

export const isEsimQRInProgress = (device: ExtendedSimCardDetail) => {
  return device.esimInfo?.esimchangeInProgress === true;
};

// Utilitiy functions

export const iccidWithSpaces = (x = "") => {
  const iccid = x.match(/^(\d{6})(\d{6})(\d{6})(\d{2})/);
  if (iccid && iccid.length >= 4) {
    return `${iccid[1]} ${iccid[2]} ${iccid[3]} ${iccid[4]}`;
  } else {
    return x;
  }
};

export const toPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/^0(.*)$/, "+41 $1");
};

export const getSpeedFromLocalFlatSpeed = (simDetail?: SimCardDetail) => {
  if (!simDetail) return "-";

  // In case we got a mobileAbo we take one of the following speed values
  if (mobileAbos.includes(`${simDetail.serviceID}`)) {
    const tariffType = getTariffType(simDetail.serviceID);

    switch (tariffType) {
      case TariffTypes.flatMobile:
      case TariffTypes.flatMobilePlus:
        return "2000 Mbits";
      case TariffTypes.flatMobileSwiss:
      default:
        return "30 Mbits";
    }
  } else {
    if (!simDetail.localFlatSpeed) return "-";

    let speedMatch = simDetail.localFlatSpeed.match(/^\w+ ([0-9.]*) |/);
    let speed = 0;
    if (!speedMatch?.[1]) {
      speedMatch = simDetail.localFlatSpeed.match(/^[0-9]+/);
      speed = Number(speedMatch?.[0]) || 0;
    } else {
      speed = Number(speedMatch && speedMatch[1]) || 0;
    }
    if (speed >= 1000) {
      return `${speed / 1000} Gbits`;
    } else {
      return `${speed} Mbits`;
    }
  }
};

export const stripFlatName = (fullName = "") =>
  fullName.replace(/ [|+] .*/, "");

export const getBaseFlatName = (device: SimCardDetail) =>
  stripFlatName(device.localFlatSpeed);

export const getFlatNameFromDevice = (
  device: SimCardDetail,
  isCockpit: boolean = true,
) => {
  if (mobileAbos.includes((device?.serviceID || 0).toString())) {
    const { t } = getI18n();
    if (device.watchSim) {
      return `${stripFlatName(getFlatNameFromLocalFlatSpeed(device.localFlatSpeed))} ${isCockpit ? "+" : "|"} ${isCockpit ? "Watch" : "Watch SIM"}`;
    }
  }

  return getFlatNameFromLocalFlatSpeed(device.localFlatSpeed);
};

export const getFlatNameFromLocalFlatSpeed = (value = "") =>
  stripFlatName(value) +
  (value.includes("Voice") ? " + Voice" : "") +
  (value.includes("Watch") ? " + Watch" : "");

export const getExtendedFlatNameFromLocalFlatSpeed = (value = "") =>
  stripFlatName(value) +
  (value.includes("Voice") ? " | Voice Option" : "") +
  (value.includes("Watch") ? " | Watch SIM" : "");

export const getTariffName = (simDetail: SimCardDetail) => {
  const { t } = getI18n();

  return isInactive(simDetail)
    ? simDetail.localFlatSpeed !== undefined
      ? getFlatNameFromDevice(simDetail)
      : t("portal:layout.tiles.inactive.noPackageActive")
    : simDetail.servicePending
      ? "-"
      : getFlatNameFromDevice(simDetail);
};

export enum TariffTypes {
  "flatMobile",
  "flatMobilePlus",
  "flatMobileSwiss",
  "dataTariff",
}

export const getTariffType = (serviceID?: number) => {
  switch (serviceID) {
    case 131601:
    case 131611:
      return TariffTypes.flatMobile;
    case 131603:
    case 131613:
      return TariffTypes.flatMobilePlus;
    case 131602:
    case 131612:
      return TariffTypes.flatMobileSwiss;
    default:
      return TariffTypes.dataTariff;
  }
};

export const sortRoamingOnHierarchy = (a: RoamingInfo, b: RoamingInfo) => {
  if (a.roamingStatus === Status.active || b.roamingStatus === Status.active) {
    if (
      a.roamingStatus === Status.active &&
      b.roamingStatus === Status.active
    ) {
      if (a.usageZone === "ZONE1" && b.usageZone === "ZONE2") return -1;
      if (a.usageZone === "ZONE2" && b.usageZone === "ZONE3") return -1;
      if (a.usageZone === "ZONE2" && b.usageZone === "ZONE1") return 1;
      if (a.usageZone === "ZONE3" && b.usageZone === "ZONE2") return 1;
    } else {
      if (a.roamingStatus === Status.active) return -1;
      if (b.roamingStatus === Status.active) return 1;
    }
  } else {
    if ((a.remainingDays || 0) < (b.remainingDays || 0)) {
      return -1;
    }
  }

  return 0;
};

type roamingTransformerType = (
  device: ExtendedSimCardDetail,
) => RoamingInfo | undefined;
export const getFirstOfSortedRoaming: roamingTransformerType = (device) =>
  device.roamingInfo?.sort(sortRoamingOnHierarchy)[0];

export const hasDifferentSuccessor = (
  simDetail: SimCardDetail | undefined,
): boolean => {
  return (
    simDetail?.successorForRenew !== undefined &&
    simDetail.successorForRenew.id !== simDetail.serviceID
  );
};

export const devices = [
  "Smartphone",
  "Tablet",
  "Laptop",
  "WLAN Home Router",
  "Mobile Hotspot",
  "GPS Tracker",
  "Wearable",
  "Kamera",
  "IoT Gerät",
  "Fahrzeug",
  "Andere",
] as const;

export type DeviceName = (typeof devices)[number];

// Map name to icon
export const getIconForDevice = (description?: string) =>
  deviceToIconMap[getDeviceDescription(description)];

export const getDeviceDescription: (device?: string) => DeviceName = (
  device,
) =>
  device !== undefined && devices.includes(device as DeviceName)
    ? (device as DeviceName)
    : "Andere";

export const getIconForDescription = (device?: string) =>
  getIconForDevice(getDeviceDescription(device));

export const hasAllowedManagement = (device: SimCardDetail) =>
  device.simStatus !== SimCardDetailSimStatus.red;

export const checkIfDeviceCanReloadEsim = (esimInfo: ESimInfo[]) =>
  esimInfo?.[0]?.esimresetAllowed;

export const checkIfDeviceCanTransferEsim = (esimInfo: ESimInfo[]) =>
  esimInfo?.[0]?.esimTransferAllowed;

export const deviceNameToValueMap: Record<DeviceName, GetSimListDevicesItem> = {
  Smartphone: GetSimListDevicesItem.Smartphone,
  Tablet: GetSimListDevicesItem.Tablet,
  Laptop: GetSimListDevicesItem.Laptop,
  "WLAN Home Router": GetSimListDevicesItem.Router,
  "Mobile Hotspot": GetSimListDevicesItem.Mobile_Hotspot,
  "GPS Tracker": GetSimListDevicesItem.GPS_Tracker,
  Wearable: GetSimListDevicesItem.Wearable,
  Kamera: GetSimListDevicesItem.Camera,
  "IoT Gerät": GetSimListDevicesItem.IoT_Device,
  Fahrzeug: GetSimListDevicesItem.Vehicle,
  Andere: GetSimListDevicesItem.Andere,
} as const;

export const deviceValueToNameMap: Record<GetSimListDevicesItem, DeviceName> = {
  [GetSimListDevicesItem.Smartphone]: "Smartphone",
  [GetSimListDevicesItem.Tablet]: "Tablet",
  [GetSimListDevicesItem.Laptop]: "Laptop",
  [GetSimListDevicesItem.Router]: "WLAN Home Router",
  [GetSimListDevicesItem.Mobile_Hotspot]: "Mobile Hotspot",
  [GetSimListDevicesItem.GPS_Tracker]: "GPS Tracker",
  [GetSimListDevicesItem.Wearable]: "Wearable",
  [GetSimListDevicesItem.Camera]: "Kamera",
  [GetSimListDevicesItem.IoT_Device]: "IoT Gerät",
  [GetSimListDevicesItem.Vehicle]: "Fahrzeug",
  [GetSimListDevicesItem.Andere]: "Andere",
} as const;

export const hasNumberPorting = (device: SimCardDetail) =>
  device.simStatusReason === SimCardDetailSimStatusReason.WF_ACTIVE_NP;

export const canBeChangedToFlatMobile = (device: SimCardDetail) =>
  !hasPendingService(device) &&
  device.simStatus !== SimCardDetailSimStatus.red &&
  !hasNumberPorting(device);
