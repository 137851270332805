import { debounce } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import BlurredQRCode from "@/assets/BlurredQrCode.svg?react";
import { useDisplayTwoFactorAuthenticationData } from "@/services/auth";

import { TwoFactorChangeAuthQRCode } from "./TwoFactorChangeAuthQRCode";
import { TwoFactorManualQrCodeField } from "./TwoFactorManualQrCodeField";

// This component displays the current QR code again
// for users in their 2FA management page.
export const TwoFactorQRCodeSection = () => {
  const { t } = useTranslation();
  const { mutate, data } = useDisplayTwoFactorAuthenticationData();
  const [showManualSetup, setShowManualSetup] = useState(false);
  const debouncedMutation = debounce(() => mutate(), 1000, {
    leading: true,
    trailing: false,
  });
  const hasRequestedQRCode = !!data?.qrCodeUri;

  return (
    <div>
      <h3>{t("portal:user-account.security.2fa.qrCode.title")}</h3>
      <div className="flex flex-col md:flex-row gap-4">
        <p>{t("portal:user-account.security.2fa.qrCode.description")}</p>
        <div className="min-w-[178px]">
          {hasRequestedQRCode ? (
            <>
              <div className="flex flex-col min-w-52">
                <TwoFactorChangeAuthQRCode qrCodeData={data?.qrCodeUri} />
                <button
                  type="button"
                  className="text-[13px] text-center text-primary-100 underline"
                  onClick={() => setShowManualSetup(!showManualSetup)}
                >
                  {t(
                    showManualSetup
                      ? "portal:authenticator.app.setup.step1.back-to-qr-code"
                      : "portal:authenticator.app.setup.step1.enter-key-manually",
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <BlurredQRCode />
              <button
                onClick={() => debouncedMutation()}
                className="text-primary-100 underline text-[13px]"
              >
                {t("portal:user-account.security.2fa.qrCode.showQrCode")}
              </button>
            </div>
          )}
        </div>
      </div>
      {hasRequestedQRCode && showManualSetup && (
        <TwoFactorManualQrCodeField manualCode={data?.manualCode} />
      )}
    </div>
  );
};
