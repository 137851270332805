import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  DataContext,
  DataContextInterface,
} from "@/provider/DataContextProvider";
import { mobileAbos } from "@/utils/constants";
import {
  getEntry,
  hasBasketEntryWithVoiceOption,
} from "@/utils/dataContextHelpers";
import { getIconForDevice } from "@/utils/deviceUtils";

import { useGetCampaignEndDate } from "./useGetCampaignEndDate";

export const useGetFlatPricesAndName: (isRecurring?: boolean) => {
  onceTotal: number;
  onceSubtotal: number;
  recurringTotal: number;
  recurringSubtotal: number;
  flatName: string;
  flatPrice: number;
  voicePrice: number;
  flatPriceRecurring: number;
  voicePriceRecurring: number;
  discountAmount: number;
  discountEndDate?: Date;
} = (isRecurring) => {
  const { dataContext } = useContext(DataContext);
  const { t } = useTranslation();

  const entry = getEntry(dataContext);
  const option = entry && entry.options && entry.options[0];
  const selectedOption = dataContext.selectedOption;
  const hasVoiceOption = hasBasketEntryWithVoiceOption(dataContext);

  const flatName = selectedOption?.baseDisplayValue || "Flat Name";
  let flatPrice: number;
  let flatPriceRecurring: number;
  let voicePrice = 0;

  // TODO: replace 'flatPriceRecurring' value with option?.recurringCharges once it's fixed by backend

  // If the user picked a voice option, oneTimeCharge[0] is the voice price
  if (hasVoiceOption) {
    voicePrice = option?.oneTimeCharges?.[0]?.amount || 0;
    flatPrice = option?.oneTimeCharges?.[1]?.amount || 0;
    flatPriceRecurring = option?.oneTimeCharges?.[1]?.unitAmount || 0;
  }
  // Otherwise oneTimeCharge[0] is the flat price
  else {
    flatPrice = option?.oneTimeCharges?.[0]?.amount || 0;
    flatPriceRecurring = option?.oneTimeCharges?.[0]?.unitAmount || 0;
  }

  const voicePriceRecurring = voicePrice;

  if (selectedOption?.precharged) flatPrice = 0;

  const recurringTotal = dataContext.basket?.total?.recurring?.amount ?? 0;
  const recurringSubtotal = dataContext.basket?.total?.oneTime?.amount || 0;
  const onceTotal =
    (dataContext.basket?.total?.oneTime?.amount || 0) -
    (dataContext.basket?.total?.oneTimeCampaign?.amount || 0);
  const onceSubtotal = dataContext.basket?.total?.oneTime?.amount || 0;
  const discountAmount =
    (isRecurring
      ? (dataContext?.basket?.total?.oneTime?.amount || 0) -
        (dataContext.basket?.total?.recurring?.amount || 0)
      : dataContext.basket?.total?.oneTimeCampaign?.amount) || 0;
  const { campaignEndDate } = useGetCampaignEndDate(dataContext.basket);

  return {
    onceTotal,
    onceSubtotal,
    recurringTotal,
    recurringSubtotal,
    flatName,
    flatPrice,
    voicePrice,
    flatPriceRecurring,
    voicePriceRecurring,
    discountAmount,
    campaignEndDate,
  };
};

export const useGetIconForDevice = () => {
  const { dataContext } = useContext(DataContext);
  const entry = getEntry(dataContext);
  const device = entry && entry.device;

  return getIconForDevice(device?.description);
};

export const useHasVoiceOption = () => {
  const { dataContext } = useContext(DataContext);

  return hasBasketEntryWithVoiceOption(dataContext);
};

export const useBillingPeriod = () => {
  const { dataContext } = useContext(DataContext);

  return dataContext.selectedOption?.minContractDur || 30;
};

export const hasMobileAbo = (dataContext: DataContextInterface) => {
  const entry = getEntry(dataContext);
  const option = entry && entry.options && entry.options[0];

  return mobileAbos.includes(`${option?.product?.id}`);
};

export const useIsMobileAbo = () => {
  const { dataContext } = useContext(DataContext);

  return hasMobileAbo(dataContext);
};
