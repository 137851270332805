import { RadioGroup } from "@headlessui/react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useIsTariffChange } from "@/hooks/useIsTariffChange";
import { DataContext } from "@/provider/DataContextProvider";
import { pushProductImpression } from "@/utils/analytics/onboardingAnalytics";
import {
  TransformedProduct,
  useGetPrechargedOptionForBasket,
} from "@/utils/tariffUtils";

import { MobileAboCard } from "./MobileAboCard";

export const MobileAboForm = ({
  value,
  setValue,
  mobileAbos,
  showCustomRateTab,
  isCustomRateTabLinkDisabled,
}: {
  value: string | undefined;
  setValue: (value: string) => void;
  mobileAbos: TransformedProduct[];
  showCustomRateTab: () => void;
  isCustomRateTabLinkDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const prechargedProduct = usePrechargedProductIfBasketExists();

  useEffect(() => {
    pushProductImpression(mobileAbos, "flat-mobile-selection");
    // We want to push the product impression only once, so we don't need to run this effect again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col mt-8">
      <RadioGroup value={value ?? ""} onChange={setValue}>
        <div className="flex flex-wrap gap-6 justify-center -mx-10">
          {mobileAbos.map((product) => (
            <MobileAboCard
              disabled={
                prechargedProduct !== undefined &&
                prechargedProduct.id !== product.id
              }
              value={value}
              product={product}
              key={product.id}
            />
          ))}
        </div>
      </RadioGroup>
      {isCustomRateTabLinkDisabled || (
        <div className="w-full mt-8 text-center">
          <button
            className="text-primary-100 text-md underline"
            onClick={showCustomRateTab}
          >
            {t("portal:mobileAbo.toDataOnlyTariffs")}
          </button>
        </div>
      )}
    </div>
  );
};

const useDataContext = () => {
  const { dataContext } = useContext(DataContext);

  return dataContext;
};

const usePrechargedProductIfBasketExists = () => {
  const isTariffChange = useIsTariffChange();
  const { basket } = useDataContext();

  return useGetPrechargedOptionForBasket(isTariffChange ? undefined : basket);
};
