/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type GetSimListSimTypeItem =
  (typeof GetSimListSimTypeItem)[keyof typeof GetSimListSimTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSimListSimTypeItem = {
  TRIPLESIM: "TRIPLESIM",
  ESIM: "ESIM",
} as const;
