import "@/components/Layout/Table/TableRow/TableRow.scss";
import "./DashboardListRow.scss";

import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArrowRight, ExpiresFlag } from "@/assets/icons/icons";
import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { AdditionalColumnMap } from "@/components/Portal/Cockpit/Filter/Filter";
import { ExtendedSimCardDetail } from "@/hooks/useExtendedSims";
import { useNeedsIDCheck } from "@/hooks/useNeedsIDCheck";
import {
  DeviceName,
  deviceNameToValueMap,
  getFirstOfSortedRoaming,
  getFlatNameFromDevice,
  getFlatNameFromLocalFlatSpeed,
  getIconForDevice,
  hasAllowedManagement,
  isNumberPortingTile,
} from "@/utils/deviceUtils";
import { Status } from "@/utils/enums";

import { DeviceStatusIndicator } from "./DeviceStatusIndicator/DeviceStatusIndicator";

interface Props {
  additionalColumns?: AdditionalColumnMap;
  device: ExtendedSimCardDetail;
}

export const DashboardListRow: FC<Props> = ({ additionalColumns, device }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const needsIdCheck = useNeedsIDCheck();

  const navigateToDevice = () => {
    navigate(`../manage?simId=${device.simId}`);
  };

  const {
    roamingDataConsumption,
    roamingOpenRuntime,
    roamingStatus,
    telephoneNumber,
    iccid,
  } = additionalColumns || {};

  // We want only the most important of maximum three roaming infos displayed here.
  const roamingInfo = getFirstOfSortedRoaming(device);

  const isManageable = hasAllowedManagement(device) && !needsIdCheck;

  return (
    <tr
      role="link"
      aria-label={`Navigate to ${device.alias}`}
      {...(isManageable ? { onClick: navigateToDevice } : {})}
      className={clsx(
        "row-layout font-small",
        isManageable && "cursor-pointer",
      )}
    >
      <td data-testid="td-status" className="pl-6 w-1 relative">
        {isNumberPortingTile(device) && (
          <>
            <div className="number-porting-flag">
              <ExpiresFlag className="" />
            </div>
            <div className="number-porting-flag-text">
              {t("cockpit.deviceList.row.numberPorting")}
            </div>
          </>
        )}
        <Tooltip
          content={t(`cockpit.deviceList.row.${device.simStatus ?? "green"}`)}
        >
          <DeviceStatusIndicator
            color={device.simStatus}
            className="ml-2 align-middle"
          />
        </Tooltip>
      </td>
      <td data-testid="td-type" className="text-center icon-cell">
        <RowType device={device} />
      </td>
      <td data-testid="td-device-name">{device.alias}</td>
      <td data-testid="td-device-simType">{device.simType}</td>
      <td data-testid="td-device-flat">{getFlatNameFromDevice(device)}</td>
      <td data-testid="td-voice-option">
        {device.voiceOption ? t("cockpit.deviceList.row.active") : "-"}
      </td>
      <td data-testid="td-runtime">
        {device.remainingDays
          ? `${device.remainingDays}/${device.minContractDuration} ${t(
              "Common.days",
              {
                ns: "translation",
              },
            )}`
          : "-"}
      </td>
      <td data-testid="td-followups">
        {getFlatNameFromLocalFlatSpeed(
          device.successorForRenew?.description?.split("/")[0] || "-",
        )}
      </td>
      {/*Optional Columns*/}
      {roamingDataConsumption && (
        <td data-testid="td-roaming-consumption">
          {roamingInfo?.remainingVolume && roamingInfo?.initialVolume
            ? `${
                roamingInfo.initialVolume - roamingInfo.remainingVolume
              } Megabyte`
            : "-"}
        </td>
      )}
      {roamingOpenRuntime && (
        <td data-testid="td-roaming-runtime">
          {roamingInfo?.remainingDays
            ? `${roamingInfo?.remainingDays}/${roamingInfo?.initialDays} ${t(
                "Common.days",
                {
                  ns: "translation",
                },
              )}`
            : "-"}
        </td>
      )}
      {roamingStatus && (
        <td data-testid="td-roaming-status">
          {roamingInfo?.roamingStatus === Status.active
            ? t("cockpit.deviceList.row.active")
            : "-"}
        </td>
      )}
      {telephoneNumber && <td data-testid="td-number">{device.msisdn}</td>}
      {iccid && <td data-testid="td-ICCID">{device.iccid}</td>}
      <td className="icon-cell">
        {isManageable ? <ArrowRight /> : <ArrowRight className="opacity-25" />}
      </td>
    </tr>
  );
};

const RowType = ({ device }: { device: ExtendedSimCardDetail }) => {
  const { t } = useTranslation();
  if (!device.device?.description) return <span>-</span>;

  const deviceIcon = getIconForDevice(device.device.description);

  return (
    <Tooltip
      content={
        <span className="whitespace-nowrap">
          {t(
            `Common.devices.${
              deviceNameToValueMap[device.device.description as DeviceName]
            }`,
          )}
        </span>
      }
    >
      {deviceIcon}
    </Tooltip>
  );
};
