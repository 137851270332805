import "@/components/Layout/Claim/Claim.scss";

import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const Claim: FC = () => {
  const { i18n } = useTranslation();

  return (
    <div className={clsx("digitalrepublic-claim", i18n?.resolvedLanguage)} />
  );
};
