import "./Filter.scss";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMatch, useSearchParams } from "react-router-dom";

import { Close } from "@/assets/icons/icons";
import SearchIcon from "@/assets/icons/SearchIcon.svg?react";
import SettingsIcon from "@/assets/icons/Settings.svg?react";
import { Button } from "@/components/Interface/Button/Button";
import {
  GetSimListDevicesItem,
  GetSimListSimAutoRenew,
  GetSimListSimStatusItem,
  GetSimListSimTypeItem,
  GetSimListSimVoiceOption,
} from "@/services/model";

import { ColumnFilter } from "./ColumnFilter/ColumnFilter";
import { DeviceTypeFilter } from "./DeviceTypeFilter/DeviceTypeFilter";
import { SearchBar } from "./SearchBar/SearchBar";
import { Settings } from "./Settings/Settings";
import { SimTypeFilter } from "./SimTypeFilter/SimTypeFilter";
import { StatusChips } from "./StatusChips/StatusChips";

export const getDeviceFilterSearchFromFormState = (
  formState: FilterDialogForm,
  searchParams: URLSearchParams = new URLSearchParams(),
) => {
  const updatedSearchParams = new URLSearchParams(searchParams.toString());
  if (formState.simStatus && formState.simStatus.length > 0) {
    updatedSearchParams.set("simStatus", formState.simStatus.join(","));
  } else {
    updatedSearchParams.delete("simStatus");
  }
  if (formState.simAutoRenew) {
    if (formState.simAutoRenew === "ALL")
      updatedSearchParams.delete("simAutoRenew");
    else updatedSearchParams.set("simAutoRenew", formState.simAutoRenew);
  } else updatedSearchParams.delete("simAutoRenew");
  if (formState.simVoiceOption) {
    if (formState.simVoiceOption === "ALL")
      updatedSearchParams.delete("simVoiceOption");
    else updatedSearchParams.set("simVoiceOption", formState.simVoiceOption);
  } else updatedSearchParams.delete("simVoiceOption");
  if (formState.simType && formState.simType.length > 0) {
    updatedSearchParams.set("simType", formState.simType.join(","));
  } else updatedSearchParams.delete("simType");
  if (formState.devices && formState.devices.length > 0) {
    updatedSearchParams.set("device", formState.devices.join(","));
  } else updatedSearchParams.delete("device");
  if (formState.additionalColumns.length > 0) {
    updatedSearchParams.set(
      "additionalColumns",
      formState.additionalColumns.join(","),
    );
  } else updatedSearchParams.delete("additionalColumns");

  return updatedSearchParams;
};

export const additionalColumns = [
  "roamingDataConsumption",
  "roamingOpenRuntime",
  "roamingStatus",
  "telephoneNumber",
  "iccid",
] as const;
export type AdditionalColumns = typeof additionalColumns;
export type AdditionalColumnMap = {
  [key in AdditionalColumns[number]]?: boolean;
};

export type FilterDialogForm = {
  simType: GetSimListSimTypeItem[];
  simAutoRenew: GetSimListSimAutoRenew | "ALL";
  simVoiceOption: GetSimListSimVoiceOption | "ALL";
  simStatus: GetSimListSimStatusItem[];
  devices: GetSimListDevicesItem[];
  additionalColumns: AdditionalColumns;
};

export const Filter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isListView = useMatch("/portal/cockpit/list");
  const { t } = useTranslation("portal");

  const {
    watch,
    control,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm<FilterDialogForm>({
    defaultValues: {
      simAutoRenew: "ALL",
      simVoiceOption: "ALL",
      additionalColumns: [],
      simStatus: [],
      devices: [],
      simType: [],
    },
  });

  const removeURLParams = (searchParams: URLSearchParams) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.delete("simStatus");
    updatedSearchParams.delete("simAutoRenew");
    updatedSearchParams.delete("simVoiceOption");
    updatedSearchParams.delete("simType");
    updatedSearchParams.delete("device");
    updatedSearchParams.delete("additionalColumns");

    return updatedSearchParams.toString();
  };

  const closeModal = () => {
    const updatedSearchParams = getDeviceFilterSearchFromFormState(
      formState,
      searchParams,
    ).toString();
    setSearchParams(updatedSearchParams);
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const resetFilters = () => {
    const resettedSearchParams = removeURLParams(searchParams);
    reset();
    setSearchParams(resettedSearchParams);
  };

  const formState = watch();
  const hasFormFields =
    formState.simVoiceOption !== "ALL" ||
    formState.simAutoRenew !== "ALL" ||
    formState.simStatus.length > 0 ||
    formState.devices.length > 0 ||
    formState.simType.length > 0 ||
    formState.additionalColumns.length > 0;
  // To make it more user-friendly, we want to enable typing once the search-field is opened
  const focusSearchInput = () => {
    const searchField = document.getElementById(
      "filter-searchbar-input",
    ) as HTMLInputElement;
    searchField?.focus();
  };

  return (
    <>
      {hasFormFields && (
        <div className="reset-button">
          <button
            className="reset-filters font-x-small text-secondary-100 mr-3 lg:mr-6 underline"
            onClick={resetFilters}
          >
            {t("cockpit.filter.resetFilters")}
          </button>
        </div>
      )}
      <button
        onMouseUp={focusSearchInput}
        onClick={() => setSearchIsOpen((searchIsOpen) => !searchIsOpen)}
        className={clsx("search-filter", searchIsOpen && "opened")}
      >
        <SearchIcon />
      </button>
      <SearchBar
        isOpen={searchIsOpen}
        onEmptyBlur={() => setSearchIsOpen(false)}
      />
      <div className={`${isOpen ? "active" : ""}`}>
        <button
          type="button"
          onClick={openModal}
          className="search-filter ml-2 mr-6 relative"
        >
          <SettingsIcon />
          {hasFormFields && (
            <div className="rounded-xl bg-secondary-100 w-4 h-4 top-0 right-0 absolute" />
          )}
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog open={isOpen} className="filter-dialog" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(0, 20, 255, 0.25)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="filter-panel">
                  <Dialog.Title as="div" className="filter-title">
                    <h3 id="filter-title-text">{t("cockpit.filter.title")}</h3>
                    <button onClick={closeModal}>
                      <Close id="close-icon" />
                    </button>
                  </Dialog.Title>
                  <form onSubmit={closeModal} className="sections-layout">
                    <div className="status-section">
                      <h4 className="section-header">
                        {t("cockpit.filter.status")}
                      </h4>
                      <StatusChips
                        watch={watch}
                        setValue={setValue}
                        register={register}
                      />
                    </div>
                    <div className="activity-section">
                      <h4 className="section-header">
                        {t("cockpit.filter.activity")}
                      </h4>
                      <Settings
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        register={register}
                      />
                    </div>
                    <div className="drodown-wrapper">
                      <div className="application-section">
                        <h4 className="section-header">
                          {t("cockpit.filter.application")}
                        </h4>
                        <div className="flex flex-col gap-2 mb-6">
                          <SimTypeFilter control={control} />
                          <DeviceTypeFilter control={control} />
                        </div>
                      </div>
                      {isListView && (
                        <div className="additional-column-section">
                          <h4 className="section-header">
                            {t("cockpit.filter.additionalColumns")}
                          </h4>
                          <ColumnFilter control={control} />
                        </div>
                      )}
                    </div>
                  </form>
                  <div className="filter-actions">
                    {hasFormFields && (
                      <div className="reset-filters-wrapper">
                        <button
                          className="reset-filters"
                          onClick={resetFilters}
                        >
                          {t("cockpit.filter.resetFilters")}
                        </button>
                      </div>
                    )}
                    <div className="apply-wrapper">
                      <Button
                        disabled={!hasFormFields}
                        className="accent whitespace-nowrap"
                        onClick={closeModal}
                      >
                        {t("cockpit.filter.apply")}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
