import "@/components/Layout/DueNow/DueNow.scss";

import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { getCurrency } from "@/utils/translationHelpers";

export type DueNowLightInterfaceProps = {
  totalOnce: number;
  currency: string;
  icon: ReactNode;
};

export const DueNowLightInterface = ({
  totalOnce,
  currency,
  icon,
}: DueNowLightInterfaceProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "Onboarding.sections.checkout.summary",
  });

  return (
    <div className="payment-box-wrapper">
      <div className="title-icon">
        <span>
          <Trans
            t={t}
            components={[<span key="now" className="text-primary-100" />]}
            values={{ total: getCurrency(totalOnce, currency) }}
          >
            payOnceValue
          </Trans>
        </span>
        <div id="device-icon">{icon}</div>
      </div>
    </div>
  );
};
