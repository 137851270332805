import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { SimDetailsAccordion } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/SimDetailsAccordion";
import { SimPricingDetails } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/SimPricingDetails";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { SimCardDetail } from "@/services/model";
import { getBaseFlatName } from "@/utils/deviceUtils";
import { calculateDiscounts, getNextPaymentDate } from "@/utils/tariffUtils";
import { getDateString } from "@/utils/translationHelpers";

export const CostDetails = ({
  simDetail,
  className,
  isDowngrade,
}: {
  simDetail: SimCardDetail;
  className?: string;
  isDowngrade: boolean;
}) => {
  const { t } = useTranslation();

  const price = simDetail.priceBase || 0;

  const oneTimeDiscounts = calculateDiscounts(simDetail.localDiscounts);
  const recurringDiscounts = calculateDiscounts(
    simDetail.successorForRenew?.successorDiscounts,
  );

  const autoRenewText = simDetail.autoRenew
    ? simDetail?.successorForRenew?.minContractDuration
      ? t("cockpit.managementTile.tariff.payInterval", {
          days: simDetail.minContractDuration || 30,
        })
      : ""
    : t("cockpit.managementTile.tariff.once");

  return (
    <div className={className}>
      <TariffHeader
        className={clsx(isDowngrade ? "mb-2" : "mb-6")}
        title={t("cockpit.managementTile.tariff.currentCosts")}
      />
      <p className="mb-6">{`${t(
        "cockpit.managementTile.tariff.until",
      )} ${getDateString(getNextPaymentDate(simDetail.remainingDays))}`}</p>

      <SimPricingDetails
        productName={getBaseFlatName(simDetail)}
        basePrice={price}
        discounts={oneTimeDiscounts}
        voicePrice={simDetail.priceVoice}
        watchSimPrice={simDetail.priceWatch}
        totalPrice={simDetail.priceDiscounted || 0}
      />

      <div className="text-right text-base" data-testid="pay-interval-label">
        {autoRenewText}
      </div>

      {simDetail.autoRenew && isDowngrade && (
        <SimDetailsAccordion
          content={
            <>
              <TariffHeader
                className={clsx(isDowngrade ? "mb-2" : "mb-6")}
                title={t("cockpit.managementTile.tariff.subsequentCosts")}
              />
              <p className="mb-6">
                {typeof simDetail.remainingDays === "number"
                  ? `${t("cockpit.managementTile.tariff.starting")} ${getDateString(
                      getNextPaymentDate(simDetail.remainingDays + 1),
                    )}`
                  : ""}
              </p>

              <SimPricingDetails
                productName={getBaseFlatName(simDetail)}
                basePrice={price}
                discounts={recurringDiscounts}
                voicePrice={simDetail.successorForRenew?.priceVoice}
                watchSimPrice={simDetail.successorForRenew?.priceWatch}
                totalPrice={simDetail.successorForRenew?.priceDiscounted || 0}
              />

              <div
                className="text-right text-base"
                data-testid="pay-interval-label"
              >
                {autoRenewText}
              </div>
            </>
          }
          textOpen={t(
            "portal:cockpit.managementTile.tariff.showSubsequentCosts",
          )}
          textClose={t(
            "portal:cockpit.managementTile.tariff.hideSubsequentCosts",
          )}
        />
      )}
    </div>
  );
};
