import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { useGetRoaming } from "@/services/api";
import { RoamingInfoRoamingStatus } from "@/services/model";

export const useRoamingInfoForDisplayedSim = () => {
  const [simId] = useSimIdFromQueryParam();
  const { data: roamingInfo } = useGetRoaming(
    {
      simIds: [Number(simId)],
    },
    {
      query: {
        enabled: !isNaN(parseInt(simId)),
        // update the roaming Info as long as it got "ordered" items
        refetchInterval: (data) => {
          if (
            data?.some(
              (roaming) =>
                roaming.roamingStatus === RoamingInfoRoamingStatus.ordered,
            )
          ) {
            return 2000;
          }

          return false;
        },
      },
    },
  );

  return roamingInfo;
};
