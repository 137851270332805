import { Basket } from "@/services/model";

export const useGetCampaignEndDate: (basket?: Basket) => {
  campaignEndDate?: Date;
} = (basket) => {
  if (!basket?.entries) {
    return { campaignEndDate: undefined };
  }

  let smallestEndDate: Date | undefined = undefined;

  for (const entryKey in basket.entries) {
    const entry = basket.entries[entryKey];

    if (entry.options) {
      for (const option of entry.options) {
        if (option.discounts) {
          for (const discount of option.discounts) {
            const campaignPeriod = discount.campaignPeriod;

            if (campaignPeriod?.campaignPeriodEnum === "date_specific") {
              const endDate = new Date(campaignPeriod.endDate!);

              if (!smallestEndDate || endDate < smallestEndDate) {
                smallestEndDate = endDate;
              }
            }
          }
        }
      }
    }
  }

  return { campaignEndDate: smallestEndDate };
};
