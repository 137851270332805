import "@/components/Layout/DueNow/DueNow.scss";
import "./DueRecurringCard.scss";

import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { getNextPaymentDate } from "@/utils/tariffUtils";
import { getCurrency, getDateString } from "@/utils/translationHelpers";

export type DueRecurringCardProps = {
  isUpgrade?: boolean;
  remainingDays?: number;
  totalReoccurring: number;
  subtotalRecurring?: number;
  discountRecurring?: number;
  discountEndDate?: Date;
  currency: string;
  flatName?: string;
  billingPeriod: number;
  flatPrice: number;
  flatOption?: string | ReactNode;
  flatOptionPrice?: number;
  watchSimName?: string;
  watchSimPrice?: number;
};

export const DueRecurringCard = ({
  isUpgrade,
  remainingDays,
  totalReoccurring,
  subtotalRecurring,
  discountRecurring,
  discountEndDate,
  currency,
  flatName,
  billingPeriod,
  flatPrice,
  flatOption,
  flatOptionPrice,
  watchSimName,
  watchSimPrice,
}: DueRecurringCardProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "Onboarding.sections.checkout.summary",
  });

  const billingPeriodLabel = t("billingPeriod", {
    days: billingPeriod || 30,
  });

  const startingOnLabel = t(isUpgrade ? "nextDueOn" : "startingOn", {
    date: getDateString(getNextPaymentDate(remainingDays || billingPeriod)),
  });

  const shouldShowSubtotalSection =
    !!subtotalRecurring &&
    subtotalRecurring > 0 &&
    !!discountRecurring &&
    discountRecurring > 0;

  return (
    <div id="duerecurringcard-payment-box" className="payment-box-wrapper">
      <div className="title-icon">
        <span>
          <Trans
            t={t}
            components={[<span key="dueNow" className="text-primary-100" />]}
            values={{ days: billingPeriod }}
          >
            payReoccuring
          </Trans>
        </span>
      </div>

      <div className="title-addition">{startingOnLabel}</div>

      <div className="payment-breakdown">
        <div className="flat-name">
          {flatName}
          <div className="mobile-days-label">{billingPeriodLabel}</div>
        </div>
        <div className="days-label">{billingPeriodLabel}</div>
        <div className="price">{getCurrency(flatPrice, currency)}</div>
        {/* Exclude Voice Option as it is already included in 'Flat Mobile' */}
        {!flatName?.startsWith("Flat Mobile") &&
          !!flatOption &&
          flatOptionPrice !== undefined &&
          flatOptionPrice >= 0 && (
            <>
              <div className="flat-name">
                {flatOption}
                <div className="mobile-days-label">{billingPeriodLabel}</div>
              </div>
              <div className="days-label">{billingPeriodLabel}</div>
              <div className="price">
                {getCurrency(flatOptionPrice, currency)}
              </div>
            </>
          )}
        {!!watchSimName &&
          watchSimPrice !== undefined &&
          watchSimPrice >= 0 && (
            <>
              <div className="flat-name">
                {watchSimName}
                <p className="mobile-days-label">{billingPeriodLabel}</p>
              </div>
              <span className="days-label">{billingPeriodLabel}</span>
              <span className="price" data-testid="voice-price">
                {getCurrency(watchSimPrice || 58, currency)}
              </span>
            </>
          )}
      </div>

      {shouldShowSubtotalSection && (
        <>
          <hr className="line" />
          <div className="total-breakdown">
            <div className="subtotal">
              <span>{t("subtotal")}</span>
            </div>
            <div className="subtotal-amount">
              {getCurrency(subtotalRecurring, currency)}
            </div>

            <div className="subtotal">
              {t("discount")}{" "}
              {discountEndDate && (
                <span className="text-[13px] text-black leading-6">
                  <Trans
                    t={t}
                    i18nKey="until_date"
                    components={[
                      <span key="until_date" className="text-red-500" />,
                    ]}
                    values={{
                      date: discountEndDate.toLocaleDateString("de-DE"),
                    }}
                  />
                </span>
              )}
            </div>
            <div className="subtotal-amount">
              - {getCurrency(discountRecurring, currency)}
            </div>
          </div>
        </>
      )}

      <hr className="line" />
      <div className="total-breakdown">
        <div className="total">
          {t("total")}
          <span className="tax-hint font-regular">{t("taxIncluded")}</span>
        </div>
        <div className="total-amount" data-testid="total-amount-due-recurring">
          {getCurrency(totalReoccurring || 0, currency)}
        </div>
      </div>
    </div>
  );
};
