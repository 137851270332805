import clsx from "clsx";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CampaignPeriod, DiscountDetail } from "@/services/model";
import { starterKit } from "@/utils/constants";
import { getCurrency } from "@/utils/translationHelpers";
interface PriceDetailsProps {
  productName: string;
  basePrice: number;
  voicePrice?: number;
  discounts?: DiscountDetail[];
  watchSimPrice?: number;
  totalPrice: number;
  includedFeatures?: { name: string; price: string }[];
}

const Divider = () => <hr className="border-primary-100 mt-[14px]" />;
const Description: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className,
}) => (
  <span
    data-testid="flat-name"
    className={clsx("flex flex-grow mr-2", className)}
  >
    {children}
  </span>
);
const Value: FC<{ className?: string; children: ReactNode }> = ({
  className,
  children,
}) => <span className={clsx("flex flex-none", className)}>{children}</span>;

export const SimPricingDetails = ({
  productName,
  basePrice,
  voicePrice,
  discounts,
  watchSimPrice,
  totalPrice,
  includedFeatures,
}: PriceDetailsProps) => {
  const { t } = useTranslation();

  // The starter kit should not be displayed in the discount list
  const discountsWithoutStarterKit = discounts?.filter(
    (discount) => discount.discountName !== starterKit,
  );

  // Here, we need to calculate the total price without the starter kit
  const priceWithoutStarterPack =
    totalPrice +
    (discounts?.find((discount) => discount.discountName === starterKit)
      ?.amount || 0);

  const getDiscountText = (
    campaignPeriod: CampaignPeriod,
    discountName: string,
  ) => {
    let text = discountName;

    switch (campaignPeriod.campaignPeriodEnum) {
      case "date_specific":
        text += ` (${t("portal:cockpit.managementTile.tariff.until")} ${new Date(campaignPeriod!.endDate!)?.toLocaleDateString("de-DE")})`;
        break;
      case "one_time":
        break;
      default:
        text += ` (${t("portal:cockpit.managementTile.tariff.untilRevoke")})`;
        break;
    }

    return text;
  };

  return (
    <>
      <div data-testid="cost-display" className="flex justify-between mb-2">
        <Description>{productName}</Description>
        <Value>{getCurrency(basePrice, "CHF")}</Value>
      </div>
      {includedFeatures && (
        <div className="ml-2 text-sm">
          {includedFeatures?.map((feature) => (
            <div key={feature.name} className="flex justify-between  mb-2">
              <span>{feature.name}</span>
              <span>{feature.price}</span>
            </div>
          ))}
        </div>
      )}
      {voicePrice && voicePrice > 0 && (
        <div className="flex justify-between mb-2">
          <Description>
            {t("portal:cockpit.managementTile.watchSim.display.voiceOption")}
          </Description>
          <Value>{getCurrency(voicePrice || 0, "CHF")}</Value>
        </div>
      )}
      {watchSimPrice && (
        <>
          <div className="flex justify-between mb-2">
            <Description>
              {t("portal:cockpit.managementTile.watchSim.display.watchSIM")}
            </Description>
            <Value>{getCurrency(watchSimPrice || 0, "CHF")}</Value>
          </div>
        </>
      )}
      <Divider />

      {discountsWithoutStarterKit && discountsWithoutStarterKit.length > 0 && (
        <>
          {discountsWithoutStarterKit.map(
            ({ id, amount, campaignPeriod, discountName }) =>
              campaignPeriod &&
              discountName && (
                <div key={id} className="flex justify-between mb-2">
                  <Description className="text-xs">
                    {getDiscountText(campaignPeriod, discountName)}
                  </Description>
                  <Value className="text-xs">
                    - {getCurrency(amount || 0, "CHF")}
                  </Value>
                </div>
              ),
          )}
          <Divider />
        </>
      )}
      <p className="text-right text-xl font-semibold text-primary-100">
        {getCurrency(priceWithoutStarterPack, "CHF")}
      </p>
    </>
  );
};
