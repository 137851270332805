import "./InactiveSimTile.scss";

import { Trans, useTranslation } from "react-i18next";
import { FCC } from "types";

import { SpeedAndData } from "@/components/Portal/Cockpit/SpeedAndData/SpeedAndData";
import {
  DeviceTile,
  DeviceTileProps,
  getTitle,
} from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { SimCardDetail } from "@/services/model";
import {
  getIconForDevice,
  getSpeedFromLocalFlatSpeed,
} from "@/utils/deviceUtils";

interface InactiveProps extends DeviceTileProps {
  device: SimCardDetail;
}

export const InactiveSimTile: FCC<InactiveProps> = ({ device }) => {
  const { t } = useTranslation("portal");
  const title = getTitle(
    device.alias || t("layout.tiles.activeSim.yourDevice"),
  );
  const icon = getIconForDevice(device.device?.description);

  return (
    <DeviceTile
      navigationTarget={`../manage?simId=${device.simId}`}
      icon={icon}
      dataTestid="inactive-sim"
      className="inactive-sim-tile"
    >
      <div id="inactive-wrapper">
        <h2 className="tile-title">{title}</h2>
        <h3 className="tile-subtitle">
          {t("layout.tiles.inactive.noPackageActive")}
        </h3>
        <SpeedAndData speed={getSpeedFromLocalFlatSpeed(device)} />
      </div>
      <div className="text-base mt-14">
        <div className="text-primary-100">
          <Trans
            t={t}
            components={[<span key={0} className="font-semibold"></span>]}
            i18nKey={"layout.tiles.inactive.activateText"}
          />
        </div>
      </div>
    </DeviceTile>
  );
};
