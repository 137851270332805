import "./Rates.scss";

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "@/components/Layout/Divider";
import { RateOptionDesktop } from "@/components/Onboarding/Steps/RateSelection/RateOption/RateOptionDesktop";
import { RateOptionMobile } from "@/components/Onboarding/Steps/RateSelection/RateOption/RateOptionMobile";
import { RateFormProps } from "@/components/Onboarding/Steps/RateSelection/RateSelection";
import { useIsMobile } from "@/hooks/useIsMobile";
import {
  combineProductsByBaselabel,
  isFlatMobileTariff,
  TransformedProduct,
} from "@/utils/tariffUtils";

export interface RatesProps {
  processedProductsById: { [p: string]: TransformedProduct };
  preSelectedProcessedProduct: TransformedProduct | undefined;
  readonlyProductId?: number;
  currentRate: RateFormProps["rate"];
  shouldShowEsimTrial?: boolean;
}

export const Rates: FC<RatesProps> = ({
  processedProductsById,
  preSelectedProcessedProduct,
  readonlyProductId,
  currentRate,
  shouldShowEsimTrial = false,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const currentProduct =
    processedProductsById[currentRate || preSelectedProcessedProduct?.id || ""];

  // Create one RadioButton for the recommended custom rate product
  const recommendedProduct = useMemo(() => {
    const product = processedProductsById
      ? Object.entries(combineProductsByBaselabel(processedProductsById)).find(
          (transformedProduct) =>
            transformedProduct[1][0].recommended &&
            !isFlatMobileTariff(transformedProduct[0]),
        )
      : null;

    return product ? (
      <RateOptionMobile
        isSelected={currentProduct?.baseDisplayValue === product![0]}
        preSelectedProcessedProduct={preSelectedProcessedProduct}
        readonlyProductId={readonlyProductId}
        yearOption={product![1][1]}
        monthOption={product![1][0]}
        name={product![0]}
        currentProductId={currentProduct?.id}
        shouldShowEsimTrial={shouldShowEsimTrial}
      />
    ) : null;
  }, [
    currentProduct,
    preSelectedProcessedProduct,
    processedProductsById,
    readonlyProductId,
    shouldShowEsimTrial,
  ]);

  const optionsContainers = useMemo(
    () =>
      processedProductsById
        ? Object.entries(combineProductsByBaselabel(processedProductsById)).map(
            ([rate, [monthOption, yearOption]], index) =>
              isFlatMobileTariff(rate) ? null : isMobile ? (
                monthOption.recommended ? null : (
                  <RateOptionMobile
                    key={index}
                    isSelected={currentProduct?.baseDisplayValue === rate}
                    preSelectedProcessedProduct={preSelectedProcessedProduct}
                    readonlyProductId={readonlyProductId}
                    yearOption={yearOption}
                    monthOption={monthOption}
                    name={rate}
                    currentProductId={currentProduct?.id}
                    shouldShowEsimTrial={shouldShowEsimTrial}
                  />
                )
              ) : (
                <RateOptionDesktop
                  key={index}
                  isSelected={currentProduct?.baseDisplayValue === rate}
                  preSelectedProcessedProduct={preSelectedProcessedProduct}
                  readonlyProductId={readonlyProductId}
                  yearOption={yearOption}
                  monthOption={monthOption}
                  name={rate}
                  currentProductId={currentProduct?.id}
                  shouldShowEsimTrial={shouldShowEsimTrial}
                />
              ),
          )
        : null,
    [
      currentProduct,
      readonlyProductId,
      processedProductsById,
      preSelectedProcessedProduct,
      shouldShowEsimTrial,
      isMobile,
    ],
  );

  return (
    <fieldset>
      <div className="labels-container">
        <div className="descriptions">
          <span className="label">
            {t("Onboarding.sections.rate-selection.rate")}
          </span>
          <span className="label">
            {t("Onboarding.sections.rate-selection.speed")}
          </span>
        </div>
        <div className="durations">
          <span className="label">
            {t("Onboarding.sections.rate-selection.30days")}
          </span>
          <span className="label">
            {t("Onboarding.sections.rate-selection.365days")}
          </span>
        </div>
      </div>
      {processedProductsById && isMobile && recommendedProduct && (
        <div className="pt-6">
          {recommendedProduct}
          <div className="flex justify-evenly mt-6">
            <Divider />
            <div className="flex flex-grow-0 px-6">
              {t("Onboarding.sections.rate-selection.or")}
            </div>
            <Divider />
          </div>
        </div>
      )}
      {optionsContainers}
    </fieldset>
  );
};
