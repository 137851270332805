import { Globe } from "@/assets/icons/icons";
import { DueNow } from "@/components/Layout/DueNow/DueNow";
import { useGetCampaignEndDate } from "@/hooks/useGetCampaignEndDate";
import { Basket } from "@/services/model";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

type DueNowProps = {
  basket: Basket;
  flatName?: string;
  billingPeriod: string;
};

export const RoamingDueNow = ({
  basket,
  billingPeriod,
  flatName,
}: DueNowProps) => {
  const entry = basket.entries[0];
  const option = entry.options?.length ? entry?.options[0] : undefined;

  const subtotalOnce = basket.total?.oneTime?.amount || 0;
  // If balance is negative it is the credit, otherwise it is something else
  const credit =
    basket.balance && basket.balance < 0 ? Math.abs(basket.balance || 0) : 0;
  const discountAmount = basket?.total?.oneTimeCampaign?.amount;
  // substract discount and credit to achieve totalCost
  const totalOnce =
    (basket?.total?.oneTime?.amount || 0) -
    (basket?.total?.oneTimeCampaign?.amount || 0) -
    credit;
  const currency = getCurrencyFromLookupValue(basket.currency || { id: 1 });
  const { campaignEndDate } = useGetCampaignEndDate(basket);

  const flatPrice =
    option?.oneTimeCharges?.[0] && !!option.oneTimeCharges[0].amount
      ? option?.oneTimeCharges[0].amount
      : 0;

  return (
    <DueNow
      currency={currency}
      icon={<Globe />}
      totalOnce={totalOnce}
      subtotalOnce={subtotalOnce}
      discountAmount={discountAmount}
      discountEndDate={campaignEndDate}
      dataRateName={flatName}
      dataRatePrice={flatPrice}
      billingPeriod={billingPeriod}
      credit={credit}
    />
  );
};
