import { useTranslation } from "react-i18next";

export const TwoFactorChangeTitle = () => {
  const { t } = useTranslation();

  return (
    <h3 className="text-secondary-100">
      {t("portal:user-account.security.2fa.initialSetup.title")}
    </h3>
  );
};
