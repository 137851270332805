import "./Step.scss";

import { ReactNode } from "react";
import { FCC } from "types";

interface StepProps {
  id: string;
  headline: string;
  headlinePrimary?: string;
  navigation?: ReactNode;
  subheadline?: string;
}

export const Step: FCC<StepProps> = ({
  id,
  headline,
  headlinePrimary,
  subheadline,
  navigation,
  children,
}) => {
  return (
    <div className="step-container" data-testid={id} id={id}>
      <div className="step">
        <h2 className="headline">
          {headline}
          {headlinePrimary ? (
            <p className="text-primary-100 inline"> {headlinePrimary}</p>
          ) : (
            ""
          )}
        </h2>
        {subheadline && <p className="font-regular">{subheadline}</p>}
        {children}
      </div>
      {navigation}
    </div>
  );
};
