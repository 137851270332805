import {
  BusinessPersonalDetailsFormFields,
  FormOfAddresses,
} from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetailsFormFields/PersonalDetailsFormFields";
import {
  BasicCustomerData,
  SimCustomerInfo,
  SimCustomerInfoAddESIMNotAllowedReasonItem,
} from "@/services/model";
import { getCountryFromId } from "@/utils/countries";
import { getLocalizedDateString } from "@/utils/dateHelpers";

export const getSalutationTranslationKey = (
  salutation: BasicCustomerData["salutation"],
) => {
  switch (salutation?.id) {
    case 1:
      return "Onboarding.sections.personal-details.form.formOfAddress.mr";
    case 2:
      return "Onboarding.sections.personal-details.form.formOfAddress.mrs";
    default:
      return "Onboarding.sections.personal-details.form.formOfAddress.other";
  }
};

export const getPhoneFromBasicCustomerDataPhone = (
  phone: BasicCustomerData["phone"] | undefined,
) => {
  return phone && phone.countryCode && phone.areaCode && phone.cli
    ? `${phone.countryCode} ${phone.areaCode} ${phone.cli}`
    : undefined;
};

export const getLocalizedBirthday = (
  birthday: string | undefined,
  language: string,
) => {
  return birthday ? getLocalizedDateString(birthday, language) : "-";
};

export const transformPersonalDetailsFormToBasicCustomerData: (
  formValues: BusinessPersonalDetailsFormFields,
  data: BasicCustomerData,
) => BasicCustomerData = (formValues, data) => {
  const birthday = formValues.dateOfBirth ?? data.birthday;

  return {
    id: data?.id,
    customerId: data.customerId,
    salutation: { id: formValues.formOfAddress || data.salutation!.id },
    phone: {
      id: data.phone?.id,
      phone: formValues.phone.replaceAll(" ", "/"),
    },
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    ...(birthday ? { birthday: toValidBirthdayFormat(birthday) } : {}),
    mainAddress: {
      id: data.mainAddress?.id,
      zip: formValues.zip,
      city: formValues.city,
      street: formValues.street,
      houseNumber: formValues.houseNumber,
      postalDeliveryNotes: formValues.postalDeliveryNotes,
      country: { id: formValues?.country?.ID },
    },
    ...(!!formValues.organizationName && {
      companyName: formValues.organizationName,
      legalForm: formValues.organizationForm,
    }),
    ...(!!formValues.organizationId && {
      registerNo: formValues.organizationId,
    }),
    ...(!!data.customerType && {
      customerType: data.customerType,
    }),
  };
};

export const transformBasicCustomerDataToPersonalDetailsForm: (
  data: BasicCustomerData,
) => Omit<BusinessPersonalDetailsFormFields, "terms"> = (data) => {
  return {
    formOfAddress: (data.salutation?.id?.toString() ||
      FormOfAddresses.other) as FormOfAddresses,
    firstName: data?.firstName || "Max",
    lastName: data?.lastName || "Mustermann",
    street: data?.mainAddress?.street || "",
    houseNumber: data?.mainAddress?.houseNumber || "",
    postalDeliveryNotes: data?.mainAddress?.postalDeliveryNotes || "",
    zip: data?.mainAddress?.zip || "",
    city: data?.mainAddress?.city || "",
    country: getCountryFromId(data?.mainAddress?.country.id),
    phone: data?.phone?.phone?.replaceAll("/", " ") || "",
    dateOfBirth: data.birthday
      ? new Date(data.birthday).toISOString().substring(0, 10)
      : "",
    organizationName: data?.companyName || "",
    organizationForm: data?.legalForm || { id: 0 },
    organizationId: data?.registerNo || "",
  };
};

// firstName is a mandatory field when filling personal details. If it's not
// there, the customer hasn't filled their personal details.
export const hasValidPersonalDetails = (customer: BasicCustomerData) =>
  !!customer.firstName &&
  customer.mainAddress?.street !== "Dummy" &&
  customer.mainAddress?.zip !== "0000";

export const isBusinessCustomer = (customer: BasicCustomerData) =>
  customer?.customerType?.id === 2;

/** Email Regex used by the backend */
export const emailRegex =
  /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9])])$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])[a-zA-Z\d=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{8,30}$/;

export const allowedBusinessCustomerNameRegex = /^[^,;]*$/;

export const canOnlyBookData = (customer: BasicCustomerData) =>
  !!customer.dataOnly;

export const canAddESim = (simCustomerActions?: SimCustomerInfo): boolean =>
  simCustomerActions !== undefined &&
  simCustomerActions?.addESIMAllowed === true;

export const cannotAddEsimBecauseIDCheckOpen = (
  simCustomerActions?: SimCustomerInfo,
) =>
  simCustomerActions?.addESIMNotAllowedReason?.includes(
    SimCustomerInfoAddESIMNotAllowedReasonItem.IDCheckOpen,
  ) || false;
export const cannotAddEsimBecauseReachedMaximumSims = (
  simCustomerActions?: SimCustomerInfo,
): boolean =>
  simCustomerActions?.addESIMNotAllowedReason?.includes(
    SimCustomerInfoAddESIMNotAllowedReasonItem.TooManySIMs,
  ) || false;

export const cannotAddEsimBecauseBillDunning = (
  simCustomerActions?: SimCustomerInfo,
): boolean =>
  simCustomerActions?.addESIMNotAllowedReason?.includes(
    SimCustomerInfoAddESIMNotAllowedReasonItem.DunningLevel3,
  ) || false;

const toValidBirthdayFormat = (birthday: string): string =>
  // Format a date into YYYY-MM-DD
  new Date(birthday).toLocaleDateString("fr-CA");
