import clsx from "clsx";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Info } from "@/assets/icons/icons";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import { ProgressBar } from "@/components/Layout/ProgressBar/ProgressBar";
import { RoamingInfoRoamingStatus } from "@/services/model";
import { Status } from "@/utils/enums";

import { RoamingCallExplanation } from "./RoamingCallExplanation";

type RoamingUsageProps = {
  days: number;
  description: string;
  descriptionExtension?: string;
  initial: number;
  isFirstOfUsageZone: boolean;
  remaining: number;
  consumed?: number;
  title: string;
  isIncludedInService?: boolean;
  roamingStatus?: RoamingInfoRoamingStatus;
  isYearly?: boolean;
  showFlatMobileExplanation?: boolean;
  showRoamingCallExplanation?: boolean;
  isNotAllowedToUse?: boolean;
  isMobileOrMobilePlusTariff: boolean;
  isRoamingCallType?: boolean;
};

const getPercentage = (
  initial: number | undefined,
  current: number | undefined,
) => {
  if (initial !== undefined && current !== undefined) {
    return ((initial - current) / initial) * 100;
  }

  return 0;
};

/** Calculates and displays the data usage of a roaming option */
export const RoamingUsage = ({
  days,
  description,
  descriptionExtension,
  initial,
  isFirstOfUsageZone,
  remaining,
  consumed,
  title,
  isIncludedInService,
  roamingStatus,
  isYearly,
  showFlatMobileExplanation,
  showRoamingCallExplanation,
  isNotAllowedToUse,
  isMobileOrMobilePlusTariff,
  isRoamingCallType,
}: RoamingUsageProps) => {
  const [usage, setUsage] = useState<number | undefined>(0);
  const { t } = useTranslation("portal");

  const isActive =
    roamingStatus === Status.active || isMobileOrMobilePlusTariff;

  useEffect(() => {
    setUsage(isActive ? getPercentage(initial, remaining) : undefined);
  }, [roamingStatus, isActive, initial, remaining]);

  const isUsedUpMobileAboRoaming = isIncludedInService && remaining === 0;
  const isOverspentMobileAboRoaming = isIncludedInService && remaining < 0;
  const isFlatMobileRoamingCallCase =
    isRoamingCallType && isMobileOrMobilePlusTariff;

  return (
    <section className={clsx("mb-4", isUsedUpMobileAboRoaming && "opacity-50")}>
      {showFlatMobileExplanation && !isUsedUpMobileAboRoaming ? (
        <MobileAboProRataExplanation title={title} />
      ) : showRoamingCallExplanation && !isNotAllowedToUse ? (
        <RoamingCallExplanation title={title} />
      ) : (
        <p className="text-base mb-4">{title}</p>
      )}

      <ProgressBar
        usageBarWidth={
          isNotAllowedToUse ||
          (roamingStatus === RoamingInfoRoamingStatus.ready && consumed === 0)
            ? undefined
            : usage
        }
      />

      <span
        className={clsx(
          "description",
          !isActive && "inactive",
          isOverspentMobileAboRoaming && "!text-red-500",
        )}
      >
        {roamingStatus === "ordered" && (
          <p>{t("cockpit.managementTile.roaming.showOption.ordered.usage")}</p>
        )}

        {roamingStatus === Status.ready && !isFlatMobileRoamingCallCase && (
          <Trans
            components={[<span key="0" className="font-semibold" />]}
            t={t}
            values={{
              days,
            }}
          >
            {isFirstOfUsageZone
              ? "cockpit.managementTile.roaming.showOption.ready.firstPackageOfUsageZone.usage"
              : "cockpit.managementTile.roaming.showOption.ready.notFirstPackageOfUsageZone.usage"}
          </Trans>
        )}
        {(roamingStatus === Status.active || isFlatMobileRoamingCallCase) && (
          <span>
            {isNotAllowedToUse ? (
              <span>
                {t(
                  "cockpit.managementTile.roaming.showOption.active.notAllowed",
                )}
              </span>
            ) : (
              <Trans
                components={[<span key="0" className="font-semibold" />]}
                values={{
                  // If no consumed value is passed it means we also passed different translation text and need to use remaining value.
                  // 0 evaluates as false btw, so we need to check against undefined
                  value: consumed !== undefined ? consumed : remaining,
                  initial,
                }}
                t={t}
              >
                {description}
              </Trans>
            )}
            {isOverspentMobileAboRoaming && (
              <p className="text-primary-100">
                <Trans
                  components={[
                    <Link
                      key="0"
                      className="underline"
                      to={t(
                        "cockpit.managementTile.roaming.showOption.active.overspentCallsLink",
                      )}
                      target="_blank"
                    />,
                  ]}
                  t={t}
                >
                  {t(
                    "cockpit.managementTile.roaming.showOption.active.overspentCalls",
                  )}
                </Trans>
              </p>
            )}
          </span>
        )}
        {!isYearly && descriptionExtension && (
          <span> {descriptionExtension}</span>
        )}
      </span>
    </section>
  );
};

const MobileAboProRataExplanation = ({ title }: { title: string }) => {
  const { t } = useTranslation("portal");

  return (
    <SectionButtonHeader className="mb-4" header={title}>
      <Tooltip
        position="bottom"
        className="mt-4 !px-6 !py-4 right text-black font-normal text-left text-base mr-5 sm:mr-6 md:mr-0 min-w-[327px]"
        content={
          <>
            <h3 className="text-lg font-semibold text-primary-100 pb-2 block">
              {t(
                "cockpit.managementTile.roaming.showOption.flatMobileTooltip.title",
              )}
            </h3>
            <ul className="list-disc pl-4">
              <li>
                <b>
                  {t(
                    "cockpit.managementTile.roaming.showOption.flatMobileTooltip.monthlyRenewal.title",
                  )}
                  :
                </b>
                <br />
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.monthlyRenewal.description",
                )}
              </li>
              <li>
                <b>
                  {t(
                    "cockpit.managementTile.roaming.showOption.flatMobileTooltip.proRataVolume.title",
                  )}
                  :
                </b>
                <br />
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.proRataVolume.description",
                )}
              </li>
            </ul>
            <p className="pt-2">
              <b>
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileTooltip.example.title",
                )}
                :
              </b>
              <br />
              {t(
                "cockpit.managementTile.roaming.showOption.flatMobileTooltip.example.description",
              )}
            </p>
          </>
        }
      >
        <Info className="w-[21px] h-[21px] text-primary-100" />
      </Tooltip>
    </SectionButtonHeader>
  );
};
