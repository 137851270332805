import "./Tooltip.scss";

import clsx from "clsx";
import { ReactNode, useEffect, useRef, useState } from "react";

import { FCC } from "@/types";

interface Props {
  className?: string;
  content: string | ReactNode;
  dataTestId?: string;
  position?: "top" | "bottom";
  leftOnMobile?: boolean;
}

export const Tooltip: FCC<Props> = ({
  children,
  className,
  content,
  dataTestId,
  position = "top",
  leftOnMobile = false,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Close tooltip when clicking outside (mobile case)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      onMouseOver={() => {
        setIsVisible(true);
      }}
      onMouseLeave={() => {
        setIsVisible(false);
      }}
      className="tooltip-container"
    >
      <div
        ref={tooltipRef}
        data-testid={dataTestId}
        className={clsx(
          "tooltip",
          className,
          position,
          leftOnMobile && "left-on-mobile",
          isVisible ? "visible" : "hidden",
        )}
      >
        {content}
      </div>
      <div onClick={() => setIsVisible((prev) => !prev)}>{children}</div>
    </div>
  );
};
