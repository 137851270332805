import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/Interface/Button/Button";
import { Modal } from "@/components/Interface/Modal/Modal";

export const EsimJourneyCheckoutBlockedDialog = ({
  hasIDCheckPending,
  hasReachedMaximumAmountOfSims,
  hasBillDunning,
}: {
  hasIDCheckPending?: boolean;
  hasReachedMaximumAmountOfSims?: boolean;
  hasBillDunning?: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirmAction = () => navigate("/portal/cockpit");

  return (
    <Modal
      title={t("portal:esimJourneyCheckoutBlocked.title")}
      onClose={confirmAction}
      showCloseButton={false}
    >
      <p data-testid="esim-checkout-blocked-description" className="mb-6">
        {t("portal:esimJourneyCheckoutBlocked.explanation")}
      </p>
      <ul className="ml-4 list-disc">
        {hasIDCheckPending && (
          <li>{t("portal:esimJourneyCheckoutBlocked.idCheckOpen")}</li>
        )}
        {hasReachedMaximumAmountOfSims && (
          <li>
            {t(
              "portal:esimJourneyCheckoutBlocked.hasReachedMaximumAmountOfSims",
            )}
          </li>
        )}
        {hasBillDunning && (
          <li>{t("portal:esimJourneyCheckoutBlocked.hasBillDunning")}</li>
        )}
      </ul>
      <Button className="w-fit ml-auto mt-6 accent" onClick={confirmAction}>
        {t("portal:esimJourneyCheckoutBlocked.buttonText")}
      </Button>
    </Modal>
  );
};
