import "./MobileAboSwitch.scss";

import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const MobileAboSwitch = ({
  initialIndex,
  disabled,
  children,
}: {
  initialIndex?: number;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  const { reset } = useFormContext();
  const { t } = useTranslation();

  const index = initialIndex;

  const tabGroupProps = {
    defaultIndex: index,
    selectedIndex: disabled ? index : undefined,
    onChange: disabled ? () => {} : () => reset(),
  };

  return (
    <Tab.Group {...tabGroupProps}>
      <div
        className={clsx(
          "flex w-full justify-center my-4 mt-14",
          disabled && "opacity-50",
        )}
      >
        <Tab.List className="mobile-abo-switch">
          {({ selectedIndex }) => (
            <>
              <div className="switch-group">
                <Tab className="switch-text">
                  {({ selected }) => (
                    <span
                      className={clsx(
                        "text-primary-100 z-40 whitespace-nowrap",
                        selected ? "text-primary-100" : "text-white",
                      )}
                    >
                      {t("Onboarding.sections.rate-selection.30days")}
                    </span>
                  )}
                </Tab>
                <Tab className="switch-text">
                  {({ selected }) => (
                    <span
                      className={clsx(
                        "z-40 whitespace-nowrap",
                        selected ? "text-primary-100" : "text-white",
                      )}
                    >
                      {t("Onboarding.sections.rate-selection.365days")}
                    </span>
                  )}
                </Tab>
              </div>
              <span
                aria-hidden="true"
                style={{
                  transform:
                    selectedIndex === 0
                      ? "translate(0px)"
                      : "translate(calc(100% ))",
                }}
                className={clsx(
                  selectedIndex === 0 ? "translate-x-full" : "translate-x-0",
                  "pointer-events-none inline-block h-[34px] z-20 w-1/2 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out",
                )}
              />
            </>
          )}
        </Tab.List>
      </div>
      {children}
    </Tab.Group>
  );
};
