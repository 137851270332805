import { ReactNode, useState } from "react";

export const SimDetailsAccordion = ({
  content,
  textOpen,
  textClose,
}: {
  content: ReactNode;
  textOpen: string;
  textClose: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen((prev) => !prev);

  return (
    <div className="mt-2">
      <button
        data-testid="sim-details-accordion-button"
        onClick={toggleAccordion}
        className="w-full flex justify-between items-center py-3 text-left text-base text-primary-100 font-medium underline"
      >
        {isOpen ? textClose : textOpen}
        <svg
          className={`w-5 h-5 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      <div
        className={`overflow-hidden transition-[max-height] duration-300 ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        <div className="py-3">{content}</div>
      </div>
    </div>
  );
};
