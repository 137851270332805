import "./DueNow.scss";

import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { getCurrency } from "@/utils/translationHelpers";

export type DueNowProps = {
  totalOnce: number;
  subtotalOnce: number;
  discountAmount?: number;
  discountEndDate?: Date;
  currency: string;
  dataRateName?: string;
  dataRatePrice?: number;
  billingPeriod: string;
  voiceOptionName?: string | ReactNode;
  voiceOptionPrice?: number;
  watchSimName?: string;
  watchSimPrice?: number;
  credit?: number;
  creditTimeRemaining?: number;
  icon?: ReactNode;
};

// TODO - Refactor the RoamingDueNow DeviceTile so they make more sense,
// e.g this component should not be called interface if it's not an interface
// and the DueNowLightInterface should be reused and renamed
export const DueNow = ({
  totalOnce,
  subtotalOnce,
  discountAmount,
  discountEndDate,
  currency,
  billingPeriod,
  dataRateName,
  dataRatePrice,
  voiceOptionName,
  voiceOptionPrice,
  watchSimName,
  watchSimPrice,
  credit,
  creditTimeRemaining,
  icon,
}: DueNowProps) => {
  const { t } = useTranslation();

  const hasCredit = !!credit && credit > 0;
  const hasCreditTimeRemaining =
    !!creditTimeRemaining && creditTimeRemaining > 0;
  const hasDiscount = !!discountAmount && discountAmount > 0;

  const shouldShowSubtotalSection =
    !!subtotalOnce &&
    subtotalOnce > 0 &&
    (hasCredit || hasCreditTimeRemaining || hasDiscount);

  return (
    <div className="payment-box-wrapper w-72 xsPlus:w-full">
      <div className="title-icon">
        <span>
          <Trans
            components={[<span key="dueNow" className="text-primary-100" />]}
            t={t}
          >
            Onboarding.sections.checkout.summary.payOnce
          </Trans>
        </span>
        <div id="device-icon">{icon}</div>
      </div>

      <div className="payment-breakdown">
        {!!dataRateName &&
          dataRatePrice !== undefined &&
          dataRatePrice >= 0 && (
            <>
              <div className="flat-name">
                {dataRateName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="flat-price">
                {getCurrency(dataRatePrice, currency)}
              </span>
            </>
          )}
        {/* Exclude Voice Option as it is already included in 'Flat Mobile' */}
        {!dataRateName?.startsWith("Flat Mobile") &&
          !!voiceOptionName &&
          voiceOptionPrice !== undefined &&
          voiceOptionPrice >= 0 && (
            <>
              <div className="flat-name">
                {voiceOptionName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="voice-price">
                {getCurrency(voiceOptionPrice, currency)}
              </span>
            </>
          )}
        {!!watchSimName &&
          watchSimPrice !== undefined &&
          watchSimPrice >= 0 && (
            <>
              <div className="flat-name">
                {watchSimName}
                <p className="mobile-days-label">{billingPeriod}</p>
              </div>
              <span className="days-label">{billingPeriod}</span>
              <span className="price" data-testid="watch-price">
                {getCurrency(watchSimPrice, currency)}
              </span>
            </>
          )}
      </div>
      {shouldShowSubtotalSection && (
        <>
          <hr className="line" />
          <div className="total-breakdown">
            <div className="subtotal">
              <span>{t("Onboarding.sections.checkout.summary.subtotal")}</span>
            </div>
            <div className="subtotal-amount">
              {getCurrency(subtotalOnce, currency)}
            </div>
            {hasCreditTimeRemaining && (
              <>
                <span className="credit">
                  {t("Onboarding.sections.checkout.summary.credit")}{" "}
                  <span className="hint">
                    {t("cockpit.managementTile.tariff.checkout.creditText")}
                  </span>
                </span>
                <span className="credit-amount">
                  - {getCurrency(creditTimeRemaining, currency)}
                </span>
              </>
            )}
            {hasDiscount && (
              <>
                <div className="subtotal">
                  <span>
                    {t("Onboarding.sections.checkout.summary.discount")}{" "}
                  </span>
                  {discountEndDate && (
                    <span className="hint">
                      <Trans
                        t={t}
                        i18nKey="Onboarding.sections.checkout.summary.until_date"
                        components={[
                          <span key="until_date" className="text-red-500" />,
                        ]}
                        values={{
                          date: discountEndDate.toLocaleDateString("de-DE"),
                        }}
                      />
                    </span>
                  )}
                </div>
                <div className="subtotal-amount">
                  - {getCurrency(discountAmount, currency)}
                </div>
              </>
            )}
            {hasCredit && (
              <>
                <span className="credit">
                  {t("Onboarding.sections.checkout.summary.credit")}{" "}
                  <span className="hint">
                    {t(
                      "cockpit.managementTile.tariff.checkout.creditTextOthers",
                    )}
                  </span>
                </span>
                <span className="credit-amount">
                  - {getCurrency(credit, currency)}
                </span>
              </>
            )}
          </div>
        </>
      )}
      <hr className="line" />
      <div className="total-breakdown">
        <div className="total">
          {t("Onboarding.sections.checkout.summary.total")}
          <span className="tax-hint font-regular">
            {t("Onboarding.sections.checkout.summary.taxIncluded")}
          </span>
        </div>
        <div className="total-amount" data-testid="total-amount-due-now">
          {totalOnce <= 0
            ? `${currency} 0.00`
            : getCurrency(totalOnce, currency)}
        </div>
      </div>
    </div>
  );
};
