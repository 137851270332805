import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Info } from "@/assets/icons/icons";
import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";

export const FreeEsimTrialTooltip = ({
  iconClassName,
  leftOnMobile = false,
}: {
  iconClassName?: string;
  leftOnMobile?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      leftOnMobile={leftOnMobile}
      position="bottom"
      className="mt-4 !px-6 !py-4 right text-black font-normal text-left text-base  min-w-[250px] xsPlus:min-w-[327px]"
      content={
        <>
          <span className="text-lg font-semibold text-primary-100 pb-2 block">
            {t(
              "translation:Onboarding.sections.rate-selection.esimTooltip.title",
            )}
          </span>
          <p className="pb-2">
            {t(
              "translation:Onboarding.sections.rate-selection.esimTooltip.description",
            )}
          </p>
          <ul className="list-disc pl-4">
            <li>
              {t(
                "translation:Onboarding.sections.rate-selection.esimTooltip.swissAddress",
              )}
            </li>
            <li>
              {t(
                "translation:Onboarding.sections.rate-selection.esimTooltip.newCustomer",
              )}
            </li>
            <li>
              {t(
                "translation:Onboarding.sections.rate-selection.esimTooltip.identification",
              )}
            </li>
          </ul>
        </>
      }
    >
      <Info
        className={clsx("w-[21px] h-[21px] text-primary-100", iconClassName)}
      />
    </Tooltip>
  );
};
