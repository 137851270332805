import { useTranslation } from "react-i18next";
import { text } from "stream/consumers";

import { Anchor } from "@/components/Interface/Button/Button";

export const GoToShopSection = ({
  isOnboarding,
}: {
  isOnboarding?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="block xl:w-[300px] xl:ml-[38px]">
      <div className="flex flex-col xl:flex-row">
        <div className="h-[2px] w-[200px] bg-primary-100 xl:h-auto xl:w-[2px] xl:ml-auto xl:mr-10" />
        <div>
          <h3 className="mb-4 mt-6 text-primary-100 xl:mt-0">
            {t("Onboarding.sections.sim-selection.noSimCardTitle")}
          </h3>
          <p className="mb-10 block text-black font-regular">
            {t("Onboarding.sections.sim-selection.noSimCardText")}
          </p>
          <Anchor
            className="inverted secondary w-full smPlus:max-w-fit"
            href={
              // Redirect to homepage to recieve free trial
              // Only onboarding users are eligible (see T-1053)
              isOnboarding
                ? t("Onboarding.sections.sim-selection.homepageLink")
                : t("Onboarding.sections.sim-selection.shopLink")
            }
            label={t("Common.label.toShop")}
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
};
