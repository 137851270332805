import "./Onboarding.scss";

import { setUser } from "@sentry/react";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Background } from "@/components/Layout/Background/Background";
import { Footer } from "@/components/Layout/Footer/Footer";
import { DesktopHeading } from "@/components/Onboarding/DesktopHeading";
import { onboardingSections } from "@/components/Onboarding/Onboarding.constants";
import { OnboardingHeader } from "@/components/Onboarding/OnboardingHeader/OnboardingHeader";
import { OnboardingSidemenu } from "@/components/Onboarding/OnboardingSidemenu/OnboardingSidemenu";
import { StepGuard } from "@/components/Onboarding/StepGuard/StepGuard";
import { Wizard } from "@/components/Onboarding/Wizard/Wizard";
import { useIsAnonymousUser } from "@/hooks/useIsAnonymousUser";
import { useGetCustomer } from "@/services/api";

/**
 * Root route for displaying the steps of the onboarding process.
 */
export const Onboarding: FC = () => {
  const [isAnonymousUser] = useIsAnonymousUser();

  const { data } = useGetCustomer(undefined, {
    query: {
      // If we come back to the onboarding, we want to refetch the data
      refetchOnMount: "always",
      onSuccess: (data) => {
        setUser({ id: data.id?.toString() });
      },
      enabled: !isAnonymousUser,
    },
  });

  if (data?.onboardingFinished) {
    return <Navigate to="/portal/cockpit" replace={true} />;
  }

  return (
    <Background>
      <OnboardingHeader />
      <div className="bg-white flex-grow">
        <StepGuard basePath="/onboarding">
          <main id="onboarding-wrapper">
            <OnboardingSidemenu />
            <DesktopHeading />
            <div className="onboarding-content">
              <Wizard basePath="/onboarding" sections={onboardingSections}>
                <Outlet context={"/onboarding"} />
              </Wizard>
            </div>
          </main>
        </StepGuard>
      </div>
      <Footer className="has-wave" />
    </Background>
  );
};
