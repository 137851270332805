/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type DrCreditCardCardType =
  (typeof DrCreditCardCardType)[keyof typeof DrCreditCardCardType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrCreditCardCardType = {
  TWI: "TWI",
  PFC: "PFC",
  ECA: "ECA",
  VIS: "VIS",
} as const;
