import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import {
  ConnectionIcon,
  SpeedIcon,
  Subtract,
  VoiceOptionCall,
} from "@/assets/icons/icons";
import { RoamingCallExplanation } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/RoamingUsage/RoamingCallExplanation";
import { getTariffType } from "@/utils/deviceUtils";
import {
  getIncludedFeaturesRateSelection,
  TransformedProduct,
} from "@/utils/tariffUtils";

export const MobileAboCard = ({
  value,
  product,
  disabled,
}: {
  value: string | undefined;
  product: TransformedProduct;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const tariffType = getTariffType(product.id);

  if (!product.id) return null;

  const includedFeatures = getIncludedFeaturesRateSelection(
    t,
    tariffType,
    product.minContractDur || 30,
  );

  const iconClassName = "size-6 flex-shrink-0 text-primary-100";

  return (
    <RadioGroup.Option
      disabled={disabled}
      defaultChecked={value === product.id.toString()}
      value={product.id.toString()}
    >
      {({ checked }) => (
        <>
          {product.recommended && (
            <div className="absolute">
              <div className="absolute z-10 ml-4 font-semibold text-white text-xs">
                {t("Onboarding.sections.rate-selection.recommended")}
              </div>
              <Subtract />
            </div>
          )}
          <div
            className={clsx(
              "icon-tile px-6 py-4 h-full flex text-black w-[250px] xs:w-[280px] xsPlus:w-[300px]",
              disabled && "opacity-50",
              checked && "selected",
            )}
          >
            <div className="flex justify-between mt-2">
              <h2 className="text-secondary-100">{product.baseDisplayValue}</h2>
              <div className="mt-px pt-px">
                {checked ? (
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.5 6c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-8a5 5 0 1 1 10 0 5 5 0 0 1-10 0z"
                      fill="#0014FF"
                    />
                  </svg>
                ) : (
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.5 16c0-5.52 4.48-10 10-10s10 4.48 10 10-4.48 10-10 10-10-4.48-10-10zm2 0c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8-8 3.58-8 8z"
                      fill="#0014FF"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className={clsx(
                "flex flex-col gap-4 text-base flex-grow",
                checked ? "text-primary-100" : "",
              )}
            >
              <p className="font-semibold">{t("countries.CHE")}</p>

              <p className="flex flex-row gap-2">
                <ConnectionIcon className={iconClassName} />
                {includedFeatures[0].name}
              </p>
              <p className="flex flex-row gap-2">
                <SpeedIcon className={iconClassName} />
                {includedFeatures[2].name}
              </p>
              <p className="flex flex-row gap-2">
                <VoiceOptionCall className={iconClassName} />
                {includedFeatures[1].name}
              </p>

              <p className="font-semibold">
                {t("portal:mobileAbo.features.international")}
              </p>

              <p className="flex flex-row gap-2">
                <ConnectionIcon className={iconClassName} />
                {includedFeatures.length >= 4 ? includedFeatures[3].name : "-"}
              </p>
              <p className="flex flex-row gap-2">
                <VoiceOptionCall className={iconClassName} />
                <span className="inline-flex items-end">
                  {includedFeatures.length >= 4
                    ? includedFeatures[4].name
                    : "-"}
                  {includedFeatures.length >= 4 && <RoamingCallExplanation />}
                </span>
              </p>
            </div>

            <hr className="border-primary-100" />
            <p
              className={clsx(
                "text-lg font-semibold",
                checked ? "text-primary-100" : "text-black",
              )}
            >
              {product.chargeFlat}
            </p>
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
};
