import clsx from "clsx";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Subtract } from "@/assets/icons/icons";
import { FreeEsimTrialTooltip } from "@/components/Onboarding/Steps/RateSelection/FreeEsimTrialTooltip/FreeEsimTrialTooltip";
import { RateRadioButton } from "@/components/Onboarding/Steps/RateSelection/RateRadioButton/RateRadioButton";
import { RateOptionProps } from "@/types";

const rateStyle = "flex-1 flex-col w-full py-4 pl-4";
const daysLabelStyle = "ml-7 text-xs text-primary-100";

export const RateOptionMobile: FC<RateOptionProps> = ({
  name,
  yearOption,
  monthOption,
  preSelectedProcessedProduct,
  isSelected,
  currentProductId,
  readonlyProductId,
  shouldShowEsimTrial = false,
  disabled,
  voice,
}) => {
  const { t } = useTranslation();

  const isRecommended = yearOption?.recommended || monthOption?.recommended;
  const isPrecharged = preSelectedProcessedProduct?.precharged;
  const yearIsPreselected =
    yearOption?.id === preSelectedProcessedProduct?.id ||
    yearOption.voice === preSelectedProcessedProduct?.id;
  const monthIsPreselected =
    monthOption?.id === preSelectedProcessedProduct?.id ||
    monthOption.voice === preSelectedProcessedProduct?.id;

  const label =
    isRecommended && !isPrecharged
      ? t("Onboarding.sections.rate-selection.recommended")
      : isPrecharged
        ? t("Onboarding.sections.rate-selection.precharged")
        : null;

  return (
    <div
      className={clsx(
        "flex flex-col relative mt-6 shadow-primary border-1",
        isSelected && "border-solid border-highlight-100 bg-subtle-selected",
        disabled && !isSelected && "disabled",
        voice && "voice",
        isPrecharged && "precharged",
      )}
    >
      {label && (
        <>
          <div className="absolute z-10 top-[2px] left-8 font-semibold text-[10px] leading-3 -ml-2 text-white">
            {label}
          </div>
          <div className="hint">
            <Subtract />
          </div>
        </>
      )}
      <div
        className="text-base leading-6 content-baseline p-4 border-b-1 border-primary-50"
        data-testid="rate-descriptions"
      >
        <div className="flex gap-6">
          <span className="text-primary-100 font-semibold">{name}</span>
          <span
            className={clsx(isSelected && "text-primary-100 font-semibold")}
          >
            {monthOption?.bandwidthLabel} Mbits
          </span>
        </div>
        {shouldShowEsimTrial &&
          (currentProductId === yearOption.id ||
            currentProductId === monthOption.id) && (
            <p className="flex text-primary-100">
              <span className="mr-2">
                {t("Onboarding.sections.rate-selection.esim-trial-hint")}
              </span>
              <span className="mt-1">
                <FreeEsimTrialTooltip leftOnMobile={true} />
              </span>
            </p>
          )}
      </div>
      <div className="flex">
        <div
          data-testid="rate-durations"
          className={clsx(
            rateStyle,
            isPrecharged && !monthIsPreselected && "disabled",
          )}
        >
          {monthOption && (
            <RateRadioButton
              isReadonly={readonlyProductId === monthOption?.id}
              transformedProduct={monthOption}
              disabled={isPrecharged && !monthIsPreselected}
              preSelectedProcessedProduct={preSelectedProcessedProduct}
            />
          )}
          <div className={daysLabelStyle}>
            {t("Onboarding.sections.rate-selection.30days")}
          </div>
        </div>
        <div className="flex border-r-1 border-primary-50" />
        <div
          className={clsx(
            rateStyle,
            isPrecharged && !yearIsPreselected && "disabled",
          )}
        >
          {yearOption && (
            <RateRadioButton
              isReadonly={readonlyProductId === yearOption?.id}
              transformedProduct={yearOption}
              disabled={isPrecharged && !yearIsPreselected}
              preSelectedProcessedProduct={preSelectedProcessedProduct}
            />
          )}
          <div className={daysLabelStyle}>
            {t("Onboarding.sections.rate-selection.365days")}
          </div>
        </div>
      </div>
    </div>
  );
};
