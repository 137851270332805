import { useMemo } from "react";

import { Id } from "@/assets/icons/icons";
import { Anchor, StyledLink } from "@/components/Interface/Button/Button";

import { KYCModal } from "./KYCModal";
import { WarningHeader } from "./WarningHeader";

type IDCheckComponentProps = {
  title: string;
  info: string;
  buttonLabel?: string;
  showModal?: boolean;
  target?: string;
  type?: "external" | "internal";
  children?: React.ReactNode;
};

export const WarningWithKYCButton = ({
  title,
  info,
  buttonLabel,
  target,
  type = "external",
  showModal = true,
  children,
}: IDCheckComponentProps) => {
  const shouldShowInternalLink = !!buttonLabel && type === "internal";
  const shouldShowAnchor = !!buttonLabel && type === "external";

  const button = useMemo(() => {
    if (shouldShowAnchor && showModal)
      return <KYCModal buttonLabel={buttonLabel} target={target} />;
    if (shouldShowInternalLink)
      return (
        <StyledLink
          disabled={target === undefined}
          to={target ?? ""}
          className="inverted secondary w-fit whitespace-nowrap"
        >
          {buttonLabel}
        </StyledLink>
      );

    return (
      <Anchor
        disabled={target === undefined}
        href={target}
        target={"_blank"}
        label={buttonLabel}
        className="inverted secondary w-fit whitespace-nowrap"
      />
    );
  }, [
    shouldShowAnchor,
    showModal,
    buttonLabel,
    target,
    shouldShowInternalLink,
  ]);

  return (
    <WarningHeader
      icon={<Id className="hidden md:block" />}
      title={title}
      info={info}
      button={button}
    >
      {children}
    </WarningHeader>
  );
};
