import "./SpeedAndData.scss";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FCWithClassName } from "types";

import { ConnectionIcon, SpeedIcon } from "@/assets/icons/icons";

interface SpeedAndDataProps {
  speed: string;
}

export const SpeedAndData: FCWithClassName<SpeedAndDataProps> = ({
  className,
  speed,
}) => {
  const { t } = useTranslation();

  if (!speed) return null;

  return (
    <div className={clsx(className, "device-info")}>
      <div className="icon-row">
        <SpeedIcon role="img" title={t("Icons.speed")} />

        <span>{speed}</span>
      </div>

      <div className="icon-row">
        <ConnectionIcon role="img" title={t("Icons.connection")} />

        <span>{t("layout.tiles.activeSim.unlimited")}</span>
      </div>
    </div>
  );
};
