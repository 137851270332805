import "./IconTile.scss";

import clsx from "clsx";
import { FCC } from "types";

interface Props {
  icon?: JSX.Element;
  label?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  isMobile?: boolean;
}

export const IconTile: FCC<Props> = ({
  icon,
  label,
  selected,
  disabled,
  onClick,
  dataTestId = "icon-tile",
  isMobile,
}) => (
  <button
    aria-label={label}
    {...(disabled ? { disabled: true } : {})}
    className={clsx(
      "icon-tile",
      selected && "selected",
      disabled && "opacity-50",
      isMobile && "flex-row w-full h-auto py-6 px-8",
    )}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {icon && (
      <div className={clsx("icon-container", isMobile && "mr-2")}>{icon}</div>
    )}
    <p
      className={clsx(
        "label-container",
        isMobile && "!text-start !justify-start !w-full !whitespace-normal",
      )}
    >
      {label}
    </p>
  </button>
);
