import { FC, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  useGetIdentificationStatus,
  useGetIdentificationURL,
} from "@/services/api";
import {
  IdentificationStatusResponse,
  IdentificationStatusResponseKycStatus,
} from "@/services/model";

import { WarningWithKYCButton } from "./WarningWithKYCButton";

export const KYCWarning: FC = () => {
  const { t, i18n } = useTranslation();

  const staleTime = useRef(0);
  const {
    data: identificationStatus,
  }: {
    // This type construction can be removed once KYC_invalid_document was added to the BE
    // https://digitalrepublicag.atlassian.net/browse/DRDEV-875
    data:
      | IdentificationStatusResponse
      | undefined
      | {
          isVerified?: boolean;
          kycStatus?:
            | "KYC_invalid_document"
            | "KYC_max_attempts"
            | "KYC_ID_uncomparable";
        };
  } = useGetIdentificationStatus({
    query: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      cacheTime: 5 * 60 * 1000,
      retry: false,
      staleTime: staleTime.current,
      onSuccess: (data) => {
        if (
          data?.kycStatus === IdentificationStatusResponseKycStatus.OK &&
          data?.isVerified
        ) {
          staleTime.current = 5 * 60 * 1000;
        }
      },
    },
  });
  const { data: identificationUrl } = useGetIdentificationURL({
    query: {
      enabled:
        identificationStatus?.kycStatus ===
          IdentificationStatusResponseKycStatus.OPEN ||
        identificationStatus?.kycStatus === "KYC_invalid_document" ||
        identificationStatus?.kycStatus === "KYC_ID_uncomparable" ||
        identificationStatus?.kycStatus ===
          IdentificationStatusResponseKycStatus.KYC_error_processing_selfie ||
        identificationStatus?.kycStatus ===
          IdentificationStatusResponseKycStatus.KYC_error_processing_docs,
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1000,
      retry: false,
    },
  });
  const identificationUrlWithLanguage = useMemo(() => {
    if (identificationUrl) {
      const url = new URL(identificationUrl);
      url.searchParams.set("lang", i18n?.resolvedLanguage ?? "en");

      return url.toString();
    }
  }, [identificationUrl, i18n.resolvedLanguage]);

  if (
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.KYC_error_processing_selfie
  ) {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.KYC_error_processing_selfie")}
        info={t("cockpit.kycSection.errors.KYC_error_processing_selfie")}
        buttonLabel={t("cockpit.id-banner.open.button")}
        target={identificationUrlWithLanguage}
      />
    );
  } else if (
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.KYC_error_processing_docs
  ) {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.KYC_error_processing_docs")}
        info={t("cockpit.kycSection.errors.KYC_error_processing_docs")}
        buttonLabel={t("cockpit.id-banner.open.button")}
        target={identificationUrlWithLanguage}
      />
    );
  } else if (
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.PENDING
  ) {
    return (
      <WarningWithKYCButton
        title={t("cockpit.id-banner.pending.title")}
        info={t("cockpit.id-banner.pending.info")}
      />
    );
  } else if (
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.KYC_data_mismatch
  ) {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.personalDetails")}
        info={t("cockpit.kycSection.errors.personalDetails")}
        buttonLabel={t("cockpit.kycSection.buttonLabel.personalDetails")}
        target="/portal/user/personal"
        type="internal"
      >
        <div className="mt-4">
          <ul className="list-disc list-inside">
            <li>{t("cockpit.kycSection.toCorrect.names")}</li>
            <li>{t("cockpit.kycSection.toCorrect.dateOfBirth")}</li>
          </ul>
        </div>
      </WarningWithKYCButton>
    );
  } else if (
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.KYC_underaged
  ) {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.underaged")}
        info={t("cockpit.kycSection.errors.underaged")}
        buttonLabel={t("cockpit.kycSection.buttonLabel.underaged")}
        target={t("cockpit.kycSection.links.underaged")}
      />
    );
  } else if (identificationStatus?.kycStatus === "KYC_invalid_document") {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.invalidDocument")}
        info=""
        buttonLabel={t("cockpit.id-banner.open.button")}
        target={identificationUrlWithLanguage}
      >
        <Trans
          t={t}
          components={[
            <a
              key={0}
              className="text-primary-100 underline"
              href={t("cockpit.kycSection.links.invalidDocument")}
              target="_blank"
              rel="noreferrer"
            />,
          ]}
          i18nKey="cockpit.kycSection.errors.invalidDocument"
        />
      </WarningWithKYCButton>
    );
  } else if (identificationStatus?.kycStatus === "KYC_max_attempts") {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.maxAttempts")}
        info={t("cockpit.kycSection.errors.maxAttempts")}
        buttonLabel={t("cockpit.kycSection.buttonLabel.maxAttempts")}
        target={t("cockpit.kycSection.links.maxAttempts")}
        showModal={false}
      />
    );
  } else if (identificationStatus?.kycStatus === "KYC_ID_uncomparable") {
    return (
      <WarningWithKYCButton
        title={t("cockpit.kycSection.title.IDUncomparable")}
        info={t("cockpit.kycSection.errors.IDUncomparable")}
        buttonLabel={t("cockpit.id-banner.open.button")}
        target={identificationUrlWithLanguage}
      />
    );
  } else {
    return (
      <WarningWithKYCButton
        title={t("cockpit.id-banner.open.title")}
        info={t("cockpit.id-banner.open.info")}
        buttonLabel={t("cockpit.id-banner.open.button")}
        target={identificationUrlWithLanguage}
      />
    );
  }
};
