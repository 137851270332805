import { useOutletContext } from "react-router-dom";

export type WizardBasePath = "/onboarding" | "/portal/add-device";

/**
 * Gets the basepath of the onboarding.
 * This is used to determine whether the user is in the onboarding or in the portal.
 * @returns The base path of the wizard: "/onboarding" or "/portal/add-device"
 */
export const useWizardBasePath = () => useOutletContext<WizardBasePath>();
