import "@/components/Onboarding/Steps/NumberPorting/NumberPorting.scss";

import { captureMessage, withScope } from "@sentry/react";
import clsx from "clsx";
import { FC, useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Accordion } from "@/components/Interface/Accordion/Accordion";
import { Button } from "@/components/Interface/Button/Button";
import { Combobox } from "@/components/Interface/FormFields/Combobox/Combobox";
import { ErrorMessage } from "@/components/Interface/FormFields/ErrorMessage/ErrorMessage";
import { FormField } from "@/components/Interface/FormFields/FormField/FormField";
import { RadioButton } from "@/components/Interface/FormFields/RadioButton/RadioButton";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { Step } from "@/components/Onboarding/Steps/Step";
import { useIsInsidePortal } from "@/hooks/useIsInsidePortal";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import { useGetOnpProviders, useSetMnpData } from "@/services/api";
import { MnpData, Provider } from "@/services/model";
import { enableNumberPorting } from "@/utils/analytics/onboardingAnalytics";
import { getEntry } from "@/utils/dataContextHelpers";
import {
  deviceNameToValueMap,
  getDeviceDescription,
} from "@/utils/deviceUtils";
import { log } from "@/utils/log";

export type NumberPortingFormProps = {
  currentNumber: string;
  currentProvider: string;
  mnpContractType: "1" | "2"; // mnpContractType: id 1 = postpaid | id 2 = prepaid
  mnpTime: "2" | "3"; // mnpTime: id 2 = after the contract ends | id 3 = premature contract termination
};

// mnpContractType: id 1 = postpaid | id 2 = prepaid
const mnpContractType = {
  postpaid: { id: 1, description: "postpaid" },
  prepaid: { id: 2, description: "prepaid" },
};

// id 2 = after the contract ends | id 3 = premature contract termination
const mnpTime = {
  after: { id: 2, description: "after" },
  before: { id: 3, description: "before" },
};

const numberPrefix = "+41 (0) ";

const phoneRegex =
  /^(\+41\s\(0\))\s?(\d{1,2})\s?(\d{0,3})\s?(\d{0,2})\s?(\d{0,2})$/;

export const formatNumber = (input: string, oldInput = "") => {
  if (input.indexOf(numberPrefix) !== 0) {
    return oldInput;
  } else {
    const match = input.match(phoneRegex);
    const formatted = match
      ? match
          .slice(1)
          .filter((x) => x)
          .join(" ")
      : input;

    return formatted.substring(0, 32);
  }
};

const providersToObj = (arr: Provider[]): Record<string, number> =>
  Object.fromEntries(
    arr
      .filter(({ providerName, id }) => providerName && id)
      .map(({ providerName, id }) => [providerName, id]),
  );

export const NumberPorting: FC = () => {
  const { dataContext, setDataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isFetching: isLoadingProviders } = useGetOnpProviders();
  const providers = useMemo(() => data && providersToObj(data), [data]);

  const isInsidePortal = useIsInsidePortal();

  const { mutate: setMnpData, isLoading: isSettingMnpData } = useSetMnpData({
    mutation: {
      onSuccess: (data) => {
        setDataContext({
          ...dataContext,
          basket: data,
          numberPorting: formValues,
          allowedMaxOnboardingStep: 4,
        });
        navigate(
          `${basePath}/4/${isInsidePortal ? "checkout" : "personal-details"}`,
        );
      },
    },
  });

  const {
    register,
    watch,
    control,
    resetField,
    setValue,
    formState: { isValid, errors },
  } = useForm<NumberPortingFormProps>({
    mode: "onChange",
    defaultValues: dataContext.numberPorting,
  });

  const oldNumber = watch("currentNumber");
  const formValues = watch();

  const entry = getEntry(dataContext);
  const device = getDeviceDescription(entry?.device?.description);
  const continueIsDisabled = !(
    isValid &&
    dataContext.basket?.id &&
    formValues?.currentNumber &&
    formValues.currentProvider &&
    formValues.mnpContractType &&
    (formValues.mnpContractType?.toString() !==
      mnpContractType.postpaid.id?.toString() ||
      formValues.mnpTime)
  );

  const terminationIsDisabled = !(
    formValues?.mnpContractType?.toString() ===
    mnpContractType.postpaid.id?.toString()
  );

  const terminationBeforeContractEnd =
    formValues?.mnpTime?.toString() === mnpTime.before.id.toString();

  const navigateToNext = () => {
    const mnpData: MnpData = {
      // TODO: Temporary workaround to store the number
      portedPhoneNumber: {
        phone: formValues.currentNumber,
      },
      currentProvider: {
        id: providers?.[formValues.currentProvider] || -1,
        description: formValues.currentProvider,
      },
      mnpContractType:
        formValues.mnpContractType?.toString() ===
        mnpContractType.postpaid.id?.toString()
          ? mnpContractType.postpaid
          : mnpContractType.prepaid,
      mnpTime:
        formValues.mnpTime?.toString() === mnpTime.after.id.toString()
          ? mnpTime.after
          : mnpTime.before,
      msisdn: formValues.currentNumber, // TODO: Temporary workaround to store the number
    };

    if (!continueIsDisabled && dataContext.basket?.id) {
      // Track number porting analytics
      enableNumberPorting(
        formValues.currentProvider,
        formValues.mnpContractType === "1" ? "subscription" : "prepaid",
        formValues.mnpTime === "2"
          ? "after end of contract"
          : "early cancellation",
      );

      setMnpData({ basketId: dataContext.basket.id, index: 0, data: mnpData });
    } else {
      log(`
      dataContext.basket?.id: ${
        dataContext.basket?.id ? dataContext.basket?.id : "missing"
      }
      formValues ${formValues ? formValues : "missing"}
      formValues.currentNumber ${
        formValues.currentNumber ? formValues.currentNumber : "missing"
      }
      formValues.currentProvider ${
        formValues.currentProvider ? formValues.currentProvider : "missing"
      }
      formValues.mnpContractType ${
        formValues.mnpContractType ? formValues.mnpContractType : "missing"
      }
      formValues.mnpTime ${formValues.mnpTime ? formValues.mnpTime : "missing"}
   `);
      withScope(function (scope) {
        scope.setTag("section", "onboarding");
        scope.setTag("onboardingStep", "numberporting");
        scope.setContext("numberPortingForm", {
          basketId: dataContext.basket?.id,
          formErrors: errors,
        });
        captureMessage(
          `Navigation in NumberPorting was triggered while it should be disabled.`,
        );
      });
    }
  };

  return (
    <Step
      id="onboarding-number-porting"
      data-testid="onboarding-number-porting"
      headline={
        t("Onboarding.sections.rate-selection.header.rateFor") +
        " " +
        t(
          `Onboarding.sections.rate-selection.header.your.${device}`,
          t("Onboarding.sections.rate-selection.header.your.Andere"),
        )
      }
      headlinePrimary={t(
        `Common.devices.${deviceNameToValueMap[device]}`,
        t("Common.devices.Andere"),
      ).toString()}
      subheadline={t("Onboarding.sections.number-porting.subHeadline")}
      navigation={
        <div className="nav-button-container">
          <Button
            onClick={() => navigate(`${basePath}/3/rate-selection`)}
            className="accent inverted"
          >
            {t("Common.label.back")}
          </Button>

          <Button
            disabled={continueIsDisabled}
            onClick={() => navigateToNext()}
            className="accent"
          >
            {t("Common.label.forward")}
          </Button>
        </div>
      }
    >
      {(isLoadingProviders || isSettingMnpData) && <LoadingSpinner />}

      <Accordion
        className="mt-10"
        label={t("Onboarding.sections.number-porting.numberPorting")}
        canOpen={false}
      >
        <p className="mt-4">
          <Trans i18nKey="Onboarding.sections.number-porting.accordion.subline" />
        </p>

        <div className="content-container">
          <div className="mt-4 form-field-wrapper relative">
            <FormField<NumberPortingFormProps>
              id="telephone-number-input"
              autocomplete="tel"
              name="currentNumber"
              label={`1. ${t(
                "Onboarding.sections.number-porting.currentNumber",
              )}`}
              errors={errors}
              register={register}
              type="text"
              placeholder={t(
                "Onboarding.sections.number-porting.placeholder.currentNumber",
              )}
              onFocus={() => {
                if (!watch("currentNumber")) {
                  setValue("currentNumber", numberPrefix);
                }
              }}
              options={{
                pattern: {
                  value: phoneRegex,
                  message: t("Onboarding.sections.number-porting.numberFormat"),
                },
                onChange: (e: KeyboardEvent) => {
                  const input = e.target as HTMLInputElement;
                  const value = formatNumber(input.value, oldNumber);
                  setValue("currentNumber", value);
                },
              }}
            />

            <div
              className={`prefix-coloring ${
                watch("currentNumber") ? "" : "hidden"
              }`}
            ></div>
          </div>

          <div className="mt-6 form-field-container form-field-wrapper">
            {providers && (
              <Controller<NumberPortingFormProps>
                control={control}
                name="currentProvider"
                render={({ field }) => (
                  <Combobox
                    label={`2. ${t(
                      "Onboarding.sections.number-porting.currentProvider",
                    )}`}
                    field={field}
                    dataTestId="currentProviderCombobox"
                    options={Object.keys(providers)}
                    placeholder={t(
                      "Onboarding.sections.number-porting.placeholder.currentProvider",
                    )}
                  />
                )}
              />
            )}
          </div>
        </div>

        <div className="content-container">
          <fieldset className="mt-7 form-field-wrapper">
            <legend className="input-label">
              {`3. ${t(
                "Onboarding.sections.number-porting.currentContractType",
              )}`}
            </legend>

            <RadioButton
              name="mnpContractType"
              value={`${mnpContractType.prepaid.id}`}
              errors={errors}
              register={register}
              label={t("Onboarding.sections.number-porting.prepaid")}
              options={{
                onChange: () => {
                  resetField("mnpTime");
                },
              }}
              className="mb-5"
            />

            <RadioButton
              name="mnpContractType"
              value={`${mnpContractType.postpaid.id}`} // mnpContractType: id 1 = postpaid | id 2 = prepaid
              errors={errors}
              register={register}
              label={t("Onboarding.sections.number-porting.postpaid")}
            />
          </fieldset>

          <fieldset
            className={clsx(
              "mt-6 form-field-wrapper",
              terminationIsDisabled && "disabled",
            )}
            data-testid="termination"
          >
            <legend className="input-label">
              {`4. ${t("Onboarding.sections.number-porting.termination")}`}
            </legend>

            <p className="text-sm mb-2">
              {t("Onboarding.sections.number-porting.steps")}
            </p>

            <RadioButton
              name="mnpTime"
              value={`${mnpTime.after.id}`}
              errors={errors}
              register={register}
              label={t("Onboarding.sections.number-porting.afterContractEnds")}
              labelComponent={
                <Trans
                  components={[
                    <span
                      key="recommended"
                      className="font-x-small text-primary-75 m-2"
                    />,
                  ]}
                  i18nKey="Onboarding.sections.number-porting.recommended"
                />
              }
              options={{
                disabled: terminationIsDisabled,
              }}
              className="mb-5"
            />

            <RadioButton
              name="mnpTime"
              value={`${mnpTime.before.id}`}
              errors={errors}
              register={register}
              label={t("Onboarding.sections.number-porting.beforeContractEnds")}
              options={{
                disabled: terminationIsDisabled,
              }}
            />

            {terminationBeforeContractEnd && (
              <div className="ml-[28px]">
                <ErrorMessage
                  message={t(
                    "Onboarding.sections.number-porting.cost-warning",
                  ).toString()}
                  name={"mnpTime"}
                />
              </div>
            )}
          </fieldset>
        </div>
      </Accordion>
    </Step>
  );
};
