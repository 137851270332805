import { useTranslation } from "react-i18next";

import { ProgressBar } from "@/components/Layout/ProgressBar/ProgressBar";
import { RoamingCallExplanation } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/RoamingUsage/RoamingCallExplanation";
import { FCC } from "@/types";

interface Props {
  showRoamingCallExplanation?: boolean;
  isNotAllowedToUse?: boolean;
}

export const RoamingUnlimited: FCC<Props> = ({
  isNotAllowedToUse,
  showRoamingCallExplanation,
}) => {
  const { t } = useTranslation("portal");

  const title = t(
    "cockpit.managementTile.roaming.showOption.active.callsToAndFromAbroad",
  );

  return (
    <section className="mb-4">
      {showRoamingCallExplanation && !isNotAllowedToUse ? (
        <RoamingCallExplanation title={title} />
      ) : (
        <p className="text-base mb-4">{title}</p>
      )}

      <ProgressBar />
      <div className="text-base block mt-2 text-primary-100">
        {isNotAllowedToUse ? (
          <span>
            {t("cockpit.managementTile.roaming.showOption.active.notAllowed")}
          </span>
        ) : (
          <span>
            {t(
              "cockpit.managementTile.roaming.showOption.active.unlimitedCalls",
            )}
          </span>
        )}
      </div>
    </section>
  );
};
