import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SimCardDetail } from "@/services/model";

import { RegistrationDetails } from "./RegistrationDetails/RegistrationDetails";

export const UnscannedQRCodeDetails = ({
  simDetail,
}: {
  simDetail: SimCardDetail;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.details.sections.details",
  });
  const [showRegistrationDetails, setShowRegistrationDetails] = useState(false);

  return (
    <div data-testid="activate-esim" className="flex flex-col gap-6">
      <div className="flex flex-row gap-4">
        <div>
          <p className="font-semibold">{t("scanQRCode")}</p>
          <p className="text-sm">{t("scanQRCodeText")}</p>
          <p className="pt-2">
            <a
              className="text-[13px] text-primary-100 underline"
              href={t("portal:layout.tiles.qrCodeTile.instructionsLink")}
              target="_blank"
              rel="noreferrer"
            >
              {t("instructionsLinkText")}
            </a>
          </p>
          <p className="pt-2">
            <button
              data-testid="show-code-button"
              className="text-[13px] text-primary-100 underline"
              onClick={() =>
                setShowRegistrationDetails(!showRegistrationDetails)
              }
            >
              {showRegistrationDetails
                ? t("registrationDetails.hideCode")
                : t("registrationDetails.showCode")}
            </button>
          </p>
        </div>
        <div className="pt-2">
          <QRCodeSVG value={simDetail.matchingId || ""} size={120} />
        </div>
      </div>
      {showRegistrationDetails && (
        <RegistrationDetails matchingId={simDetail.matchingId || ""} />
      )}
    </div>
  );
};
