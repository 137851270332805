import "./UpsellTile.scss";

import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { ArrowRight } from "@/assets/icons/icons";
import { DeviceTile } from "@/components/Portal/Cockpit/Tiles/DeviceTile/DeviceTile";
import { useAllSims } from "@/hooks/useAllSims";
import { useCannotAddEsim } from "@/hooks/useCannotAddEsim";
import { mobileAbos } from "@/utils/constants";

import { FlatMobileUpsellCTA } from "./FlatMobileUpsellCTA";

export const UpsellTile: FCC = () => {
  const simCards = useAllSims();

  const hasNoFlatMobileYet = simCards.every(
    ({ serviceID }) => !mobileAbos.includes((serviceID || 0).toString()),
  );

  const cannotAddEsim = useCannotAddEsim();
  const upsellFlatMobile = hasNoFlatMobileYet && !cannotAddEsim;

  if (upsellFlatMobile) return <FlatMobileUpsellCTA />;

  return <NormalUpsellCTA />;
};

const NormalUpsellCTA = () => {
  const { t } = useTranslation();

  return (
    <a
      href={t("layout.tiles.upsellSim.shopURL")}
      target="_blank"
      rel="noreferrer"
    >
      <DeviceTile dataTestid="upsell-tile">
        <div className="upsell-wrapper">
          <p className="upsell-claim">
            {t("layout.tiles.upsellSim.mobileInternet")}
          </p>
          <h2 className="text-primary-100 mt-6">
            {t("layout.tiles.upsellSim.doYouNeedASim")}
          </h2>
        </div>
        <div className="button-wrapper">
          <button className="button">
            <span className="manage">{t("layout.tiles.upsellSim.toShop")}</span>
            <ArrowRight />
          </button>
        </div>
      </DeviceTile>
    </a>
  );
};
