import "./BookedRoamingOption.scss";

import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConnectionIcon, Costs, VoiceOptionCall } from "@/assets/icons/icons";
import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import { CountrylistDialog } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/AddRoamingOption/CountryList/CountryList";
import { RoamingUnlimited } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/RoamingUnlimited/RoamingUnlimited";
import { RoamingUsage } from "@/components/Portal/Cockpit/DeviceManagement/Roaming/ShowRoamingOptions/RoamingUsage/RoamingUsage";
import { useShouldDisplayRoamingHints } from "@/hooks/useShouldDisplayRoamingHints";
import {
  getSimRoamingCallPackageUsage,
  useGetVoiceSettings,
} from "@/services/api";
import {
  GetRoamingPackageUsageResponse,
  RoamingInfo,
  RoamingInfoRoamingStatus,
  SimCardDetail,
} from "@/services/model";
import { TOTAL_DAYS_IN_YEAR } from "@/utils/constants";
import {
  getTariffName,
  getTariffType,
  stripFlatName,
  TariffTypes,
} from "@/utils/deviceUtils";
import { getCallsVolume, isFlatMobileTariff } from "@/utils/tariffUtils";
import { getCurrency } from "@/utils/translationHelpers";

type BookedRoamingOptionProps = {
  index: number;
  isFirstOfUsageZone: boolean;
  roamingInfo: RoamingInfo;
  simDetail: SimCardDetail;
};

/**
 * This component displays an already booked roaming option.
 * It can be either active, ready or ordered.
 *
 * Active means roaming option is in use, the user is in the given country.
 * Ready means roaming option is waiting for the user to use it, but they are not in the country yet.
 * Ordered means that the option is still pending and not yet ready to be used.
 *
 * @param roaming - The RoamingInfo that this component displays
 * @param index - The index of the RoamingInfo inside the list of roaming options.
 */
export const BookedRoamingOption = ({
  index,
  isFirstOfUsageZone,
  roamingInfo,
  simDetail,
}: BookedRoamingOptionProps) => {
  const { t } = useTranslation("portal");
  const { shouldDisplayPartialHint, shouldDisplayRenewalHint } =
    useShouldDisplayRoamingHints({
      roamingInfo,
      serviceId: simDetail.serviceID,
    });
  const [roamingCallPackageUsage, setRoamingCallPackageUsage] =
    useState<GetRoamingPackageUsageResponse>();

  const { data: voiceSettings } = useGetVoiceSettings(
    {
      simId: simDetail.simId || -1,
    },
    {
      query: {
        enabled: true,
      },
    },
  );
  const isRoamingCallsUsageNotAllowed =
    voiceSettings?.reason?.includes("CreditClassC");

  const status = roamingInfo.roamingStatus;
  const tariffType = getTariffType(simDetail.serviceID);

  const isMobileAboRoaming = roamingInfo.includedInService;
  const isActive = status === RoamingInfoRoamingStatus.active;
  const isOrdered = status === RoamingInfoRoamingStatus.ordered;

  const isMobileOrMobilePlusTariff =
    tariffType === TariffTypes.flatMobile ||
    tariffType === TariffTypes.flatMobilePlus;

  const title = isMobileAboRoaming
    ? t("mobileAbo.name")
    : t("cockpit.managementTile.roaming.package", {
        index: index + 1,
      });

  const name = isMobileAboRoaming
    ? getTariffName(simDetail)
    : stripFlatName(roamingInfo.shortName);
  const flatName = isOrdered ? "-" : name;

  const isTravelPackage =
    !isFlatMobileTariff(name) || !roamingInfo.includedInService;

  const callsVolume = getCallsVolume({
    t,
    tariffType,
    minContractDuration: simDetail.minContractDuration,
  });
  const roamingVolume = isOrdered ? "-" : `${roamingInfo?.initialVolume} MB`;
  const roamingCosts = isMobileAboRoaming
    ? t("mobileAbo.included")
    : getCurrency(roamingInfo.charge || 0, "CHF");
  const roamingCostsText = isOrdered ? "-" : roamingCosts;

  useEffect(() => {
    if (tariffType === TariffTypes.flatMobile && simDetail.simId) {
      getSimRoamingCallPackageUsage({ simId: simDetail.simId }).then((data) => {
        setRoamingCallPackageUsage({
          roamingCalls: data.roamingCalls
            ? Math.ceil(data.roamingCalls / 60)
            : 0,
          maxInclRoamingCalls: data.maxInclRoamingCalls
            ? data.maxInclRoamingCalls / 60
            : 0,
        });
      });
    }
  }, [simDetail.simId, tariffType]);

  return (
    <div className="roaming-option" data-testid="roaming-option">
      <h3 className="title">{title}</h3>
      <div className="mb-14">
        <SectionButtonHeader
          className="mb-2 h-6"
          header={t("cockpit.managementTile.roaming.availableOptions")}
        >
          {(isActive || isMobileOrMobilePlusTariff) && <CountrylistDialog />}
        </SectionButtonHeader>

        <span
          className={clsx(
            "font-semibold",
            isActive || isMobileOrMobilePlusTariff
              ? "text-primary-100"
              : "text-primary-50",
          )}
        >
          {flatName}
        </span>

        <div
          className={clsx(
            "icon-wrapper",
            !(isActive || isMobileOrMobilePlusTariff) && "opacity-25",
          )}
        >
          <div className="icon-row">
            <ConnectionIcon />
            {roamingVolume}
          </div>

          <div className="icon-row">
            {isTravelPackage ? (
              <>
                <Costs />
                {roamingCostsText}
              </>
            ) : (
              <>
                <VoiceOptionCall className="size-6 text-primary-100" />
                {callsVolume}
              </>
            )}
          </div>
        </div>
      </div>
      <RoamingUsage
        showFlatMobileExplanation={roamingInfo.initialDays !== 365}
        days={roamingInfo.remainingDays || 0}
        description="cockpit.managementTile.roaming.showOption.active.remainingData"
        descriptionExtension={
          (shouldDisplayRenewalHint &&
            t(
              "cockpit.managementTile.roaming.showOption.flatMobileHint.renewalReminder",
            )) ||
          undefined
        }
        initial={roamingInfo.initialVolume || 0}
        isFirstOfUsageZone={isFirstOfUsageZone}
        remaining={roamingInfo.remainingVolume || 0}
        consumed={
          (roamingInfo.initialVolume || 0) - (roamingInfo.remainingVolume || 0)
        }
        isIncludedInService={roamingInfo.includedInService}
        roamingStatus={roamingInfo.roamingStatus}
        title={t("cockpit.managementTile.roaming.showOption.active.dataUsage")}
        isYearly={roamingInfo.initialDays === TOTAL_DAYS_IN_YEAR}
        isMobileOrMobilePlusTariff={isMobileOrMobilePlusTariff}
      />

      {isTravelPackage ? (
        <RoamingUsage
          days={roamingInfo.remainingDays || 0}
          description="cockpit.managementTile.roaming.showOption.active.runtimeUsage"
          initial={roamingInfo.initialDays || 0}
          remaining={roamingInfo.remainingDays || 0}
          roamingStatus={roamingInfo.roamingStatus}
          title={t("cockpit.managementTile.roaming.showOption.active.runtime")}
          isFirstOfUsageZone={isFirstOfUsageZone}
          isMobileOrMobilePlusTariff={isMobileOrMobilePlusTariff}
        />
      ) : (
        <>
          {tariffType === TariffTypes.flatMobilePlus ? (
            <RoamingUnlimited
              showRoamingCallExplanation={true}
              isNotAllowedToUse={isRoamingCallsUsageNotAllowed}
            />
          ) : (
            <RoamingUsage
              days={roamingInfo.remainingDays || 0}
              description="cockpit.managementTile.roaming.showOption.active.remainingMin"
              initial={roamingCallPackageUsage?.maxInclRoamingCalls || 0}
              remaining={
                (roamingCallPackageUsage?.maxInclRoamingCalls || 0) -
                (roamingCallPackageUsage?.roamingCalls || 0)
              }
              consumed={roamingCallPackageUsage?.roamingCalls}
              isIncludedInService={roamingInfo.includedInService}
              roamingStatus={roamingInfo.roamingStatus}
              title={t(
                "cockpit.managementTile.roaming.showOption.active.callsToAndFromAbroad",
              )}
              isFirstOfUsageZone={isFirstOfUsageZone}
              showRoamingCallExplanation={true}
              isNotAllowedToUse={isRoamingCallsUsageNotAllowed}
              isMobileOrMobilePlusTariff={isMobileOrMobilePlusTariff}
              isRoamingCallType={true}
            />
          )}
          {shouldDisplayPartialHint && (
            <p className="text-base mb-4">
              <b className="text-primary-100">
                {t(
                  "cockpit.managementTile.roaming.showOption.flatMobileHint.title",
                )}
              </b>
              <br />
              {t(
                "cockpit.managementTile.roaming.showOption.flatMobileHint.text",
              )}
            </p>
          )}
        </>
      )}
    </div>
  );
};
