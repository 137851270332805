import { useAtom } from "jotai";
import { Dispatch, SetStateAction, useMemo, useTransition } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import {
  BusinessPersonalDetailsFormFields,
  excludedFieldsPrivate,
  PersonalDetailsFormFieldKeys,
  PersonalDetailsFormFields,
} from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetailsFormFields/PersonalDetailsFormFields";
import { useUpdateCustomer } from "@/services/api";
import {
  BasicCustomerData,
  IdentificationStatusResponse,
  IdentificationStatusResponseKycStatus,
} from "@/services/model";
import { userAccountStateAtom } from "@/utils/atoms";
import {
  isBusinessCustomer,
  transformPersonalDetailsFormToBasicCustomerData,
} from "@/utils/customerUtils";
import { log } from "@/utils/log";

export const PersonalDataForm = ({
  localCustomerData,
  setLocalCustomerData,
  formData,
  identificationStatus,
  customer,
}: {
  localCustomerData?: BasicCustomerData;
  setLocalCustomerData: (data: BasicCustomerData) => void;
  formData: Omit<BusinessPersonalDetailsFormFields, "terms">;
  identificationStatus: IdentificationStatusResponse;
  customer: BasicCustomerData;
}) => {
  const [, setUserAccountState] = useAtom(userAccountStateAtom);
  const methods = useForm<BusinessPersonalDetailsFormFields>({
    mode: "onChange",
    defaultValues: formData,
  });
  const { mutateAsync: updateCustomer } = useUpdateCustomer();

  const saveData = (data: BusinessPersonalDetailsFormFields) => {
    if (localCustomerData?.birthday && data) {
      const postBody = transformPersonalDetailsFormToBasicCustomerData(
        data,
        localCustomerData,
      );

      updateCustomer({
        data: postBody,
      })
        .then(() => {
          setLocalCustomerData(postBody);
          setUserAccountState((prev) => ({
            ...prev,
            editingPersonalData: false,
          }));
        })
        .catch((error) => {
          if (`${error?.response?.data}`.includes("invalid phone number")) {
            methods.setError("phone", {
              message: t("label.validation.phoneBackend"),
              type: "backendError",
            });
          } else {
            log("Error in customer form: " + error);
          }
        });
    }
  };

  // Use memoization to ensure that the array is only recreated when the array elements actually change.
  // This is to avoid it being a new array instance on every render, which would cause re-renderings.
  const excludedFields = useMemo(
    () => [
      // Business data is not changeable by the customer
      ...excludedFieldsPrivate,
      // Exclude date of birth field for business customers,
      ...(customer && isBusinessCustomer(customer)
        ? [PersonalDetailsFormFieldKeys.dateOfBirth]
        : []),
    ],
    [customer],
  );

  const readOnlyFields =
    identificationStatus?.kycStatus ===
    IdentificationStatusResponseKycStatus.KYC_data_mismatch
      ? [PersonalDetailsFormFieldKeys.formOfAddress]
      : [
          PersonalDetailsFormFieldKeys.formOfAddress,
          PersonalDetailsFormFieldKeys.firstName,
          PersonalDetailsFormFieldKeys.lastName,
          PersonalDetailsFormFieldKeys.dateOfBirth,
        ];

  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(saveData)}>
        <div className="header-row mb-6">
          <h3 className="text-secondary-100 font-semibold">
            {t("Onboarding.sections.personal-details.title")}
          </h3>
        </div>
        <PersonalDetailsFormFields
          excludedFields={excludedFields}
          readonlyFields={readOnlyFields}
        />
        <HighlightPanel className="max-w-[360px] mt-14 mb-14">
          <p>
            <Trans
              components={[
                <a
                  key="0"
                  className="text-primary-100 underline"
                  href={t("translation:links.supportHomeLink")}
                  target="_blank"
                  rel="noreferrer"
                />,
              ]}
              i18nKey="Onboarding.sections.personal-details.disabledFieldsHint"
            />
          </p>
        </HighlightPanel>
        <div className="flex flex-col gap-4 mt-10 md:flex-row justify-between md:justify-end md:col-span-2">
          <Button
            onClick={() => {
              methods.reset();
              setUserAccountState((prev) => ({
                ...prev,
                editingPersonalData: false,
              }));
            }}
            className="accent w-full inverted"
          >
            {t("common.buttons.cancel")}
          </Button>
          <SubmitButton
            className="w-full accent"
            label={t("common.buttons.save")}
          />
        </div>
      </form>
    </FormProvider>
  );
};
