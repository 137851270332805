import { AxiosError } from "axios";
import { useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, SubmitButton } from "@/components/Interface/Button/Button";
import { ConfirmationButtonFlexBox } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { OrSeparator } from "@/components/Layout/OrSeparator";
import { AuthenticatorPhoneNumberField } from "@/components/Welcome/AuthenticatorSetup/AuthenticatorPhoneNumberField";
import { useAlert } from "@/hooks/useAlert";
import { NumberForm } from "@/hooks/useNumberForm";
import { useRequestTwoFactorPhoneNumber } from "@/hooks/useRequestTwoFactorPhoneNumber";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { useChangeTwoFactorAuthenticationSMSNumber } from "@/services/auth";
import { AlertTypes, phoneNumberAtom } from "@/utils/atoms";

import { TwoFactorSmsAlternatives } from "./TwoFactorSmsAlternatives";

/**
 * Displays current 2fa phone number, renders an input to change that number and shows alternative 2fa methods.
 */
export const TwoFactorSmsPhonenumberChange = () => {
  const navigate = useNavigate();
  const showToast = useAlert();
  const { t } = useTranslation();
  const {
    setValue,
    setError,
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<NumberForm>({
    defaultValues: {
      number: "",
    },
    mode: "onChange",
  });

  const setPhoneNumber = useSetAtom(phoneNumberAtom);
  const { mutateAsync: changePhoneNumber } =
    useChangeTwoFactorAuthenticationSMSNumber();

  const phoneNumber = useRequestTwoFactorPhoneNumber();

  const handlePhonenumerChangeRejection = (error: AxiosError<string>) => {
    // If we get a 403 error, we know that the phone number is invalid
    if (error.response?.data.includes("invalid phone number")) {
      setError("number", {
        type: "manual",
        message: t("translation:label.validation.phoneBackend"),
      });
    } else {
      showToast({
        text: t("portal:user-account.security.2fa.changePhonenumber.error"),
        type: AlertTypes.error,
      });
    }
  };

  const onSubmit = (data: NumberForm) => {
    changePhoneNumber({
      params: {
        phoneNumber: data.number,
      },
    })
      .then(() => {
        setPhoneNumber(data.number);
        navigate(portalRoutes.user.security.verify2faNumberChange);
      })
      .catch(handlePhonenumerChangeRejection);
  };

  const hasEnteredNewPhoneNumber = watch("number") !== "";

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 text-black"
      >
        <div className="flex flex-col gap-4">
          <h4 className="text-lg font-semibold">
            {t("portal:user-account.security.2fa.changePhonenumber.title")}
          </h4>
          <p>
            <Trans
              t={t}
              i18nKey="portal:user-account.security.2fa.changePhonenumber.text"
              values={{
                phoneNumber: phoneNumber?.replace(/^00/, "+"),
              }}
              components={[<span className="text-primary-100" key="0" />]}
            />
          </p>
        </div>
        <AuthenticatorPhoneNumberField register={register} errors={errors} />
        {hasEnteredNewPhoneNumber && (
          <ConfirmationButtonFlexBox forceButtonWidth>
            <Button
              type="button"
              className="inverted accent"
              onClick={() => setValue("number", "")}
            >
              {t("portal:common.buttons.back")}
            </Button>
            <SubmitButton
              label={t("portal:authenticator.sms.enterNumber.sendCode")}
              className="accent"
            />
          </ConfirmationButtonFlexBox>
        )}
      </form>
      {!hasEnteredNewPhoneNumber && (
        <>
          <OrSeparator />
          <TwoFactorSmsAlternatives />
        </>
      )}
    </>
  );
};
