import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { WelcomeLoadingSpinner } from "@/components/Welcome/WelcomeLoadingSpinner.tsx";
import { useShouldSkipAuthenticatorSetup } from "@/hooks/useShouldSkipAuthenticatorSetup.ts";
import { authenticatorRoutes } from "@/router/AuthenticatorRoutes.tsx";

export const RedirectToTwoFactorSetupLoader = ({
  children,
}: PropsWithChildren) => {
  const shouldSkip = useShouldSkipAuthenticatorSetup();
  if (shouldSkip === null) {
    return <WelcomeLoadingSpinner />;
  }
  if (!shouldSkip) {
    return <Navigate to={authenticatorRoutes.base} replace />;
  }

  return children;
};
