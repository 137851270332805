import "./ProgressBar.scss";

import { FCC } from "types";

type ProgressBarProps = {
  usageBarWidth?: number;
};

export const ProgressBar: FCC<ProgressBarProps> = ({ usageBarWidth }) => (
  <div id="bar-wrapper">
    <div id="background-bar" />
    {typeof usageBarWidth === "number" && (
      <div
        id="usage-bar"
        style={{ width: `${usageBarWidth}%`, maxWidth: "100%" }}
      />
    )}
  </div>
);
