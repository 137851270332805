/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type SubscriberOrderResponseSubscriberPackageType =
  (typeof SubscriberOrderResponseSubscriberPackageType)[keyof typeof SubscriberOrderResponseSubscriberPackageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriberOrderResponseSubscriberPackageType = {
  Data_CH: "Data_CH",
  Data_Roaming: "Data_Roaming",
  Watch_Sim: "Watch_Sim",
} as const;
