/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type IdentificationStatusResponseKycStatus =
  (typeof IdentificationStatusResponseKycStatus)[keyof typeof IdentificationStatusResponseKycStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentificationStatusResponseKycStatus = {
  OPEN: "OPEN",
  PENDING: "PENDING",
  OK: "OK",
  KYC_underaged: "KYC_underaged",
  KYC_data_mismatch: "KYC_data_mismatch",
  KYC_ID_uncomparable: "KYC_ID_uncomparable",
  KYC_invalid_document: "KYC_invalid_document",
  KYC_error_processing_docs: "KYC_error_processing_docs",
  KYC_error_processing_selfie: "KYC_error_processing_selfie",
} as const;
