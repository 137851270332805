/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type GetSimListDevicesItem =
  (typeof GetSimListDevicesItem)[keyof typeof GetSimListDevicesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSimListDevicesItem = {
  Smartphone: "Smartphone",
  Tablet: "Tablet",
  Laptop: "Laptop",
  Router: "Router",
  Mobile_Hotspot: "Mobile_Hotspot",
  GPS_Tracker: "GPS_Tracker",
  Wearable: "Wearable",
  Camera: "Camera",
  IoT_Device: "IoT_Device",
  Vehicle: "Vehicle",
  Andere: "Andere",
} as const;
