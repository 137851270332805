import "@/components/Layout/Table/TableSortButton/TableSortButton.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FCC } from "types";

import ArrowRight from "@/assets/icons/ArrowRight.svg?react";

interface Props {
  title: string;
  sortField: string;
}

export const TableSortButton: FCC<Props> = ({ title, sortField }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentlyActiveSortField = searchParams.get("sortField");
  const sortFieldIsActive = currentlyActiveSortField === sortField;
  const currentlyActiveSortDirection = searchParams.get("sortDirection");

  const setSortField = (sortField: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (sortFieldIsActive) {
      if (currentlyActiveSortDirection === "asc") {
        updatedSearchParams.set("sortDirection", "desc");
      }
      if (currentlyActiveSortDirection === "desc") {
        updatedSearchParams.delete("sortField");
        updatedSearchParams.delete("sortDirection");
      }
    } else {
      updatedSearchParams.set("sortField", sortField);
      updatedSearchParams.set("sortDirection", "asc");
    }
    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <button
      aria-label={t("layout.table.sortBy", { title })}
      title={t("layout.table.sortBy", { title })}
      className="sortable"
      onClick={() => setSortField(sortField)}
    >
      {title}
      <ArrowRight
        className={`arrow ${
          sortFieldIsActive
            ? `${
                currentlyActiveSortDirection === "desc"
                  ? "rotate-90"
                  : "-rotate-90"
              }`
            : "hidden"
        }`}
      />
    </button>
  );
};
