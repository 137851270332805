import "./ChangeWatchSimRenewal.scss";

import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { HighlightPanel } from "@/components/Interface/HighlightPanel/HighlightPanel";
import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { DueNowLightInterface } from "@/components/Layout/DueNowLight/DueNowLightInterface";
import { DueRecurringCard } from "@/components/Layout/DueRecurringCard/DueRecurringCard";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { useAlert } from "@/hooks/useAlert";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { useSetAutoRenew } from "@/services/api";
import { AlertTypes } from "@/utils/atoms";
import {
  getBaseFlatName,
  getDeviceDescription,
  getFlatNameFromDevice,
  getIconForDevice,
  hasSimAutoRenewal,
  hasWatchSimAutoRenewal,
} from "@/utils/deviceUtils";

export const ChangeWatchSimRenewal: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "cockpit.managementTile.watchSim.autoRenewal",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const showAlert = useAlert();

  const [simId] = useSimIdFromQueryParam();

  const { data: simDetail, isLoading } = useDisplayedSim();
  const { mutate: sendSetAutoRenew, isLoading: isLoadingSetAutoRenew } =
    useSetAutoRenew({
      mutation: {
        onSuccess: () => {
          // Added in order to trigger a refetching in the DeviceManagement
          queryClient.invalidateQueries(["/sim/sim"]).then(() => {
            goToWatchSimDisplay();
            showAlert({
              type: AlertTypes.success,
              text: t(`${enableOrDisableKey}.successMessage`),
            });
          });
        },
      },
    });

  // simDetail should always be present and only here to prevent TypeScript warnings.
  if (isLoading || !simDetail) return <LoadingSpinner />;

  const isEnableScreen = !hasWatchSimAutoRenewal(simDetail);
  const willEnableMainSimAutoRenewal =
    isEnableScreen && !hasSimAutoRenewal(simDetail);
  const flatName = getBaseFlatName(simDetail);
  const deviceIcon = getIconForDevice(
    getDeviceDescription(simDetail?.device?.description),
  );
  const enableOrDisableKey = isEnableScreen ? "enable" : "disable";

  const goToWatchSimDisplay = () =>
    navigate(`../display?simId=${simDetail?.simId}`, { replace: true });

  const toggleWatchSimAR = () => {
    sendSetAutoRenew({
      simId: willEnableMainSimAutoRenewal
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          simDetail.simId!
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          simDetail.watchSim!.watchId!,
      params: { autoRenew: isEnableScreen },
    });
  };

  return (
    <div
      className="change-watch-sim-renewal"
      data-testid="change-watch-sim-renewal"
    >
      <section className={"change-watch-sim-renewal-summary mb-2"}>
        <div className="renewal-info max-w-[455px]">
          <h3 className="text-secondary-100 font-semibold mb-6">
            {t(`${enableOrDisableKey}.title`)}
          </h3>

          <p className="mb-8">
            <Trans
              t={t}
              values={{
                count: isEnableScreen
                  ? simDetail.minContractDuration
                  : simDetail.remainingDays,
              }}
            >
              {`${enableOrDisableKey}.description`}
            </Trans>
          </p>

          <span className="block font-semibold text-primary-100 mb-16">
            {isEnableScreen
              ? getFlatNameFromDevice(simDetail, false)
              : t("portal:common.device.attributes.watchSim")}
          </span>

          <HighlightPanel className={clsx(isEnableScreen && "mb-16")}>
            {isEnableScreen
              ? willEnableMainSimAutoRenewal
                ? t("enable.goodToKnowText.withMainSimAR")
                : t("enable.goodToKnowText.onlyWatchSimAR")
              : t("disable.goodToKnowText")}
          </HighlightPanel>
        </div>

        {isEnableScreen && (
          <div className="renewal-billing flex flex-col gap-8" id="due-cards">
            <DueNowLightInterface
              totalOnce={0}
              currency="CHF"
              icon={deviceIcon}
            />
            <DueRecurringCard
              remainingDays={simDetail.remainingDays}
              totalReoccurring={simDetail.priceTotal ?? -1}
              // TODO: fix discount - wait for backend
              subtotalRecurring={simDetail.priceTotal}
              discountRecurring={
                (simDetail.priceTotal || 0) - (simDetail.priceDiscounted || 0)
              }
              currency="CHF"
              flatName={flatName}
              billingPeriod={simDetail.minContractDuration ?? 30}
              flatPrice={simDetail.priceBase ?? -1}
              flatOptionPrice={simDetail.priceVoice}
              flatOption={t("portal:common.device.attributes.voiceOption")}
              watchSimName={t("portal:common.device.attributes.watchSim")}
              watchSimPrice={simDetail.priceWatch}
            />
          </div>
        )}
      </section>

      <ConfirmationButtonGroup
        disableSuccessButton={isLoadingSetAutoRenew}
        successText={t("portal:common.buttons.confirm")}
        successAction={toggleWatchSimAR}
        cancelText={t("portal:common.buttons.cancel")}
        cancelAction={goToWatchSimDisplay}
      />
    </div>
  );
};
