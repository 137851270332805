import { Navigate, Outlet, Route } from "react-router-dom";

import { App } from "@/App";
import { AgentLogin } from "@/components/AgentLogin.tsx";
import { WelcomeLoadingSpinner } from "@/components/Welcome/WelcomeLoadingSpinner";
import { onboardingRoutes as onboardingRouteComponents } from "@/router/OnboardingRoutes";
import { portalRouter as portalRouteComponents } from "@/router/PortalRoutes";
import { RedirectToTwoFactorSetupLoader } from "@/router/RedirectToTwoFactorSetupLoader.tsx";
import { PORTAL_BASE_ROUTE } from "@/router/routes/portalRoutes";
import { NotFound } from "@/views/NotFound/NotFound";
import { Onboarding } from "@/views/Onboarding/Onboarding";
import { Portal } from "@/views/Portal/Portal";
import { Welcome } from "@/views/Welcome";
import { WelcomeEsim } from "@/views/WelcomeEsim";

import { authenticatorRouteComponents } from "./AuthenticatorRoutes";
import { LoginRedirect } from "./LoginRedirect";
import { ProtectedRoute } from "./ProtectedRoute";
import redirects from "./redirects";

const redirectRoutes = redirects.map((redirect) => (
  <Route
    key={redirect.from}
    path={redirect.from}
    element={<Navigate to={redirect.to} replace />}
  />
));
export const routes = [
  <Route
    key="baseRoute"
    path="/"
    element={
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    }
  >
    <Route index element={<Navigate to="/onboarding/1" replace />} />
    <Route path="onboarding/*" element={<Onboarding />}>
      {onboardingRouteComponents}
    </Route>
    <Route
      path={PORTAL_BASE_ROUTE}
      element={
        <RedirectToTwoFactorSetupLoader>
          <Portal />
        </RedirectToTwoFactorSetupLoader>
      }
    >
      {portalRouteComponents}
    </Route>
  </Route>,
  ...authenticatorRouteComponents,
  <Route
    key="404-catchall"
    path="*"
    element={<Navigate to="/404" replace />}
  />,

  <Route key="404" path="/404" element={<NotFound />} />,
  <Route key="agentlogin" path="/agentLogin" element={<AgentLogin />} />,
  <Route
    key="welcome-base"
    element={
      <LoginRedirect>
        <Outlet />
      </LoginRedirect>
    }
  >
    <Route key="welcome" path="/welcome" element={<Welcome />} />
    <Route key="welcome-esim" path="/welcome-esim" element={<WelcomeEsim />} />
    <Route
      key="welcome-loading-spinner"
      path="/welcome/loading"
      element={<WelcomeLoadingSpinner />}
    />
  </Route>,
  ...redirectRoutes,
];
