import { useDisplayedSim } from "@/components/Portal/Cockpit/DeviceManagement/useDisplayedSim";
import { RemainingTime } from "@/components/Portal/Cockpit/RemainingTime/RemainingTime";
import { SimCardDetailSimStatusReason } from "@/services/model";

export const AutomaticRenewalBarGraph = ({
  hasLockRequested,
}: {
  hasLockRequested?: boolean;
}) => {
  const { data: simDetail } = useDisplayedSim();

  // If the current sim has a watch sim, we need to compare if the successor will still have it
  // as the serviceID's will not be different
  const willNotHaveWatchSimAfterAR =
    simDetail?.localFlatSpeed?.includes("Watch") &&
    !simDetail.successorForRenew?.description?.includes("Watch");

  const hasNecessaryAttributes =
    simDetail !== undefined &&
    simDetail.autoRenew !== undefined &&
    simDetail.remainingDays !== undefined &&
    simDetail.minContractDuration !== undefined;

  if (
    hasNecessaryAttributes ||
    simDetail?.simStatusReason === SimCardDetailSimStatusReason.LOCK_REQUESTED
  ) {
    return (
      <RemainingTime
        className="h-fit mb-2"
        totalTime={simDetail.minContractDuration ?? 0}
        remainingTime={simDetail.remainingDays ?? 0}
        hasRenewal={simDetail.autoRenew ?? false}
        isPending={simDetail.servicePending}
        successorForRenew={
          simDetail.successorForRenew?.id !== simDetail.serviceID ||
          willNotHaveWatchSimAfterAR
            ? simDetail.successorForRenew
            : undefined
        }
        hasLockRequested={hasLockRequested}
      />
    );
  }

  return null;
};
