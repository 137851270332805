import { useTranslation } from "react-i18next";

import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { useGetSim } from "@/services/api";
import { SimCardDetailSimStatus } from "@/services/model/simCardDetailSimStatus";

export const useDisplayedSim = () => {
  const [simId] = useSimIdFromQueryParam();
  const { t } = useTranslation();

  return useGetSim(
    { simId: parseInt(simId) },
    {
      query: {
        placeholderData: {
          alias: t("common.device.placeholder.alias"),
          simStatus: SimCardDetailSimStatus.green,
        },
        enabled: !isNaN(parseInt(simId)),
        staleTime: 15 * 1000,
        refetchOnMount: true,
      },
    },
  );
};
