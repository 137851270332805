import "./TabWithDisableHint.scss";

import { Popover } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FCC } from "types";

type TabWithDisableHintProps = {
  target: string;
  showHint: boolean;
  disabled: boolean;
};

export const TabWithDisableHint: FCC<TabWithDisableHintProps> = ({
  target,
  showHint,
  disabled,
  children,
}) => {
  const { t } = useTranslation("portal");

  return disabled && showHint ? (
    <Popover id="popover">
      <Popover.Button className="opacity-25">{children}</Popover.Button>
      <Popover.Panel className="disabled-tab-hint-panel">
        <p className="text-primary-100 font-semibold">
          {t("cockpit.managementTile.tabs.disabledHint.inactive.title")}
        </p>
        <p>{t("cockpit.managementTile.tabs.disabledHint.inactive.text")}</p>
      </Popover.Panel>
    </Popover>
  ) : disabled ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a role="link" aria-disabled="true" className="tablink disabled">
      {children}
    </a>
  ) : (
    <NavLink className="tablink" to={target} replace>
      {children}
    </NavLink>
  );
};
