import "./index.scss";

import { Provider } from "jotai";
import React from "react";
import { createRoot } from "react-dom/client";

import { DataContextProvider } from "@/provider/DataContextProvider";
import { applyTheme } from "@/themes/themeUtils";
import { store } from "@/utils/atoms";
import { initTranslation } from "@/utils/i18n";
import { initializeSentry } from "@/utils/initializeSentry";

import { Main } from "./Main.tsx";
import { DEFAULT_THEME, themes } from "./themes";
import { env } from "./utils/environmentHelpers.ts";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  if (import.meta.env.MODE === "mocked") {
    console.log("Enabling mocking");
    const { worker } = await import("./mocks/msw");

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
  }

  return;
}

if (env.isProd) {
  initializeSentry();
}

// See if we need to authenticate by looking for a code search param...
const searchMatch = window.location.search.match(/^\?code=([a-zA-Z0-9]*)$/);
// ...if we found the code search param, replace the current location and move the token into the react hash router context
if (searchMatch && searchMatch?.length > 1) {
  window.location.replace(
    window.location.origin +
      window.location.pathname +
      (window.location.hash || "#/welcome/loading") +
      window.location.search,
  );
}
// Otherwise, we can safely proceed to render the app
else {
  initTranslation();
  applyTheme(themes[DEFAULT_THEME]);
  enableMocking().then(() =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createRoot(document.getElementById("root")!).render(
      <Provider unstable_createStore={() => store}>
        <DataContextProvider>
          <Main />
        </DataContextProvider>
      </Provider>,
    ),
  );
}
