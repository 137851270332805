import "@/components/Portal/Cockpit/DeviceManagement/DeviceManagement.scss";
import "./UserAccount.scss";

import clsx from "clsx";
import { useAtom } from "jotai";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router-dom";

import { Tablist } from "@/components/Interface/Tablist/Tablist";
import { PortalHeading } from "@/components/Portal/PortalHeading/PortalHeading";
import { useQueryParam } from "@/hooks/useQueryParam";
import { portalRoutes } from "@/router/routes/portalRoutes";
import { useGetCustomer } from "@/services/api";
import {
  userAccountStateAtom,
  userAccountStateAtomInitialState,
} from "@/utils/atoms";

import { UserSettingsIconBackground } from "./UserSettingsIconBackground/UserSettingsIconBackground";

export const UserAccountTitle: FC = () => {
  const { data: customer } = useGetCustomer();
  const customerName = clsx(customer?.firstName, customer?.lastName) || "User";

  const companyName1 = customer?.companyName;
  const companyName2 = customer?.companyName2;
  const companyFullName = clsx(companyName1, companyName2) || "Hi Company";

  // customerType id: 1 = private customer
  // customerType id: 2 = company customer
  const customerTypeID = customer?.customerType?.id;
  const name = customerTypeID === 1 ? customerName : companyFullName;

  return (
    <div className="title-wrapper">
      <h2 data-testid="tile-title" className="text-primary-100 text-3xl">
        {name}
      </h2>
    </div>
  );
};

export const UserAccount: FC = () => {
  const [mode] = useQueryParam("mode");

  const { t } = useTranslation();

  const [userAccountState, setUserAccountState] = useAtom(userAccountStateAtom);

  // Reset the userAccountState when the component mounts or unmounts
  useEffect(() => {
    setUserAccountState(userAccountStateAtomInitialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditingPersonal =
    userAccountState.editingPersonalData ||
    userAccountState.editingMail ||
    userAccountState.editingPassword;

  const isEditingPayment =
    userAccountState.editingPayment || userAccountState.editingBillingInfo;

  const isChangingInboxSetting = mode === "edit";

  const securityMatch = useMatch(portalRoutes.user.security.base + "/*");
  const isInSecurityChange =
    securityMatch?.pathnameBase !== securityMatch?.pathname;
  const isEditingSecurity =
    userAccountState.editingSecurity || isInSecurityChange;

  const isPersonalDisabled =
    isChangingInboxSetting || isEditingPayment || isEditingSecurity;
  const isPaymentsDisabled =
    isEditingPersonal || isChangingInboxSetting || isEditingSecurity;
  const isInboxDisabled =
    isEditingPersonal || isEditingPayment || isEditingSecurity;
  const isSecurityDisabled =
    isEditingPersonal || isEditingPayment || isChangingInboxSetting;

  return (
    <>
      <PortalHeading heading={t("portal:user-account.title")}>
        <p className="mb-14 mt-8 md:mt-10">
          {t("portal:user-account.subheading")}
        </p>
      </PortalHeading>
      <UserSettingsIconBackground mobile />
      <div className="tile-width-wrapper" id="user-wrapper">
        <div className="management-tile active !pt-0 md:!pt-8">
          <UserSettingsIconBackground />
          <div className="content-container">
            <div id="device-management-view">
              <UserAccountTitle />
              <Tablist
                tabs={[
                  {
                    target: "personal",
                    disabled: isPersonalDisabled,
                    showHint: false,
                    text: t("portal:user-account.tabs.personalDetails"),
                  },
                  {
                    target: "security",
                    disabled: isSecurityDisabled,
                    showHint: false,
                    text: t("portal:user-account.tabs.security"),
                  },
                  {
                    target: `payments`,
                    disabled: isPaymentsDisabled,
                    showHint: false,
                    text: t("portal:user-account.tabs.paymentMethods"),
                  },
                  {
                    target: `inbox`,
                    disabled: isInboxDisabled,
                    showHint: false,
                    text: t("portal:user-account.tabs.inbox"),
                  },
                ]}
              />
              <div id="useraccount-container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
