import { useContext, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { Accordion } from "@/components/Interface/Accordion/Accordion";
import { DataContext } from "@/provider/DataContextProvider";
import { GetSimListDevicesItem } from "@/services/model";
import { pushProductImpression } from "@/utils/analytics/onboardingAnalytics";
import { getEntry } from "@/utils/dataContextHelpers";
import {
  deviceNameToValueMap,
  getDeviceDescription,
} from "@/utils/deviceUtils";
import { TransformedProduct, TransformedProducts } from "@/utils/tariffUtils";

import { Rates } from "./Rates/Rates";
import { RateFormProps } from "./RateSelection";
import { VoiceOption } from "./VoiceOption/VoiceOption";

/**
 * This component is a form that allows the user to select a custom rate and voice option.
 * It is used in the rate selection step of the onboarding process and is the alternative to picking one of the "Mobile Abo" products.
 */
export const CustomRateForm = ({
  options,
  selectedOption,
  currentRate,
  shouldShowEsimTrial,
  canBookVoiceOption,
  currentVoiceOption,
  voiceIsSelected,
  showBestsellerTabs,
  isBestsellerTabsLinkDisabled,
}: {
  options: TransformedProducts | undefined;
  selectedOption: TransformedProduct | undefined;
  rateFormMethods: UseFormReturn<RateFormProps, any, RateFormProps>;
  currentRate: string;
  shouldShowEsimTrial: boolean;
  canBookVoiceOption: boolean;
  currentVoiceOption?: TransformedProduct;
  voiceIsSelected: boolean;
  showBestsellerTabs: () => void;
  isBestsellerTabsLinkDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { preSelectedProcessedProduct, prechargeVoice, processedProductsById } =
    options || {};
  const isFlat2000 = selectedOption?.baseDisplayValue?.includes("2000");

  useEffect(() => {
    pushProductImpression(
      Object.values(options?.processedProductsById || []),
      "rate-selection",
    );
    // We want to push the product impression only once, so we don't need to run this effect again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { dataContext, setDataContext } = useContext(DataContext);

  const entry = getEntry(dataContext);
  const device = getDeviceDescription(entry?.device?.description);

  const isRouterOrHotspot =
    deviceNameToValueMap[device] === GetSimListDevicesItem.Router ||
    deviceNameToValueMap[device] === GetSimListDevicesItem.Mobile_Hotspot;

  return (
    <>
      <Accordion
        label={t("Onboarding.sections.rate-selection.yourRate")}
        className="mt-10"
      >
        {processedProductsById && (
          <Rates
            processedProductsById={processedProductsById}
            preSelectedProcessedProduct={preSelectedProcessedProduct}
            currentRate={currentRate}
            shouldShowEsimTrial={shouldShowEsimTrial}
          />
        )}
        {isFlat2000 && (
          <p className="flat-2000-hint">
            {t("translation:Onboarding.sections.rate-selection.flat2000Hint")}
          </p>
        )}
      </Accordion>
      {preSelectedProcessedProduct?.precharged && (
        <p className="font-x-small text-primary-100">
          {t(
            "translation:Onboarding.sections.rate-selection.prechargedRateHint",
          )}
        </p>
      )}
      {!isRouterOrHotspot && !isBestsellerTabsLinkDisabled && (
        <div className="w-full mt-8 text-center">
          <button
            className="text-primary-75 text-md underline"
            onClick={showBestsellerTabs}
          >
            {t("portal:mobileAbo.toMobileAbos")}
          </button>
        </div>
      )}
      {canBookVoiceOption && (
        <>
          <Accordion
            label={t("Onboarding.sections.rate-selection.unlimitedCalltime")}
            className="mt-10"
          >
            <VoiceOption
              prechargeVoice={prechargeVoice || false}
              currentVoice={currentVoiceOption}
              hasFreeTrial={shouldShowEsimTrial}
            />
            {!voiceIsSelected && (
              <p className="mt-4 font-x-small leading-6 text-secondary-100 md:mt-2">
                <b>{t("Onboarding.sections.rate-selection.hint")}</b>
                <Trans
                  t={t}
                  i18nKey="Onboarding.sections.rate-selection.numberPortingDisabledExplanation"
                  components={[<b key={1} />]}
                />
              </p>
            )}
          </Accordion>
        </>
      )}
    </>
  );
};
