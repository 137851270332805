import React, { createContext, useEffect, useState } from "react";
import { FCC } from "types";

import { FinalFormProps } from "@/components/Onboarding/Steps/Checkout/Checkout";
import { NumberPortingFormProps } from "@/components/Onboarding/Steps/NumberPorting/NumberPorting";
import { BusinessPersonalDetailsFormFields } from "@/components/Onboarding/Steps/PersonalDetails/PersonalDetailsFormFields/PersonalDetailsFormFields";
import { SimVerificationFormProps } from "@/components/Onboarding/Steps/SimVerification/SimVerification";
import { safeJsonParse } from "@/hooks/useToken";
import { Basket } from "@/services/model";
import { TransformedProduct } from "@/utils/tariffUtils";

// In this file, we provide a global read-only state for the received api values.
// Other DeviceTile can use this provided data by accessing the exported DataContext.

export type DataContextInterface = {
  basket?: Basket;
  personalDetails?: BusinessPersonalDetailsFormFields;
  selectedOption?: TransformedProduct;
  numberPorting?: NumberPortingFormProps;
  checkout?: { reoccuringPayment: FinalFormProps["reoccuringPayment"] };
  simVerification?: SimVerificationFormProps;
  allowedMaxOnboardingStep?: number;
};

/**
 * Type of our global data context.
 */
export type DataContextType = {
  dataContext: DataContextInterface;
  setDataContext: React.Dispatch<React.SetStateAction<DataContextInterface>>;
};

/**
 * The initializer of our global context.
 * Tries to get the dataContext from localStorage,
 * or defaults to an empty object with token set to empty strings.
 */
export const initialContext: () => DataContextInterface = () => {
  const contextString = localStorage.getItem("dataContext") || "{}";
  const [err, context] = safeJsonParse(contextString);

  return err === null ? context : { allowedMaxOnboardingStep: 1 };
};

/**
 * Clear the written context from localStorage.
 */
export const clearContext = () => {
  localStorage.removeItem("dataContext");
};

/**
 * Initial values for the Data Context, only used here to create the context below.
 * The setter needs to be overwritten in ../ProtectedRoute/ProtectedRoute.tsx
 */
const dataContextDefaultValue: DataContextType = {
  dataContext: initialContext(),
  setDataContext: () => undefined,
};
export const DataContext = createContext<DataContextType>({
  dataContext: dataContextDefaultValue.dataContext,
  setDataContext: dataContextDefaultValue.setDataContext,
});

export const DataContextProvider: FCC = ({ children }) => {
  const [stateMemory, setStateMemory] =
    useState<DataContextInterface>(initialContext());

  // When our dataContext changes, we persist it to localStorage here.
  useEffect(() => {
    localStorage.setItem("dataContext", JSON.stringify(stateMemory));
  }, [stateMemory]);

  return (
    <DataContext.Provider
      value={{ dataContext: stateMemory, setDataContext: setStateMemory }}
    >
      {children}
    </DataContext.Provider>
  );
};
