import clsx from "clsx";
import { Namespace, TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { SimDetailsAccordion } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/SimDetailsAccordion";
import { SimPricingDetails } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffDisplay/SimPricingDetails";
import { TariffHeader } from "@/components/Portal/Cockpit/DeviceManagement/Tariff/TariffHeader/TariffHeader";
import { SimCardDetail } from "@/services/model";
import {
  getBaseFlatName,
  getTariffType,
  TariffTypes,
} from "@/utils/deviceUtils";
import {
  calculateDiscounts,
  getIncludedFeaturesCostDetail,
  getNextPaymentDate,
} from "@/utils/tariffUtils";
import { getDateString } from "@/utils/translationHelpers";

export const FlatMobileCostDetails = ({
  simDetail,
  isDowngrade,
}: {
  simDetail: SimCardDetail;
  isDowngrade: boolean;
}) => {
  const { t } = useTranslation();
  const price = simDetail.priceBase;
  const priceTotal = simDetail.priceDiscounted || 0;

  const oneTimeDiscounts = calculateDiscounts(simDetail.localDiscounts);
  const recurringDiscounts = calculateDiscounts(
    simDetail.successorForRenew?.successorDiscounts,
  );

  const autoRenewText = simDetail.autoRenew
    ? simDetail?.successorForRenew?.minContractDuration
      ? t("cockpit.managementTile.tariff.payInterval", {
          days: simDetail.minContractDuration || 30,
        })
      : ""
    : t("cockpit.managementTile.tariff.once");

  if (!price) return null;

  const payInterval =
    simDetail.minContractDuration === 30
      ? t("portal:cockpit.managementTile.tariff.payInterval", { days: 30 })
      : t("portal:cockpit.managementTile.tariff.payInterval", { days: 365 });

  return (
    <div>
      <TariffHeader
        className="mb-6"
        title={t("portal:cockpit.managementTile.tariff.currentCosts")}
      />
      <SimPricingDetails
        productName={getBaseFlatName(simDetail)}
        basePrice={price}
        discounts={oneTimeDiscounts}
        voicePrice={simDetail.priceVoice}
        watchSimPrice={simDetail.priceWatch}
        totalPrice={priceTotal}
        includedFeatures={getIncludedFeaturesCostDetail(
          t,
          getTariffType(simDetail.serviceID),
          simDetail.minContractDuration || 30,
        )}
      />
      <div className="text-right text-base" data-testid="pay-interval-label">
        {payInterval}
      </div>

      {simDetail.autoRenew && isDowngrade && (
        <SimDetailsAccordion
          content={
            <>
              <TariffHeader
                className={clsx(isDowngrade ? "mb-2" : "mb-6")}
                title={t("cockpit.managementTile.tariff.subsequentCosts")}
              />
              <p className="mb-6">
                {typeof simDetail.remainingDays === "number"
                  ? `${t("cockpit.managementTile.tariff.starting")} ${getDateString(
                      getNextPaymentDate(simDetail.remainingDays + 1),
                    )}`
                  : ""}
              </p>

              <SimPricingDetails
                productName={getBaseFlatName(simDetail)}
                basePrice={price}
                discounts={recurringDiscounts}
                voicePrice={simDetail.successorForRenew?.priceVoice}
                watchSimPrice={simDetail.successorForRenew?.priceWatch}
                totalPrice={simDetail.successorForRenew?.priceDiscounted || 0}
                includedFeatures={getIncludedFeaturesCostDetail(
                  t,
                  getTariffType(simDetail.serviceID),
                  simDetail.minContractDuration || 30,
                )}
              />

              <div
                className="text-right text-base"
                data-testid="pay-interval-label"
              >
                {autoRenewText}
              </div>
            </>
          }
          textOpen={t(
            "portal:cockpit.managementTile.tariff.showSubsequentCosts",
          )}
          textClose={t(
            "portal:cockpit.managementTile.tariff.hideSubsequentCosts",
          )}
        />
      )}
    </div>
  );
};
