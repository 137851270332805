import "./MobileMenu.scss";

import { Popover, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import Logo from "@/assets/header/Logo.svg?react";
import { BurgerMenu, Close, Exit } from "@/assets/icons/icons";
import { MenuItem } from "@/components/Layout/MenuItem/MenuItem";
import { menuItems } from "@/components/Portal/PortalSidemenu/PortalSidemenu";
import { useLogout } from "@/hooks/useLogout";

export const MobileMenu = () => {
  const { t } = useTranslation();
  const logout = useLogout();

  return (
    <Popover id="mobile-menu">
      {({ open, close }) => (
        <>
          <div className="z-10 absolute top-6 right-6">
            <Popover.Button aria-label="Navigation menu" className="w-8">
              {open ? <Close className="text-white" /> : <BurgerMenu />}
              <span className="sr-only">Navigation menu</span>
            </Popover.Button>
          </div>

          <Transition show={open}>
            <Transition.Child
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-25"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-25"
              leaveTo="opacity-0"
            >
              <div id="background-layer" />
            </Transition.Child>

            <Transition.Child
              className="transition-transform"
              enter="ease-in duration-500"
              enterFrom="-translate-y-[800px]"
              enterTo="translate-y-0"
              leave="ease-out duration-500"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-[800px]"
            >
              <Popover.Panel className="absolute z-10 inset-x-0">
                <div
                  id="nav-wrapper"
                  data-testid="nav-wrapper"
                  onClick={(e) => {
                    if (e.currentTarget === e.target) close();
                  }}
                >
                  <nav id="mobile-nav" aria-labelledby="navigation-heading">
                    <div id="logo-wrapper">
                      <Logo />
                    </div>
                    <h1 id="navigation-heading">Navigation</h1>
                    <div id="mobile-menu-items">
                      <ul className="mt-5">
                        {menuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            icon={item.icon}
                            text={t(item.text)}
                            target={item.target}
                            onClick={() => close()}
                          />
                        ))}
                      </ul>
                    </div>
                    <hr className="border-highlight-100 border-t-1" />

                    <div className="logout-wrapper">
                      <MenuItem
                        icon={<Exit />}
                        target="."
                        text={t("layout.header.menu.logout")}
                        onClick={() => {
                          logout();
                          close();
                        }}
                      />
                    </div>
                  </nav>
                </div>
              </Popover.Panel>
            </Transition.Child>
          </Transition>
        </>
      )}
    </Popover>
  );
};
