import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { DueNow } from "@/components/Layout/DueNow/DueNow";
import {
  useBillingPeriod,
  useGetFlatPricesAndName,
  useGetIconForDevice,
  useHasVoiceOption,
} from "@/hooks/useDataContextHooks";
import { useGetCampaignEndDate } from "@/hooks/useGetCampaignEndDate";
import { DataContext } from "@/provider/DataContextProvider";
import { getCurrencyFromLookupValue } from "@/utils/translationHelpers";

/** Renders the payment due now box of the checkout page */
export const OnboardingDueNow: FCC = () => {
  const { dataContext } = useContext(DataContext);

  const { t } = useTranslation();
  const currency = getCurrencyFromLookupValue(
    dataContext.basket?.currency || { id: 1 },
  );

  const hasVoiceOption = useHasVoiceOption();

  // flat name and flat price
  const {
    flatName,
    flatPrice,
    voicePrice,
    onceTotal,
    onceSubtotal,
    discountAmount,
  } = useGetFlatPricesAndName();
  const { campaignEndDate } = useGetCampaignEndDate(dataContext.basket);

  const credit = Math.abs(dataContext.basket?.balance || 0);
  const creditTimeRemaining =
    dataContext.basket?.total?.oneTime?.amountDiscounts || 0;

  const billingPeriod = useBillingPeriod();
  const billingPeriodText = t(
    "Onboarding.sections.checkout.summary.billingPeriod",
    {
      days: billingPeriod,
    },
  );

  const icon = useGetIconForDevice();

  return (
    <DueNow
      icon={icon}
      billingPeriod={billingPeriodText}
      dataRateName={flatName}
      // TODO: add specific lineItem for FreeTrial discount
      dataRatePrice={onceTotal <= 0 ? 0 : flatPrice}
      currency={currency}
      totalOnce={onceTotal - credit}
      subtotalOnce={onceSubtotal}
      discountAmount={discountAmount}
      discountEndDate={campaignEndDate}
      credit={credit}
      creditTimeRemaining={creditTimeRemaining}
      {...(hasVoiceOption
        ? {
            voiceOptionName: t(
              "Onboarding.sections.checkout.summary.voiceOption",
            ),
            voiceOptionPrice: onceTotal <= 0 ? 0 : voicePrice,
          }
        : {})}
    />
  );
};
