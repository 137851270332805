import "./Summary.scss";

import clsx from "clsx";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SectionButtonHeader } from "@/components/Interface/SectionButtonHeader/SectionButtonHeader";
import {
  useGetFlatPricesAndName,
  useIsMobileAbo,
} from "@/hooks/useDataContextHooks";
import { useWizardBasePath } from "@/hooks/useWizard";
import { DataContext } from "@/provider/DataContextProvider";
import {
  getEntry,
  hasBasketEntryWithVoiceOption,
} from "@/utils/dataContextHelpers";
import { getDeviceDescription } from "@/utils/deviceUtils";

/**
 * This component should implement the device assignment
 * and the rate picker during the onboarding.
 */
export const Summary: FC = () => {
  const { dataContext } = useContext(DataContext);
  const basePath = useWizardBasePath();
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "Onboarding.sections.checkout.summary",
  });

  const entry = getEntry(dataContext);
  const deviceType = getDeviceDescription(entry?.device?.description);
  const customData = entry?.customData;
  const deviceName = customData?.["SimAlias"] as string;
  const { flatName } = useGetFlatPricesAndName();
  const hasVoiceOption = hasBasketEntryWithVoiceOption(dataContext);
  const isMobileAbo = useIsMobileAbo();
  const hasONP = entry?.onp;
  const onpTranslation = hasONP
    ? t("numberPortingIncluded")
    : t("numberPortingNotIncluded");
  const voiceOptionText = hasVoiceOption
    ? t("voiceOptionIncluded")
    : t("voiceOptionNotIncluded");

  return (
    <div className="payment-summary">
      <p className="summary-title">{t("title")}</p>
      <div className="selected-summary">
        <SectionButtonHeader header={t("assignedDevice")}>
          <div className="selection-row">
            <button
              className="change-button"
              onClick={() => navigate(`${basePath}/2`)}
            >
              {t("changeRate")}
            </button>
          </div>
        </SectionButtonHeader>
        <div className="spans">
          <span>{deviceType}</span>
          <span>|</span>
          <span>{deviceName}</span>
        </div>
      </div>
      <div className="selected-summary">
        <div className="selection-row">
          <p className="header">{t("rateOptions")}</p>
          <button
            className="change-button"
            onClick={() => navigate(`${basePath}/3`)}
          >
            {t("changeRate")}
          </button>
        </div>
        <div className="spans w-60 xsPlus:w-full">
          <span>{flatName}</span>
          {!isMobileAbo && (
            <>
              <span>|</span>
              <span className={clsx(!hasVoiceOption && "!text-secondary-100")}>
                {voiceOptionText}
              </span>
            </>
          )}
          <span>|</span>
          <span className={clsx(!hasONP && "!text-secondary-100")}>
            {onpTranslation}
          </span>
        </div>
      </div>
    </div>
  );
};
