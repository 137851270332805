import { useTranslation } from "react-i18next";
import { FCC } from "types";

import { Tooltip } from "@/components/Interface/Tooltip/Tooltip";
import {
  SimCustomerInfoAddESIMNotAllowedReasonItem,
  SimCustomerInfoAddTripleSIMNotAllowedReasonItem,
} from "@/services/model";

type AddSimNotAllowedReasonType = (
  | SimCustomerInfoAddESIMNotAllowedReasonItem
  | SimCustomerInfoAddTripleSIMNotAllowedReasonItem
)[];
interface Props {
  reasons: AddSimNotAllowedReasonType;
  leftOnMobile?: boolean;
  dataTestId?: string;
}

export const AddSimDisabledTooltip: FCC<Props> = ({
  reasons,
  leftOnMobile,
  children,
  dataTestId,
}) => {
  const { t } = useTranslation();

  // Reasons from api
  const { TooManySIMs, IDCheckOpen, DunningLevel3 } = {
    ...SimCustomerInfoAddTripleSIMNotAllowedReasonItem,
    ...SimCustomerInfoAddESIMNotAllowedReasonItem,
  };

  const translatedReasons = reasons.reduce<string[]>((acc, reason) => {
    switch (reason) {
      case TooManySIMs:
        acc.push(t("portal:add-device.simSelection.reasons.TooManySIMs"));
        break;
      case IDCheckOpen:
        acc.push(t("portal:add-device.simSelection.reasons.IDCheckOpen"));
        break;
      case DunningLevel3:
        acc.push(t("portal:add-device.simSelection.reasons.DunningLevel3"));
        break;
    }

    return acc;
  }, []);

  return (
    <Tooltip
      className="w-72"
      dataTestId={dataTestId}
      content={
        <>
          <h5 className="text-base font-semibold text-primary-100">
            {t("portal:common.tooltip.actionNotPossible.title")}
          </h5>
          <p className="text-black font-x-small">
            {t("portal:common.tooltip.actionNotPossible.text", {
              count: translatedReasons.length,
            })}
          </p>
          <ul className="font-x-small text-black list-disc list-outside pl-4">
            {translatedReasons?.map((reason: string) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </>
      }
      position="top"
      leftOnMobile={leftOnMobile}
    >
      {children}
    </Tooltip>
  );
};
