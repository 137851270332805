import { useAtomValue } from "jotai";

import { refreshTokenAtom } from "@/utils/atoms.ts";
import { agentLoginStatus } from "@/utils/authentication.ts";

export const useIsAgentLogin: () => boolean = () => {
  const refreshToken = useAtomValue(refreshTokenAtom);

  return refreshToken === agentLoginStatus;
};
