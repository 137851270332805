import "./FreeEsimTrialBanner.scss";

import { useTranslation } from "react-i18next";

import { FreeEsimTrialTooltip } from "@/components/Onboarding/Steps/RateSelection/FreeEsimTrialTooltip/FreeEsimTrialTooltip";

export const FreeEsimTrialBanner = () => {
  const { t } = useTranslation();

  return (
    <div id="esim-trial-banner-box">
      <p className="text-white font-semibold text-xl">
        {t("translation:Onboarding.sections.rate-selection.esimBannerIntro")}
      </p>
      <div className="flex flex-row justify-center items-center gap-2">
        <p className="text-primary-100 text-2xl font-semibold">
          {t("translation:Onboarding.sections.rate-selection.esimBannerTitle")}
        </p>
        <FreeEsimTrialTooltip />
      </div>
    </div>
  );
};
