import { debounce } from "lodash";
import { useLayoutEffect, useState } from "react";

const mobileBreakPoint = 640;

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakPoint,
  );

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth < mobileBreakPoint);
    };
    window.addEventListener("resize", debounce(updateSize, 250));

    return (): void => window.removeEventListener("resize", updateSize);
  }, []);

  return isMobile;
};
