import "@/components/Portal/Cockpit/DeviceManagement/Roaming/CheckoutRoamingOption/CheckoutRoamingOption.scss";

import { captureException, captureMessage, withScope } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { ConfirmationButtonGroup } from "@/components/Layout/ConfirmationButtonGroup/ConfirmationButtonGroup";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import {
  getHeader,
  optionBasketAtom,
} from "@/components/Portal/Cockpit/DeviceManagement/Roaming/roamingUtils";
import { LightboxWrapper } from "@/components/Portal/UserAccount/PaymentMethods/LightboxWrapper/LightboxWrapper";
import { useAlert } from "@/hooks/useAlert";
import { useHandleCheckoutError } from "@/hooks/useHandleCheckoutError";
import { useHandleError } from "@/hooks/useHandleError";
import { usePaymentMode } from "@/hooks/usePaymentMode";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useSimIdFromQueryParam } from "@/hooks/useSimIdFromQueryParam";
import { ErrorType } from "@/mutator/frontendApiInstance";
import { FullScreenLoadingContext } from "@/provider/FullScreenLoadingProvider/FullScreenLoadingProvider";
import {
  useAddOptionToContract,
  useCreateTransaction,
  useGetAvailableSunriseRoamingPackages,
  usePayTransaction,
} from "@/services/api";
import {
  CreateTransactionDatatransOrderType,
  DatatransPaymentResponse,
  DatatransPaymentResponseStatus,
  GetAvailableSunriseRoamingPackagesPackageType,
  SignUpResponse,
} from "@/services/model";
import { AlertTypes } from "@/utils/atoms";
import { stripFlatName } from "@/utils/deviceUtils";

import { PaymentMeanSection } from "./PaymentMeanSection/PaymentMeanSection";
import { RoamingDueNow } from "./RoamingDueNow/RoamingDueNow";
import { RoamingSummary } from "./RoamingSummary/RoamingSummary";

type FormProps = {
  paymentProvider: string;
};

export const CheckoutRoamingOption: FC = () => {
  const [optionBasket, setOptionBasket] = useAtom(optionBasketAtom);
  const [isSubmittingCheckout, setIsSubmittingCheckout] = useState(false);
  const [simId] = useSimIdFromQueryParam();
  const [paymentStatus] = useQueryParam("payment");
  const { t } = useTranslation();
  const showAlert = useAlert();
  const { setFullScreenLoading } = useContext(FullScreenLoadingContext);
  const queryClient = useQueryClient();
  const handleError = useHandleError();
  const navigate = useNavigate();

  const { data: availableRoamingPackages } =
    useGetAvailableSunriseRoamingPackages(Number(simId), {
      packageType: GetAvailableSunriseRoamingPackagesPackageType.Data_Roaming,
    });

  const entry = optionBasket?.entries[0];
  const totalOnceAmount = optionBasket?.total?.oneTime?.amount;
  const option = entry?.options?.length ? entry?.options[0] : undefined;
  const optionId = option?.product.id ?? -1;

  const selectedPackage =
    availableRoamingPackages?.subscriberPackagesRoaming &&
    availableRoamingPackages.subscriberPackagesRoaming.find((pack) => {
      return pack.id === optionId;
    });

  const billingPeriod =
    selectedPackage?.minContractDurUnit === "months" &&
    selectedPackage?.minContractDur === 12
      ? t("cockpit.managementTile.roaming.addOption.365days")
      : "";

  const flatName = stripFlatName(selectedPackage?.shortName);

  const handleSuccess = useCallback(() => {
    // Added in order to trigger a refetching in the DeviceManagement
    queryClient.invalidateQueries(["/sim/roamingInfo"]).then(() => {
      showAlert({
        type: AlertTypes.success,
        text: t("cockpit.managementTile.roaming.checkout.success"),
      });
      navigate(`../display?simId=${simId}`, { replace: true });
    });
  }, [navigate, queryClient, showAlert, simId, t]);

  const isSuccess = paymentStatus === "success";
  useEffect(() => {
    if (!isSubmittingCheckout && isSuccess) {
      handleSuccess();
    }
  }, [isSuccess, isSubmittingCheckout, handleSuccess]);

  const {
    mutate: createTransaction,
    data: datatransRequestDocument,
    isLoading: isCreatingTransaction,
  } = useCreateTransaction();

  // Both usePayTransaction and useAddOptionToContract share the same behavior onSuccess and onError
  const transactionMutationOptions = {
    onSuccess: (data: DatatransPaymentResponse | SignUpResponse) => {
      // Hide full screen loading
      setFullScreenLoading(false);
      if ("signUpId" in data) {
        handleSuccess();
      } else {
        if (data.status === DatatransPaymentResponseStatus.OK) {
          handleSuccess();
        } else {
          setIsSubmittingCheckout(false);
          handleError(
            "Payment failure",
            `${t("Error.payment.fail")} ${t("Error.payment.pleaseCheckData")}`,
          );
          withScope(function (scope) {
            scope.setFingerprint(["/contract/serviceId/options"]);
            scope.setTag("section", "portal");
            scope.setTag("domain", "CheckoutRoamingOption");
            scope.setTag("endpoint", "addOptionToContract");
            scope.setExtra("simId", simId);
            scope.setContext("paymentContext", {
              isInvoice,
              isCreditCard,
              maskedCardNumberString,
              cardType,
            });
            captureMessage("Payment failure");
          });
        }
      }
    },
    onError: (err: ErrorType<unknown>) => {
      // Hide full screen loading
      setFullScreenLoading(false);
      // If it fails we report the error in sentry
      handleError(err);
      withScope(function (scope) {
        scope.setFingerprint(["/contract/serviceId/options"]);
        scope.setTag("section", "portal");
        scope.setTag("domain", "CheckoutRoamingOption");
        scope.setTag("endpoint", "addOptionToContract");
        scope.setExtra("simId", simId);
        scope.setExtra("basket", optionBasket);
        scope.setContext("paymentContext", {
          isInvoice,
          isCreditCard,
          maskedCardNumberString,
          cardType,
        });
        captureException(err);
      });
    },
  };

  // This is used to pay transactions with a saved credit card
  const { mutate: payTransaction } = usePayTransaction({
    mutation: transactionMutationOptions,
  });

  // This is used to add the option to the contract after the payment
  // with a saved credit card or with invoice
  const {
    mutate: addOptionToContract,
    isLoading: isAddingOptionLoading,
    isSuccess: isAddingOptionDone,
  } = useAddOptionToContract({
    mutation: transactionMutationOptions,
  });

  useHandleCheckoutError(false);

  const cancel = () => {
    // Clear the basket!
    setOptionBasket();
    navigate(`../display?simId=${simId}`, { replace: true });
  };

  const handleSubmit = () => {
    const basketId = optionBasket?.id;
    if (basketId === undefined) {
      captureMessage("Wanted to submit undefined basket id!");
      handleError("Wanted to submit undefined basket id!");

      return;
    }
    if (totalOnceAmount === undefined) {
      captureMessage("Wanted to charge undefined oneTime amount");
      handleError("Wanted to charge undefined oneTime amount");

      return;
    }
    if (!isSubmittingCheckout) {
      if (isInvoice || isCreditCard) {
        setIsSubmittingCheckout(true); // To prevent double checkout on re-rendering
        setFullScreenLoading(
          true,
          t("cockpit.managementTile.roaming.checkout.loading"),
        );
        // Trigger transaction for this basket
        if (isCreditCard) {
          payTransaction({
            params: {
              simId: parseInt(simId),
              basketId: basketId,
              datatransOrderType:
                CreateTransactionDatatransOrderType.AddOptionToContract,
            },
          });
        }
        // this customer is an invoice customer, we just add option to contract
        else if (isInvoice) {
          addContractCallback(basketId);
        }
      } else {
        // Else we create a transaction, which will in turn open the Lightbox, allow the user to enter payment information
        // and call addOptionToContract backend-side if the payment was successful.
        createTransaction({
          params: {
            simId: parseInt(simId),
            basketId: basketId,
            datatransOrderType:
              CreateTransactionDatatransOrderType.AddOptionToContract,
          },
        });
      }
    }
  };

  const addContractCallback = useCallback(
    (basketId: number) => {
      addOptionToContract({
        serviceId: parseInt(simId),
        data: {
          header: getHeader(),
          basket: optionBasket,
          basketId,
        },
      });
    },
    [addOptionToContract, optionBasket, simId],
  );

  const { watch, control } = useForm<FormProps>();
  const watchedPaymentProvider = watch("paymentProvider");
  const { isInvoice, isCreditCard, maskedCardNumberString, cardType } =
    usePaymentMode();

  const disableSuccessButton =
    isCreatingTransaction ||
    isAddingOptionLoading ||
    isAddingOptionDone ||
    !(watchedPaymentProvider || isInvoice || isCreditCard);

  return (
    <div id="checkout-roaming-option">
      <h3 className="text-secondary-100 mb-2 md:mb-6">
        {t("cockpit.managementTile.roaming.availableOptions")}
      </h3>
      {isAddingOptionLoading && <LoadingSpinner />}
      {optionBasket && selectedPackage && (
        <>
          <div id="checkout-layout">
            <RoamingSummary subscriberPackage={selectedPackage} />

            <div id="due-cards">
              <div id="due-now">
                <RoamingDueNow
                  basket={optionBasket}
                  billingPeriod={billingPeriod}
                  flatName={flatName}
                />
              </div>
            </div>
            <section id="payment-section">
              <PaymentMeanSection
                isInvoice={isInvoice}
                isCreditCard={isCreditCard}
                cardType={cardType}
                maskedCardNumberString={maskedCardNumberString}
                control={control}
                enableShowCurrent={true}
                sumIsZero={false}
              />
              <LightboxWrapper
                paymentProvider={watchedPaymentProvider}
                transactionId={datatransRequestDocument?.refNo}
                sign={datatransRequestDocument?.sign}
                enabled={datatransRequestDocument !== undefined}
              />
              <Trans
                t={t}
                components={[
                  <Link
                    className="text-primary-100 underline"
                    key="1"
                    replace
                    to={`/portal/user/payments`}
                  />,
                ]}
              >
                cockpit.managementTile.roaming.checkout.changePaymentNote
              </Trans>
            </section>
          </div>
          <ConfirmationButtonGroup
            successText={t("common.buttons.submit")}
            successAction={() => handleSubmit()}
            cancelText={t("common.buttons.cancel")}
            cancelAction={() => cancel()}
            disableSuccessButton={disableSuccessButton}
          />
        </>
      )}
    </div>
  );
};
