/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type SimCardDetailSimStatusReason =
  (typeof SimCardDetailSimStatusReason)[keyof typeof SimCardDetailSimStatusReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimCardDetailSimStatusReason = {
  WF_ACTIVE_NP: "WF_ACTIVE_NP",
  ID_CHECK_ACTIVE: "ID_CHECK_ACTIVE",
  ACTIVE_NOTICE_OF_CANCELLATION: "ACTIVE_NOTICE_OF_CANCELLATION",
  LOCK_REQUESTED: "LOCK_REQUESTED",
  NO_SUCCEEDING_PACKAGE: "NO_SUCCEEDING_PACKAGE",
  NO_ACTIVE_PACKAGE: "NO_ACTIVE_PACKAGE",
} as const;
