import { captureMessage, withScope } from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import OpenArrow from "@/assets/ux/ArrowOpenDoc.svg?react";
import { LoadingSpinner } from "@/components/Layout/LoadingSpinner/LoadingSpinner";
import { TableRow } from "@/components/Layout/Table/TableRow/TableRow";
import { BillListCheckbox } from "@/components/Portal/Bills/BillListCheckbox/BillListCheckbox";
import { useHandleError } from "@/hooks/useHandleError";
import { getBillDocument } from "@/services/api";
import { BillFile, DrBill } from "@/services/model";
import { log } from "@/utils/log";
import { getCurrency, getDateString } from "@/utils/translationHelpers";

export interface BillListRowProps {
  bill: DrBill;
}

enum statusToTranslationMapping {
  Mahnung = "reminder",
  fällig = "due",
  storniert = "canceled",
  geschlossen = "paid",
}

export const getValidStatus = (status: string) => {
  if (
    status === "Mahnung" ||
    status === "fällig" ||
    status === "storniert" ||
    status === "geschlossen"
  ) {
    return statusToTranslationMapping[status];
  } else {
    withScope((scope) => {
      scope.setTag("section", "portal");
      scope.setTag("domain", "bills");
      scope.setContext("status", { status: status ?? "undefined" });
      captureMessage("Received unexpected billing status.");
    });

    return statusToTranslationMapping.geschlossen;
  }
};

export const assignFileToAnchor = (data: BillFile, elem: HTMLAnchorElement) => {
  if (data.billFile?.file && data.billFile.mimeType === "application/pdf") {
    const filename = `DR_Rechnung-${new Date()
      .toISOString()
      .replaceAll(/:|\./g, "-")}.pdf`;
    elem.setAttribute("target", "_blank");
    elem.setAttribute("download", filename);

    // On chrome, we cannot open data urls, so we convert to a blob and create it this way
    if (navigator.userAgent.includes("Firefox")) {
      elem.setAttribute(
        "href",
        `data:application/pdf;base64,${data.billFile?.file}`,
      );
    } else {
      // decode base64 string, remove space for IE compatibility
      const binary = atob(
        (data.billFile.file as unknown as string).replace(/\s/g, ""),
      );
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      const blob = new Blob([view], { type: "application/pdf" });
      elem.setAttribute("href", window.URL.createObjectURL(blob));
    }

    return elem;
  } else {
    log("Could not save bill pdf, the check for valid object failed.");
  }
};

export const BillListRow = ({
  bill: { id, invoiceDate: date, billName: name, billNumber, amount, status },
}: BillListRowProps) => {
  const { t } = useTranslation("portal");

  const [isLoading, setIsLoading] = useState(false);
  const handleError = useHandleError();

  const downloadBill = () => {
    setIsLoading(true);
    if (id) {
      getBillDocument(id)
        .then((data) => {
          let elem: HTMLAnchorElement | undefined =
            window.document.createElement("a");
          elem = assignFileToAnchor(data, elem);
          if (elem) {
            elem.click();
          }
        })
        .catch((error) => handleError(error))
        .finally(() => setIsLoading(false));
    }
  };

  return id ? (
    <>
      {isLoading && <LoadingSpinner />}
      <TableRow>
        <td className="text-base leading-normal">
          <BillListCheckbox id={id} />
        </td>
        <td>{date && getDateString(new Date(date))}</td>
        <td>{name}</td>
        <td>{billNumber}</td>
        {/* TODO: Insert dynamic currency id here */}
        <td>{getCurrency(amount || 0, "CHF")}</td>
        <td className={status === "Mahnung" ? "text-secondary-100" : ""}>
          {status ? t(`bills.list.status.${getValidStatus(status)}`) : "-"}
        </td>
        <td>
          <button
            data-testid="bill-download-icon"
            title="Download bill."
            onClick={downloadBill}
          >
            <OpenArrow />
          </button>
        </td>
      </TableRow>
    </>
  ) : null;
};
