/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Compax Frontend API-v2
 * API by Compax
 * OpenAPI spec version: 122.0.0-SNAPSHOT
 */

export type CheckCscLoginResponseCheckCscLoginStatus =
  (typeof CheckCscLoginResponseCheckCscLoginStatus)[keyof typeof CheckCscLoginResponseCheckCscLoginStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckCscLoginResponseCheckCscLoginStatus = {
  login_available: "login_available",
  login_exists: "login_exists",
  error: "error",
} as const;
