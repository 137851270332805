import { QueryObserverResult } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { debounce } from "lodash";
import { useRef } from "react";

import { transferTypes } from "@/hooks/useSimTransferStateInfo";
import { useGetESimInfo, useStartESimChange } from "@/services/api";
import { ESimInfo } from "@/services/model";
import { esimTransferTypeAtom } from "@/utils/atoms";

export const useStartAndFetchEsimChange = (
  refetchSimDetail: () => Promise<QueryObserverResult>,
  checkIfInProgress: (esimInfo: ESimInfo[] | undefined) => boolean,
  simId: number | undefined,
  useSimMutation: typeof useStartESimChange,
  transferType: transferTypes,
) => {
  const isChanging = useRef(false);
  const setEsimTransferType = useSetAtom(esimTransferTypeAtom);
  const {
    mutateAsync: _startChange,
    isLoading,
    isSuccess: startedChange,
  } = useSimMutation({
    mutation: {
      onSuccess: async () => {
        // Refetch in order to get the newest state for checkIfInProcess
        await refetchESimInfo().then(() => {
          if (simId) {
            setEsimTransferType((prev) => {
              return {
                ...prev,
                [simId]: transferType,
              };
            });
          }
        });
      },
    },
  });
  const { data: esimInfo, refetch: refetchESimInfo } = useGetESimInfo(
    { simIds: simId ? [simId] : [] },
    {
      query: {
        enabled: simId !== undefined && !isNaN(simId),
        refetchInterval: (data) =>
          // If something is in progress, refetch every 3 seconds
          checkIfInProgress(data) || startedChange ? 3000 : false,
        onSuccess: async (data) => {
          if (!checkIfInProgress(data)) {
            // Once the change is done, refetch the sim detail to get the new matchingId
            await refetchSimDetail();
          }
        },
      },
    },
  );

  const changeInProgress = checkIfInProgress(esimInfo);

  const startChange = debounce(
    (params) => {
      if (isChanging.current) return;
      isChanging.current = true;

      return _startChange(params).finally(() => {
        isChanging.current = false;
      });
    },
    5000,
    {
      leading: true,
      trailing: false,
    },
  );

  return {
    startChange: isLoading ? undefined : startChange,
    startedChange,
    changeInProgress,
  };
};
